import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Grid2Props,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { classes, SMALL_TEXT_FIELD_DARK } from "portal/utils/theme";
import { ClassValue } from "clsx";
import { numberOrEmptyString } from "portal/utils/forms";
import { range } from "portal/utils/arrays";
import { TFunction } from "i18next";
import {
  TVEProfile,
  TVERowProfile,
} from "protos/target_velocity_estimator/target_velocity_estimator";
import { useTranslation } from "react-i18next";
import React, {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useState,
} from "react";

const DIVIDER_HEIGHT = "h-1";
const SECTION_WIDTH = "w-3/4";
const SECTION_WIDTH_FULL = "w-4/4";
const SECTION_HEIGHT = "h-12";

const SPEEDOMETER_LINES = {
  RED: "bg-red-500",
  BLUE: "bg-blue-500",
  GREEN: "bg-green-500",
  YELLOW: "bg-yellow-500",
};

const getFormula = (
  t: TFunction
): Array<{
  label: string;
  description: string;
  key: keyof TVEProfile;
  min?: number;
  max?: number;
  step?: number;
  color: string;
}> => [
  {
    label: t("components.velocityEstimator.configs.primaryRange.label"),
    key: "primaryRange",
    description: t(
      "components.velocityEstimator.configs.primaryRange.description"
    ),
    step: 1,
    max: 100,
    color: "border-green-500 border-2",
  },
  {
    label: t("components.velocityEstimator.configs.secondaryRange.label"),
    key: "secondaryRange",
    description: t(
      "components.velocityEstimator.configs.secondaryRange.description"
    ),
    step: 1,
    max: 100,
    color: "border-yellow-500 border-2",
  },
];

const getRowFormula = (
  t: TFunction
): Array<{
  label: string;
  description: string;
  key: keyof TVERowProfile;
  min?: number;
  max?: number;
  step?: number;
  color: string;
}> => [
  {
    key: "secondaryKillRate",
    label: t("components.velocityEstimator.configs.secondaryKillRate.label"),
    description: t(
      "components.velocityEstimator.configs.secondaryKillRate.description"
    ),
    step: 1,
    max: 100,
    color: "border-red-500 border-2",
  },
  {
    key: "primaryKillRate",
    label: t("components.velocityEstimator.configs.primaryKillRate.label"),
    description: t(
      "components.velocityEstimator.configs.primaryKillRate.description"
    ),
    step: 1,
    max: 100,
    color: "border-blue-500 border-2",
  },
];

export const GRID_ROW: Grid2Props = {
  spacing: 1,
  container: true,
  className: "w-full items-center justify-center",
};

interface Props {
  tveProfile: TVEProfile;
  areRowsSynced: boolean;
  canEdit: boolean;
  onToggleSyncAllRows: () => void;
  setUpdatedTve: Dispatch<SetStateAction<TVEProfile | undefined>>;
}

export const FormulaCard: FunctionComponent<Props> = ({
  tveProfile,
  areRowsSynced,
  canEdit,
  onToggleSyncAllRows,
  setUpdatedTve,
}) => {
  const { t } = useTranslation();
  const [focusedTveParameter, setFocusedTveParameter] = useState<
    keyof TVEProfile | undefined
  >();
  const getVisualizationClasses = (
    profileKey: keyof TVEProfile
  ): ClassValue[] => {
    return [
      focusedTveParameter === profileKey ? SECTION_WIDTH_FULL : SECTION_WIDTH,
      focusedTveParameter === profileKey ? "animate-pulse" : "",
    ];
  };
  return (
    <Card className="p-8 my-4 flex-shrink-0 flex-grow">
      {/* Header */}
      <Typography variant="h6">
        {t("components.velocityEstimator.configs.card.formulaTitle")}
      </Typography>

      {/* Visualization */}
      <div className="flex justify-center">
        <div className="relative w-96 h-64">
          <div className="absolute flex flex-col w-full h-full justify-center">
            <div
              className={classes(
                SPEEDOMETER_LINES.RED,
                DIVIDER_HEIGHT,
                ...getVisualizationClasses(
                  "secondaryKillRate" as keyof TVEProfile
                )
              )}
            />
            <div
              className={classes(
                "bg-gradient-to-r from-yellow-500",
                SECTION_HEIGHT,
                SECTION_WIDTH
              )}
            />
            <div
              className={classes(
                SPEEDOMETER_LINES.BLUE,
                DIVIDER_HEIGHT,
                ...getVisualizationClasses(
                  "primaryKillRate" as keyof TVEProfile
                )
              )}
            />
            <div className="flex">
              <div
                className={classes(
                  "bg-gradient-to-r from-green-500 flex-shrink",
                  SECTION_HEIGHT,
                  SECTION_WIDTH
                )}
              />
              <div className="flex flex-col justify-center font-bold text-green-500 whitespace-nowrap">
                {t("components.velocityEstimator.visualization.targetSpeed")}
              </div>
            </div>
            <div
              className={classes(
                SPEEDOMETER_LINES.GREEN,
                DIVIDER_HEIGHT,
                ...getVisualizationClasses("primaryRange" as keyof TVEProfile)
              )}
            />
            <div
              className={classes(
                "bg-gradient-to-r from-yellow-500",
                SECTION_HEIGHT,
                SECTION_WIDTH
              )}
            />
            <div
              className={classes(
                SPEEDOMETER_LINES.YELLOW,
                DIVIDER_HEIGHT,
                ...getVisualizationClasses("secondaryRange" as keyof TVEProfile)
              )}
            />
          </div>
          <div className="absolute bg-white w-1 h-full" />
          <div className="absolute flex flex-col w-full h-full justify-evenly">
            {range(10).map((index) => (
              <div
                key={index}
                className={classes("bg-white h-1", {
                  "w-6": index % 2 === 0,
                  "w-12": index % 2 === 1,
                })}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Form */}
      <div className="w-full flex flex-col justify-center items-center gap-8">
        {/* Title and sync */}
        <Grid {...GRID_ROW}>
          <Grid item xs={0} md={2} className="hidden md:block" />
          {!areRowsSynced && (
            <Grid
              item
              xs={4}
              md={2}
              className="flex flex-row justify-center"
              order={{ xs: 2, md: 1 }}
            >
              {t("components.velocityEstimator.configs.rows.row1")}
            </Grid>
          )}
          <Grid
            item
            xs={4}
            md={2}
            className="flex flex-row justify-center"
            order={{ xs: areRowsSynced ? 12 : 2, md: 1 }}
          >
            {areRowsSynced
              ? t("components.velocityEstimator.configs.rows.allRows")
              : t("components.velocityEstimator.configs.rows.row2")}
          </Grid>
          {!areRowsSynced && (
            <Grid
              item
              xs={4}
              md={2}
              className="flex flex-row justify-center"
              order={{ xs: 2, md: 1 }}
            >
              {t("components.velocityEstimator.configs.rows.row3")}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={areRowsSynced ? 8 : 4}
            className="text-sm text-center md:text-left"
            order={{ xs: 1, md: 2 }}
          >
            {canEdit ? (
              <FormControlLabel
                control={
                  <Checkbox
                    color="info"
                    checked={areRowsSynced}
                    onChange={() => onToggleSyncAllRows()}
                  />
                }
                label={t("components.velocityEstimator.configs.sync")}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        {getRowFormula(t).map(
          ({
            label,
            description,
            key,
            min = 0,
            max = 100,
            step = 1,
            color,
          }) => (
            <Grid {...GRID_ROW} key={key}>
              <Grid item xs={12} md={2} className="font-bold" order={1}>
                {label}
              </Grid>
              {[1, 2, 3].map((rowKey) => {
                if (areRowsSynced && rowKey !== 2) {
                  return;
                }
                return (
                  <Grid
                    item
                    key={rowKey + key}
                    xs={areRowsSynced ? 12 : 4}
                    md={2}
                    order={{ xs: 3, md: 2 }}
                    className="flex flex-col md:flex-row items-center"
                  >
                    <TextField
                      {...SMALL_TEXT_FIELD_DARK}
                      onFocus={() =>
                        setFocusedTveParameter(key as keyof TVEProfile)
                      }
                      onBlur={() => setFocusedTveParameter(undefined)}
                      disabled={!canEdit}
                      type="number"
                      inputProps={{ min, max, step }}
                      value={(() => {
                        const value = (
                          tveProfile.rows[rowKey] as TVERowProfile
                        )[key];
                        return typeof value === "number"
                          ? Math.round(value * 100)
                          : value;
                      })()}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        classes: {
                          notchedOutline: color,
                        },
                      }}
                      className="w-full text-center"
                      onChange={(event) => {
                        const { rows } = tveProfile;
                        const value = numberOrEmptyString(event.target.value);
                        const tveRowProfileValue = TVERowProfile.fromPartial({
                          ...rows[rowKey],
                          [key]:
                            typeof value === "number" ? value / 100 : value,
                        });
                        setUpdatedTve({
                          ...tveProfile,
                          rows: areRowsSynced
                            ? {
                                1: tveRowProfileValue,
                                2: tveRowProfileValue,
                                3: tveRowProfileValue,
                              }
                            : {
                                ...rows,
                                [rowKey]: tveRowProfileValue,
                              },
                        });
                      }}
                    />
                  </Grid>
                );
              })}

              <Grid
                item
                xs={12}
                md={areRowsSynced ? 8 : 4}
                className="text-sm"
                order={{ xs: 2, md: 3 }}
              >
                {description}
              </Grid>
            </Grid>
          )
        )}

        {getFormula(t).map(
          ({
            label,
            description,
            key,
            min = 0,
            max = Infinity,
            step = 1,
            color,
          }) => (
            <Grid {...GRID_ROW} key={key}>
              <Grid item xs={12} md={2} className="font-bold" order={1}>
                {label}
              </Grid>
              <Grid
                item
                xs={12}
                md={2}
                className="flex flex-col md:flex-row items-center"
                order={{ xs: 3, md: 2 }}
              >
                <TextField
                  {...SMALL_TEXT_FIELD_DARK}
                  onFocus={() =>
                    setFocusedTveParameter(key as keyof TVEProfile)
                  }
                  onBlur={() => setFocusedTveParameter(undefined)}
                  disabled={!canEdit}
                  type="number"
                  inputProps={{ min, max, step }}
                  value={(() => {
                    const value = tveProfile[key];
                    return typeof value === "number"
                      ? Math.round(value * 100)
                      : value;
                  })()}
                  className="w-full text-center"
                  onChange={(event) => {
                    const value = numberOrEmptyString(event.target.value);
                    setUpdatedTve({
                      ...tveProfile,
                      [key]: typeof value === "number" ? value / 100 : value,
                    });
                  }}
                  InputProps={{
                    ...SMALL_TEXT_FIELD_DARK.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    classes: {
                      ...SMALL_TEXT_FIELD_DARK.InputProps?.classes,
                      notchedOutline: color,
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={8}
                className="text-sm"
                order={{ xs: 2, md: 3 }}
              >
                {description}
              </Grid>
            </Grid>
          )
        )}
      </div>
    </Card>
  );
};
