import * as yup from "yup";
import { Alert, Typography } from "@mui/material";
import {
  BLUE_LOADING_BUTTON,
  classes,
  TEXT_FIELD_DARK,
} from "portal/utils/theme";
import { capitalize, uncapitalize } from "portal/utils/strings";
import { Field, Form, Formik } from "formik";
import { findWhere, without } from "portal/utils/arrays";
import { TextField as FormikTextField } from "formik-mui";
import { isUndefined } from "portal/utils/identity";
import { ListManager } from "portal/components/ListManager";
import { LoadingButton } from "@mui/lab";
import { RobotAllowedAlarm, RobotBlockedAlarm } from "protos/portal/admin";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useGetRobotAlarmListsQuery,
  useGetRobotQuery,
  useSetRobotAlarmListsMutation,
  useUpdateRobotMutation,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useParams } from "react-router-dom";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import SaveIcon from "@mui/icons-material/SaveOutlined";

export const RobotAdmin: FunctionComponent = withAuthenticationRequired(
  function RobotAdmin() {
    const { serial } = useParams();
    const { isInternal } = useSelf();
    const { t } = useTranslation();

    const [setRobotAlarmLists, { isLoading }] = useMutationPopups(
      useSetRobotAlarmListsMutation(),
      {
        success: capitalize(
          t("utils.actions.savedLong", {
            subject: t("views.admin.alarms.lists"),
          })
        ),
      }
    );

    const { data: lists } = useQueryPopups(
      useGetRobotAlarmListsQuery(serial ? { serial } : skipToken)
    );

    if (!isInternal || !serial || !lists) {
      return;
    }

    return (
      <div className="max-w-80">
        <Typography variant="h5">
          {t("models.robots.fields.supportSlack")}
        </Typography>
        <SupportSlackChannelForm serial={serial} className="mt-4" />
        <Typography variant="h5" className="mt-8">
          {t("views.admin.alarms.titleAllow")}
        </Typography>
        <Alert severity="warning" className="my-4">
          {t("views.admin.alarms.allowWarning")}
        </Alert>
        <ListManager
          list={lists.allowed?.alarms.map((alarm) => alarm.code)}
          loading={isLoading}
          onAdd={(newCode) => {
            setRobotAlarmLists({
              serial,
              lists: {
                ...lists,
                allowed: {
                  alarms: [
                    ...(lists.allowed?.alarms ?? []),
                    RobotAllowedAlarm.fromPartial({ code: newCode }),
                  ],
                },
              },
            });
          }}
          onRemove={(removedCode) => {
            setRobotAlarmLists({
              serial,
              lists: {
                ...lists,
                allowed: {
                  alarms: without(
                    lists.allowed?.alarms,
                    findWhere(lists.allowed?.alarms, { code: removedCode })
                  ).filter((alarm) => !isUndefined(alarm)),
                },
              },
            });
          }}
        />
        <Typography variant="h5" className="mt-8">
          {t("views.admin.alarms.titleBlock")}
        </Typography>
        <Alert severity="warning" className="my-4">
          {t("views.admin.alarms.blockWarning")}
        </Alert>
        <ListManager
          list={lists.blocked?.alarms.map((alarm) => alarm.code)}
          loading={isLoading}
          onAdd={(newCode) => {
            setRobotAlarmLists({
              serial,
              lists: {
                ...lists,
                blocked: {
                  alarms: [
                    ...(lists.blocked?.alarms ?? []),
                    RobotBlockedAlarm.fromPartial({ code: newCode }),
                  ],
                },
              },
            });
          }}
          onRemove={(removedCode) => {
            setRobotAlarmLists({
              serial,
              lists: {
                ...lists,
                blocked: {
                  alarms: without(
                    lists.blocked?.alarms,
                    findWhere(lists.blocked?.alarms, { code: removedCode })
                  ).filter((alarm) => !isUndefined(alarm)),
                },
              },
            });
          }}
        />
      </div>
    );
  }
);

interface SupportSlackChannelFormProps {
  serial: string;
  className?: string;
}
const SupportSlackChannelForm: FunctionComponent<
  SupportSlackChannelFormProps
> = ({ className, serial }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(
    () =>
      yup.object({
        supportSlack: yup
          .string()
          .label(t("models.robots.fields.supportSlack"))
          .required()
          .test({
            name: "supportSlackLeadingHash",
            message: t("views.admin.robot.warnings.supportSlackLeadingHash"),
            test: (value) => value.startsWith("#"),
          }),
      }),
    [t]
  );
  type FormValues = yup.InferType<typeof validationSchema>;

  const {
    data: robot,
    isLoading,
    isFetching,
  } = useQueryPopups(useGetRobotQuery({ serial }));
  const [updateRobot] = useMutationPopups(useUpdateRobotMutation(), {
    success: capitalize(
      t("utils.actions.updatedLong", {
        subject: uncapitalize(t("models.robots.fields.supportSlack")),
      })
    ),
  });

  const initialValues: FormValues = useMemo(() => {
    return { supportSlack: robot?.robot?.supportSlack ?? "" };
  }, [robot]);

  const onSubmit = useCallback(
    async (values: FormValues): Promise<void> => {
      const { supportSlack } = values;
      await updateRobot({ serial, robot: { supportSlack } });
    },
    [serial, updateRobot]
  );

  return (
    <Formik<FormValues>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ dirty, isSubmitting }) => (
        <Form className={classes("flex flex-col gap-2", className)}>
          <Field
            {...TEXT_FIELD_DARK}
            disabled={isLoading}
            component={FormikTextField}
            name="supportSlack"
            label={t("models.robots.fields.supportSlack")}
          />
          <div className="flex justify-end">
            <LoadingButton
              {...BLUE_LOADING_BUTTON}
              type="submit"
              loading={isSubmitting}
              disabled={!dirty || isFetching}
              startIcon={<SaveIcon />}
            >
              {t("utils.actions.save")}
            </LoadingButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};
