import {
  Bounds,
  FACILITIES,
  Facility,
  Point,
  sanitizeBounds,
} from "portal/utils/geo";
import { classes } from "portal/utils/theme";
import { FacilityTypeIcon } from "../FacilityTypeIcon";
import { FilterData } from "portal/utils/map";
import { getCustomerId } from "portal/utils/auth";
import { isEmpty } from "portal/utils/arrays";
import { Marker } from "react-map-gl";
import { Tooltip } from "@mui/material";
import { useSelf } from "portal/state/store";
import React, { ReactNode, useMemo } from "react";

export const useFacilities = (
  mapFilters: FilterData<boolean>
): {
  markers: ReactNode[];
  bounds?: Bounds;
  focus?: Point;
} => {
  const { isInternal, user } = useSelf();
  const customerId = getCustomerId(user);

  const facilities = useMemo<Facility[]>(
    () =>
      isInternal
        ? FACILITIES
        : FACILITIES.filter((facility) => facility.customerId === customerId),
    [customerId, isInternal]
  );

  const focus = useMemo<Point | undefined>(() => {
    if (isEmpty(facilities)) {
      return;
    }
    const firstFacility = facilities[0];
    if (!firstFacility) {
      return;
    }
    return [firstFacility.longitude, firstFacility.latitude];
  }, [facilities]);

  const [markers, bounds] = useMemo<[ReactNode[], Bounds | undefined]>(() => {
    let minX = Infinity;
    let maxX = -Infinity;
    let minY = Infinity;
    let maxY = -Infinity;
    const newMarkers: ReactNode[] = [];

    for (const facility of facilities) {
      if (!mapFilters.facilities[facility.type]) {
        continue;
      }
      minX = Math.min(minX, facility.latitude);
      maxX = Math.max(maxX, facility.latitude);
      minY = Math.min(minY, facility.longitude);
      maxY = Math.max(maxY, facility.longitude);
      newMarkers.push(
        <Marker
          style={{ cursor: "pointer" }}
          key={`${facility.latitude},${facility.longitude}`}
          latitude={facility.latitude}
          longitude={facility.longitude}
          onClick={() =>
            window.open(
              `https://www.google.com/maps/dir/?api=1&parameters&destination=${encodeURIComponent(
                facility.address.replace("\n", ",")
              )}`
            )
          }
        >
          <Tooltip
            title={
              <div className="flex flex-col">
                <strong>{facility.name}</strong>
                <div className="whitespace-pre">{facility.address}</div>
              </div>
            }
            arrow
          >
            {/* this wrapper prevents react from trying to ref an SVG which breaks*/}
            <div
              className={classes(
                "text-blue-500 bg-white",
                "h-4 w-4",
                "flex items-center justify-center"
              )}
            >
              <FacilityTypeIcon facilityType={facility.type} />
            </div>
          </Tooltip>
        </Marker>
      );
    }
    return [newMarkers, sanitizeBounds({ minX, maxX, minY, maxY })];
  }, [facilities, mapFilters.facilities]);

  return { markers, focus, bounds };
};
