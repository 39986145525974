import {
  Alert,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import {
  BLUE_LOADING_BUTTON,
  INPUT_DARK,
  SELECT_DARK,
  TEXT_FIELD_DARK,
} from "portal/utils/theme";
import { boolean, number, object } from "yup";
import { capitalize, titleCase } from "portal/utils/strings";
import { CarbonUser, getCustomerId, isActivated } from "portal/utils/auth";
import { Field, Form, Formik } from "formik";
import { Header } from "portal/components/header/Header";
import { LoadingButton } from "@mui/lab";
import { Page } from "portal/components/Page";
import { Path } from "portal/utils/routing";
import { Select } from "formik-mui";
import { skipToken } from "@reduxjs/toolkit/query";
import { SwitchWithLabel } from "portal/components/SwitchWithLabel";
import {
  useGetUserQuery,
  useListCustomersQuery,
  useUpdateUserMutation,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useParams } from "react-router-dom";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { WithSkeleton } from "portal/components/WithSkeleton";
import ExternalIcon from "@mui/icons-material/OpenInNewOutlined";
import React, { FunctionComponent } from "react";
import SaveIcon from "@mui/icons-material/SaveOutlined";

const AVATAR_SIZE = 80;

export const isStaff = (user: CarbonUser): boolean => {
  const firstIdentity = user.identities?.[0];
  if (!firstIdentity) {
    return false;
  }
  return Boolean(
    user.email?.endsWith("@carbonrobotics.com") && firstIdentity.isSocial
  );
};

export const isContractor = (user: CarbonUser): boolean =>
  (user.email?.endsWith("@brainsurprise.com") ||
    user.email?.endsWith("@imerit.net") ||
    user.email?.endsWith("@dropsource.com") ||
    user.email?.endsWith("@tidyops.co")) ??
  false;

export const User: FunctionComponent = withAuthenticationRequired(
  function User() {
    const { t } = useTranslation();
    const { isInternal: isSelfInternal } = useSelf();
    const { id } = useParams();

    const {
      data: userResponse,
      isError: isUserError,
      isSuccess: isUserSuccess,
    } = useQueryPopups(useGetUserQuery(id ? { userId: id } : skipToken));

    const user = userResponse?.user;
    const [updateUser] = useMutationPopups(useUpdateUserMutation(), {
      success: capitalize(
        t("utils.actions.saved", {
          subject: t("models.users.user_one"),
        })
      ),
    });

    const { data: customers } = useQueryPopups(
      useListCustomersQuery(undefined, {
        skip: !isSelfInternal,
      }),
      {
        errorVariant: "warning",
      }
    );
    const auth0Slug = window._jsenv.REACT_APP_AUTH0_AUTH_DOMAIN.replace(
      /\..*$/,
      ""
    );

    return (
      <>
        <Header
          title={
            user?.name ?? user?.email ?? t("components.Loading.placeholder")
          }
          parentLink={Path.USERS}
        />
        <Page>
          {((isUserSuccess && !user) || isUserError) && (
            <Alert severity="error">{t("views.users.errors.notFound")}</Alert>
          )}
          <Box>
            <div className="flex gap-8 items-center">
              <WithSkeleton
                variant="circular"
                width={AVATAR_SIZE}
                height={AVATAR_SIZE}
                error={isUserError}
                success={isUserSuccess}
              >
                <Avatar
                  sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
                  src={user?.picture}
                  alt={user?.name}
                />
              </WithSkeleton>
              <WithSkeleton
                variant="text"
                error={isUserError}
                success={isUserSuccess}
                className="min-h-8 min-2-24"
              >
                <Typography variant="h1" className="text-xl">
                  {user?.name ?? user?.email}
                </Typography>
              </WithSkeleton>
              {user && isStaff(user) && (
                <Chip
                  label={t("models.users.staff")}
                  className="font-normal ml-4 bg-orange-500 text-white"
                />
              )}
            </div>
            {user && isSelfInternal && (
              <Card className="mt-8">
                <CardContent>
                  <Button
                    variant="contained"
                    component="a"
                    href={`https://manage.auth0.com/dashboard/us/${auth0Slug}/users/${btoa(
                      encodeURIComponent(user.userId)
                    )}`}
                    target="_blank"
                    className="my-4"
                    endIcon={<ExternalIcon />}
                  >
                    {t("views.users.manage")}
                  </Button>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      isActivated: isActivated(user),
                      customer: getCustomerId(user)
                        ? Number(getCustomerId(user))
                        : "",
                    }}
                    validationSchema={object({
                      isActivated: boolean().required(),
                      customer: number().required(),
                    })}
                    onSubmit={async (values) => {
                      if (!user.email) {
                        return;
                      }
                      await updateUser({
                        userId: user.userId,
                        user: {
                          appMetadata: {
                            isActivated: values.isActivated,
                            customerId: Number(values.customer),
                          },
                        },
                      });
                    }}
                  >
                    {({ submitForm, isSubmitting, values }) => (
                      <Form className="flex flex-col items-start gap-4">
                        <TextField
                          {...TEXT_FIELD_DARK}
                          disabled
                          value={user.email}
                          label={t("models.users.fields.email")}
                          className="min-w-80 max-w-full"
                        />
                        <Field
                          {...SELECT_DARK}
                          input={
                            <OutlinedInput
                              {...INPUT_DARK}
                              label={titleCase(
                                t("models.customers.customer_one")
                              )}
                            />
                          }
                          component={Select}
                          name="customer"
                          label={titleCase(t("models.customers.customer_one"))}
                          placeholder={t("utils.actions.selectLong", {
                            subject: titleCase(
                              t("models.customers.customer_one")
                            ),
                          })}
                          className="min-w-80"
                        >
                          {customers?.map(({ db, name }) => (
                            <MenuItem value={db?.id} key={db?.id}>
                              {name}
                            </MenuItem>
                          ))}
                        </Field>
                        {!isStaff(user) && (
                          <Field
                            component={SwitchWithLabel}
                            type="checkbox"
                            name="isActivated"
                            label={t("models.users.fields.isActivated")}
                            classes={{
                              thumb: values.isActivated ? "bg-green-500" : "",
                              track: values.isActivated ? "bg-green-700" : "",
                            }}
                          />
                        )}
                        <LoadingButton
                          {...BLUE_LOADING_BUTTON}
                          loading={isSubmitting}
                          onClick={submitForm}
                          startIcon={<SaveIcon />}
                        >
                          {t("utils.actions.save")}
                        </LoadingButton>
                      </Form>
                    )}
                  </Formik>
                </CardContent>
              </Card>
            )}
          </Box>
        </Page>
      </>
    );
  }
);
