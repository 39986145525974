import { DependencyList, useEffect, useState } from "react";

interface AsyncState {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
}

// version of useMemo that allows for async functions
export const useMemoAsync = <T>(
  factory: () => Promise<T>,
  dependencies: DependencyList,
  defaultValue: T
): [T, AsyncState] => {
  const [result, setResult] = useState<T>(defaultValue);
  const [state, setState] = useState<AsyncState>({
    isLoading: false,
    isError: false,
    isSuccess: false,
  });

  useEffect(() => {
    setState({
      isLoading: true,
      isError: false,
      isSuccess: false,
    });
    const abortController = new AbortController();
    factory()
      .then((value) => {
        if (abortController.signal.aborted) {
          return;
        }
        setState({
          isLoading: false,
          isError: false,
          isSuccess: true,
        });
        setResult(value);
      })
      .catch(() => {
        setState({
          isLoading: false,
          isError: true,
          isSuccess: false,
        });
        setResult(defaultValue);
      });

    return () => abortController.abort();
    // doesn't like the spread, validated upstream using the additionalHooks option
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return [result, state];
};
