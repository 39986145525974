import { Facility, FacilityType } from "./geo";
import { formatRobotClass, getClass, RobotClass } from "./robots";
import { keys } from "./objects";
import { Map as MapboxMap } from "mapbox-gl";
import { PaddingOptions } from "react-map-gl";
import { RobotSummaryResponse } from "protos/portal/robots";
import { TFunction } from "i18next";

export interface FilterData<T> {
  facilities: Record<FacilityType, T>;
  robots: Record<RobotClass, T>;
}

export const DEFAULT_MAP_FILTERS: FilterData<boolean> = {
  facilities: {
    [FacilityType.CUSTOMER_OFFICE]: true,
    [FacilityType.HQ]: true,
    [FacilityType.PO_BOX]: true,
    [FacilityType.SHOP]: true,
    [FacilityType.STORAGE]: true,
    [FacilityType.SUPPORT_BASE]: true,
  },
  robots: {
    [RobotClass.Buds]: true,
    [RobotClass.Reapers]: true,
    [RobotClass.Rtcs]: true,
    [RobotClass.Simulators]: true,
    [RobotClass.Slayers]: true,
    [RobotClass.Unknown]: true,
  },
};

export type FilterGroupTypes = "facilities" | "robots";
export type FilterItemTypes = FacilityType | RobotClass;

export const isGroupFacilities = (
  groupName: FilterGroupTypes
): groupName is "facilities" => groupName === "facilities";

export const isItemFacility = (
  groupName: FilterGroupTypes,
  itemName: FilterItemTypes
): itemName is FacilityType => isGroupFacilities(groupName);

export const isGroupRobots = (
  groupName: FilterGroupTypes
): groupName is "robots" => groupName === "robots";

export const isItemRobot = (
  groupName: FilterGroupTypes,
  itemName: FilterItemTypes
): itemName is RobotClass => isGroupRobots(groupName);

export const getHiddenItemCount = (
  filters: FilterData<boolean>,
  groups?: FilterGroupTypes[]
): number => {
  let count = 0;
  // not sure why I can't get the types in this section to play nice
  for (const group of keys(filters) as FilterGroupTypes[]) {
    if (!groups || groups.includes(group)) {
      for (const itemType of keys(filters[group]) as FilterItemTypes[]) {
        if (isGroupFacilities(group) && isItemFacility(group, itemType)) {
          count = filters[group][itemType] ? count : count + 1;
          continue;
        }
        if (isGroupRobots(group) && isItemRobot(group, itemType)) {
          count = filters[group][itemType] ? count : count + 1;
          continue;
        }
        console.warn(
          `non-matching filter group and item: ${group} ${itemType}`
        );
      }
    }
  }
  return count;
};

export const showFilterDisplayName = (
  t: TFunction,
  isInternal: boolean,
  groupName: FilterGroupTypes,
  itemName: FilterItemTypes
): string => {
  if (isItemFacility(groupName, itemName)) {
    // carbon.actions.compareKeys.ignoreDynamic
    return t(`components.map.filters.${itemName}`);
  }
  if (isItemRobot(groupName, itemName)) {
    return formatRobotClass(t, isInternal, itemName);
  }
  return itemName;
};

export const getMapItemCounts = (
  facilities: Facility[],
  robots: RobotSummaryResponse[]
): FilterData<number> => {
  const counts: FilterData<number> = {
    facilities: {
      [FacilityType.CUSTOMER_OFFICE]: 0,
      [FacilityType.HQ]: 0,
      [FacilityType.PO_BOX]: 0,
      [FacilityType.SHOP]: 0,
      [FacilityType.STORAGE]: 0,
      [FacilityType.SUPPORT_BASE]: 0,
    },
    robots: {
      [RobotClass.Buds]: 0,
      [RobotClass.Reapers]: 0,
      [RobotClass.Rtcs]: 0,
      [RobotClass.Simulators]: 0,
      [RobotClass.Slayers]: 0,
      [RobotClass.Unknown]: 0,
    },
  };
  for (const facility of facilities) {
    counts.facilities[facility.type] += 1;
  }
  for (const summary of robots) {
    const type = getClass(summary.robot);
    counts.robots[type] += 1;
  }
  return counts;
};

export interface Dimensions {
  width: number;
  height: number;
}
export const getMapboxDimensions = (map: MapboxMap): Dimensions => {
  // Mapbox's `Map` type has a public `transform` property, as opposed to many
  // properties with underscored names. But it is not included in the
  // community-maintained DefinitelyTyped definitions (perhaps because it is in
  // a superclass, as `Map extends Camera`, so it's less obvious). In later
  // versions, Mapbox has migrated to native TypeScript, and this field is
  // still public, so this Seems Safe(TM) to rely upon.
  interface HasTransform {
    readonly transform: Dimensions;
  }
  return (map as typeof map & HasTransform).transform;
};

// If you call (e.g.) `map.fitBounds(bounds, { padding })` with `padding`
// larger than half the width or height of the map itself, then Mapbox throws
// an inscrutable error about "Invalid LngLat object: (NaN, NaN)", even though
// `bounds` itself is valid. This function computes a padding value that should
// be safe to pass in to such Mapbox methods.
//
// The dimensions passed to this function should ideally come from
// `getMapboxDimensions` rather than (e.g.) reading the width and height of the
// canvas element, since those values can sometimes disagree, and Mapbox's
// understanding of the transform is what's load-bearing for safety here.
export const getSafePadding = (
  mapDimensions: Dimensions,
  boundsOffset: PaddingOptions | number
): PaddingOptions => {
  const { width, height } = mapDimensions;
  const maxHorizontal = Math.max(0, Math.ceil(width / 2) - 1);
  const maxVertical = Math.max(0, Math.ceil(height / 2) - 1);

  if (typeof boundsOffset === "number") {
    const horizontal = Math.min(boundsOffset, maxHorizontal);
    const vertical = Math.min(boundsOffset, maxVertical);
    return {
      top: vertical,
      bottom: vertical,
      right: horizontal,
      left: horizontal,
    };
  } else {
    return {
      top: Math.min(boundsOffset.top, maxVertical),
      bottom: Math.min(boundsOffset.bottom, maxVertical),
      left: Math.min(boundsOffset.left, maxHorizontal),
      right: Math.min(boundsOffset.right, maxHorizontal),
    };
  }
};
