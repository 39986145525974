import { BlockResponse } from "protos/portal/spatial";
import { DATE_PATH_FORMAT } from "portal/utils/dates";
import { DateTime } from "luxon";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { HealthLog } from "protos/portal/health";
import {
  useLazyGetJobBlocksQuery,
  useLazyGetJobHistoryQuery,
  useLazyGetRobotBlocksQuery,
  useLazyGetRobotHistoryQuery,
} from "portal/state/portalApi";
import { useLazyPopups } from "portal/utils/hooks/useApiPopups";
import { useMemo } from "react";
import { useMemoAsync } from "portal/utils/hooks/useMemoAsync";

interface RobotHistoryData {
  blocks: BlockResponse[];
  logs: HealthLog[];
}
interface RobotHistory extends RobotHistoryData {
  hasBlocks: boolean;
  hasLogs: boolean;
  isLoading: boolean;
}

export const useRobotHistory = (
  serial: string | undefined,
  jobId?: string,
  inputDate?: DateTime
): RobotHistory => {
  const { isEnabled: hasSpatial } = useFeatureFlag(FeatureFlag.SPATIAL);
  const date = useMemo(
    () => (inputDate ?? DateTime.local()).toFormat(DATE_PATH_FORMAT),
    [inputDate]
  );

  const [getJobHistory] = useLazyPopups(useLazyGetJobHistoryQuery(), {
    errorVariant: "warning",
  });
  const [getJobBlocks] = useLazyPopups(useLazyGetJobBlocksQuery(), {
    errorVariant: "warning",
  });
  const [getRobotBlocks] = useLazyPopups(useLazyGetRobotBlocksQuery(), {
    errorVariant: "warning",
  });
  const [getRobotHistory] = useLazyPopups(useLazyGetRobotHistoryQuery(), {
    errorVariant: "warning",
  });
  const [{ blocks, logs }, { isLoading }] = useMemoAsync<RobotHistoryData>(
    async () => {
      if (!serial) {
        return { blocks: [], logs: [] };
      }
      if (jobId) {
        if (hasSpatial) {
          const blockResponse = await getJobBlocks({ jobId }, true);
          if (blockResponse.data && blockResponse.data.blocks.length > 0) {
            return { blocks: blockResponse.data.blocks, logs: [] };
          }
        }
        const logResponse = await getJobHistory({ jobId }, true);
        return { blocks: [], logs: logResponse.data?.logs ?? [] };
      } else {
        if (hasSpatial) {
          const blockResponse = await getRobotBlocks(
            {
              serial,
              date,
            },
            true
          );
          if (blockResponse.data && blockResponse.data.blocks.length > 0) {
            return { blocks: blockResponse.data.blocks, logs: [] };
          }
        }
        // get logs if selected job is a date and there are no blocks
        const logResponse = await getRobotHistory(
          {
            serial,
            date,
          },
          true
        );
        return { blocks: [], logs: logResponse.data?.logs ?? [] };
      }
    },
    [
      date,
      getJobBlocks,
      getJobHistory,
      getRobotBlocks,
      getRobotHistory,
      hasSpatial,
      jobId,
      serial,
    ],
    { blocks: [], logs: [] }
  );

  return {
    blocks,
    hasBlocks: Array.isArray(blocks) && blocks.length > 0,
    hasLogs: Array.isArray(logs) && logs.length > 0,
    isLoading,
    logs,
  };
};
