import {
  BlockifyRequest,
  BlockifyResponse,
  filteredListener,
  isSpatialPositionProbablyWrong,
  TYPE_BLOCKIFY,
} from "portal/workers/mapWorker";
import { Bounds, sanitizeBounds } from "portal/utils/geo";
import { Feature } from "geojson";
import { mapWorker } from "portal/workers";

import { BLANK_VALUE } from "../measurement/formatters";
import { BlockResponse } from "protos/portal/spatial";
import {
  COLOR_WHITE,
  getBlockRange,
  getBlockStyle,
  getProperties,
  isCountableBlock,
} from "portal/utils/blocks";
import { DateTime } from "luxon";
import { featureCollection, Polygon, polygon } from "@turf/helpers";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import {
  getSpatialMetricById,
  SPATIAL_TIME,
  SPATIAL_WEEDING_EFFICIENCY,
} from "portal/utils/spatialMetrics";
import {
  HISTORY_STATUS_IDLE,
  HISTORY_STATUS_LOADING,
  HistoryStatus,
} from "./useMapHistory";
import {
  isSpatialMetricBoolean,
  isSpatialMetricNumber,
  SpatialMetric,
} from "portal/utils/metrics";
import { isUndefined } from "portal/utils/identity";
import { Layer, Source } from "react-map-gl";
import { LOCALSTORAGE_MAP_BORDERS } from "portal/utils/localStorage";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useMeasurement, useSelf } from "portal/state/store";
import { useMountEffect } from "portal/utils/hooks/useMountEffect";
import { useTranslation } from "react-i18next";
import React, { ReactNode, useMemo, useState } from "react";

const SOURCE_ID = "blocks";

export const useMapBlocks = (
  blocks: BlockResponse[] | undefined,
  selectedMetricId: string,
  isRelative: boolean,
  allowBorders: boolean = false
): {
  blockSource: ReactNode;
  blockLayers: ReactNode[];
  bounds?: Bounds;
  historyStatus: HistoryStatus;
  interactiveLayerIds: string[];
} => {
  const { isInternal, measurementSystem } = useSelf();
  const { isEnabled: hasSpatial } = useFeatureFlag(FeatureFlag.SPATIAL);
  const [historyStatus, setHistoryStatus] =
    useState<HistoryStatus>(HISTORY_STATUS_IDLE);

  const { cycleSlots } = useMeasurement();

  const { t, i18n } = useTranslation();

  const [requestBorders] = useLocalStorage(
    LOCALSTORAGE_MAP_BORDERS,
    isInternal
  );
  const showBorders = requestBorders && allowBorders;

  const selectedMetric = useMemo<SpatialMetric>(
    () => getSpatialMetricById(selectedMetricId) ?? SPATIAL_WEEDING_EFFICIENCY,
    [selectedMetricId]
  );

  // do math in a seperate thread
  const [blockFeatures, setBlockFeatures] = useState<Feature<Polygon>[]>([]);
  useMountEffect(() => {
    mapWorker.addEventListener(
      "message",
      filteredListener((type, data) => {
        if (type === TYPE_BLOCKIFY) {
          setHistoryStatus(HISTORY_STATUS_IDLE);
          const { blocks } = data as BlockifyResponse;
          setBlockFeatures(blocks);
        }
      })
    );
  });

  const { calculatedBlocks, styleMapping, bounds } = useMemo<{
    styleMapping: string[];
    bounds: Bounds | undefined;
    calculatedBlocks: BlockResponse[];
  }>(() => {
    let hasBounds = false;
    let minX = Infinity;
    let maxX = -Infinity;
    let minY = Infinity;
    let maxY = -Infinity;

    const styleMapping: string[] = [];
    const calculatedBlocks: BlockResponse[] = [];
    let bounds: Bounds | undefined;
    const blockRange = getBlockRange(blocks, selectedMetric);

    const white = COLOR_WHITE.toString();

    for (const block of blocks ?? []) {
      // ignore suspicious blocks
      if (block.block?.suspicious || !block.block?.start || !block.block.end) {
        continue;
      }

      // ignore blocks with probably incorrect parameters
      if (
        isSpatialPositionProbablyWrong(block.block.start) ||
        isSpatialPositionProbablyWrong(block.block.end)
      ) {
        continue;
      }

      const startX = block.block.start.latitude;
      const startY = block.block.start.longitude;
      const endX = block.block.end.latitude;
      const endY = block.block.end.longitude;

      // ignore blocks without complete location data
      if (!startX || !startY || !endX || !endY) {
        continue;
      }
      // calculate bounds
      hasBounds = true;
      if (isCountableBlock(block)) {
        minX = Math.min(minX, startX, endX);
        maxX = Math.max(maxX, startX, endX);
        minY = Math.min(minY, startY, endY);
        maxY = Math.max(maxY, startY, endY);
      }

      if (
        // don't render colors if metric isn't heatmappable
        !selectedMetric.canMap ||
        // don't render colors if user isn't allowed to see them
        (!hasSpatial && !isInternal) ||
        // don't render stuff that doesn't count
        (!isCountableBlock(block) && !selectedMetric.renderWhenNotCountable)
      ) {
        styleMapping.push(white);
      } else if (isSpatialMetricBoolean(selectedMetric)) {
        const value = selectedMetric.getValue(block);
        const color = selectedMetric.getColor(
          value,
          {
            min: 0,
            low: 0,
            medium: 0,
            high: 1,
            max: 1,
          },
          isRelative
        );
        styleMapping.push(color);
      } else if (isSpatialMetricNumber(selectedMetric) && blockRange) {
        const value = selectedMetric.getValue(block);
        const color = selectedMetric.getColor(value, blockRange, isRelative);
        styleMapping.push(color);
      } else {
        styleMapping.push(white);
      }
      calculatedBlocks.push(block);
    }
    if (hasBounds && minX !== maxX && minY !== maxY) {
      bounds = sanitizeBounds({ minX, maxX, minY, maxY });
    }

    const message: BlockifyRequest = {
      blocks: calculatedBlocks
        .map((block) => block.block)
        .filter((block) => !isUndefined(block)),
      index: 0,
      type: TYPE_BLOCKIFY,
      units: "millimeters",
    };
    mapWorker.postMessage(message);

    setHistoryStatus(HISTORY_STATUS_LOADING);
    return { calculatedBlocks, styleMapping, bounds };
  }, [blocks, selectedMetric, isRelative, hasSpatial, isInternal]);

  // convert features to layers
  const { blockSource, blockLayers, interactiveLayerIds } = useMemo<{
    blockSource: ReactNode | undefined;
    blockLayers: ReactNode[];
    interactiveLayerIds: string[];
  }>(() => {
    const newBlockFeatures: Feature[] = [];
    const blockColors = new Set<string>();
    for (const [index, feature] of blockFeatures.entries()) {
      const block = calculatedBlocks[index];
      const color = styleMapping[index];
      if (!block || !color) {
        continue;
      }
      blockColors.add(color);
      newBlockFeatures.push(
        polygon(feature.geometry.coordinates, {
          ...feature.properties,
          ...getProperties(
            t,
            i18n,
            measurementSystem,
            block,
            selectedMetric,
            cycleSlots
          ),
          _id: block.db?.id,
          _time: (() => {
            const time = SPATIAL_TIME.getValue(block);
            if (!time) {
              return BLANK_VALUE;
            }
            return DateTime.fromJSDate(time).toLocaleString(
              DateTime.DATETIME_MED_WITH_WEEKDAY
            );
          })(),
          _latitude: block.block?.start?.latitude.toFixed(7),
          _longitude: block.block?.start?.longitude.toFixed(7),
          _color: color,
        })
      );
    }
    const blockSource = (
      <Source
        id={SOURCE_ID}
        type="geojson"
        data={featureCollection(newBlockFeatures)}
      />
    );
    const blockLayers: ReactNode[] = [];
    if (showBorders) {
      blockLayers.push(
        <Layer
          id="lines"
          key="lines"
          source={SOURCE_ID}
          type="line"
          paint={{ "line-color": "#fff" }}
        />
      );
    }
    for (const color of blockColors) {
      blockLayers.push(
        <Layer
          key={color}
          beforeId={showBorders ? "lines" : undefined}
          source={SOURCE_ID}
          {...getBlockStyle(color)}
          filter={["==", color, ["get", "_color"]]}
        />
      );
    }
    return {
      blockSource,
      blockLayers,
      interactiveLayerIds: [...blockColors],
    };
  }, [
    blockFeatures,
    calculatedBlocks,
    selectedMetric,
    styleMapping,
    showBorders,
    t,
    measurementSystem,
    i18n,
    cycleSlots,
  ]);

  return {
    blockSource,
    blockLayers,
    bounds,
    historyStatus,
    interactiveLayerIds,
  };
};
