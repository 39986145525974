import * as yup from "yup";
import {
  BLUE_BUTTON,
  BLUE_LOADING_BUTTON,
  classes,
  SELECT_DARK,
  SMALL_INPUT_DARK,
  SMALL_LABEL,
  TEXT_FIELD_DARK,
  WHITE_BUTTON,
} from "portal/utils/theme";
import { Breakpoint, useBreakpoint } from "portal/utils/hooks/useBreakpoint";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { capitalize } from "portal/utils/strings";
import {
  DRAW_STYLE,
  parseBoundary,
  parsePlantingHeading,
} from "portal/utils/geo";
import {
  EffectfulTextField,
  Props as EffectfulTextFieldProps,
} from "portal/components/EffectfulTextField";
import {
  Feature,
  GeoJsonProperties,
  Geometry,
  LineString,
  Polygon,
} from "geojson";
import {
  Field,
  FieldAttributes,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps,
} from "formik";
import { TextField as FormikTextField } from "formik-mui";
import { getCustomerSerial } from "portal/utils/robots";
import { isUndefined } from "portal/utils/identity";
import { keys } from "portal/utils/objects";
import { LoadingButton } from "@mui/lab";
import { Map } from "portal/components/map/Map";
import { RobotSummaryResponse } from "protos/portal/robots";
import { TFunction } from "i18next";
import { useControl } from "react-map-gl";
import {
  useCreateFieldDefinitionMutation,
  useListRobotsQuery,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AddIcon from "@mui/icons-material/AddOutlined";
import MapboxDraw, {
  DrawCreateEvent,
  DrawDeleteEvent,
  DrawMode,
  DrawModeChangeEvent,
  DrawUpdateEvent,
} from "@mapbox/mapbox-gl-draw";
import React, {
  forwardRef,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

interface Props {
  serial?: string;
}

export const RobotFields: FunctionComponent<Props> = withAuthenticationRequired(
  function RobotFields({ serial }) {
    const { t } = useTranslation();
    const { isInternal } = useSelf();
    const formRef = useRef<RobotFieldsFormRef | null>(null);

    const [createFieldDefinition] = useMutationPopups(
      useCreateFieldDefinitionMutation(),
      {
        success: capitalize(
          t("utils.actions.createdLong", {
            subject: t("models.fieldDefinitions.fieldDefinition_one"),
          })
        ),
      }
    );

    const validationSchema = useMemo(() => buildValidationSchema(t), [t]);

    if (!serial || !isInternal) {
      return;
    }

    const initialValues: Values = {
      name: "",
      boundaryText: "",
      plantingHeadingText: "",
    };

    const onSubmit = async (
      values: Values,
      actions: FormikHelpers<Values>
    ): Promise<void> => {
      const { name, boundaryText, plantingHeadingText } = values;
      const fieldDefinition = {
        name,
        boundary: { feature: boundaryText },
        plantingHeading: { feature: plantingHeadingText },
        recordedBoundary: undefined,
        recordedPlantingHeading: undefined,
      };
      await createFieldDefinition({
        serial,
        fieldDefinition,
      });
      actions.resetForm();
    };

    return (
      <Formik<Values>
        initialValues={initialValues}
        onSubmit={onSubmit}
        onReset={() => formRef.current?.resetMapDraw()}
        validationSchema={validationSchema}
      >
        {(props) => <RobotFieldsForm {...props} ref={formRef} />}
      </Formik>
    );
  }
);

enum FeatureType {
  BOUNDARY = "boundary",
  PLANTING_HEADING = "plantingHeading",
}

interface Values {
  name: string;
  boundaryText: string;
  plantingHeadingText: string;
}

type FormKeyOfType<T> = keyof {
  [K in keyof Values as Values[K] extends T ? K : never]: true;
};

const buildValidationSchema = (t: TFunction): yup.Schema<Values> => {
  // Create a yup validator that returns an error if the given callback throws.
  const tryParseTest =
    <T,>(validateOrThrow: (value: T) => void): yup.TestFunction<T> =>
    (value: T, context: yup.TestContext): boolean | yup.ValidationError => {
      try {
        validateOrThrow(value);
      } catch (error) {
        const message = error instanceof Error ? error.message : String(error);
        return context.createError({ message });
      }
      return true;
    };
  const requiredText = t("utils.form.required");
  return yup.object().shape({
    name: yup.string().required(requiredText),
    boundaryText: yup
      .string()
      .required(requiredText)
      .test(tryParseTest((text: string) => parseBoundary(t, text))),
    plantingHeadingText: yup
      .string()
      .required(requiredText)
      .test(tryParseTest((text: string) => parsePlantingHeading(t, text))),
  });
};

const featureTypeToKey: Readonly<Record<FeatureType, FormKeyOfType<string>>> =
  Object.freeze({
    [FeatureType.BOUNDARY]: "boundaryText",
    [FeatureType.PLANTING_HEADING]: "plantingHeadingText",
  });

const drawModes: Readonly<Record<FeatureType, DrawMode>> = Object.freeze({
  [FeatureType.BOUNDARY]: "draw_polygon",
  [FeatureType.PLANTING_HEADING]: "draw_line_string",
});

const drawModesValues: Pick<Set<DrawMode>, "has"> = new Set(
  Object.values(drawModes)
);

const EXTRA_DRAW_STYLE = [
  {
    id: "fielddef-boundary",
    type: "line",
    filter: [
      "all",
      ["==", "$type", "Polygon"],
      ["==", "id", FeatureType.BOUNDARY],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "black",
      "line-dasharray": [1],
      "line-width": 2,
    },
  },
  {
    id: "fielddef-planting-heading",
    type: "line",
    filter: [
      "all",
      ["==", "$type", "LineString"],
      ["==", "id", FeatureType.PLANTING_HEADING],
    ],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": "black",
      "line-dasharray": [1],
      "line-width": 3,
    },
  },
];

interface RobotFieldsFormRef {
  resetMapDraw(): void;
}

const stringifyPretty = (x: object): string => JSON.stringify(x, undefined, 2);

const RobotFieldsForm = forwardRef<RobotFieldsFormRef, FormikProps<Values>>(
  function RobotFieldsForm(props, ref) {
    const { dirty, isSubmitting, setFieldTouched, setFieldValue } = props;

    const { t } = useTranslation();
    const breakpoint = useBreakpoint();
    const drawRef = useRef<MapboxDraw | null>(null);

    // Track which feature the user asked to draw, so that when we get a "new
    // feature finished" event, we know which form field to update.
    const [activelyDrawingFeature, _setActivelyDrawingFeatureState] = useState<
      FeatureType | undefined
    >(undefined);
    const activelyDrawingFeatureRef = useRef<FeatureType | undefined>(
      activelyDrawingFeature
    );
    const setActivelyDrawingFeature = useCallback(
      (t: FeatureType | undefined): void => {
        _setActivelyDrawingFeatureState(t);
        activelyDrawingFeatureRef.current = t;
      },
      []
    );

    useImperativeHandle(
      ref,
      (): RobotFieldsFormRef => ({
        resetMapDraw() {
          const draw = drawRef.current;
          if (draw) {
            draw.deleteAll();
            draw.trash(); // cancel current drawing operation
            draw.changeMode("simple_select");
          }
          setActivelyDrawingFeature(undefined);
        },
      }),
      [setActivelyDrawingFeature]
    );

    // Handlers for mapbox-gl-draw events. API reference:
    // https://github.com/mapbox/mapbox-gl-draw/blob/main/docs/API.md#events
    const drawCallbacks = useMemo(() => {
      // Strip the `id` field from a GeoJSON feature and serialize it to JSON text,
      // to be shown in the text field.
      const jsonWithoutId = <G extends Geometry>(
        feature: Feature<G>
      ): string => {
        return stringifyPretty({ ...feature, id: undefined });
      };

      // Work around Formik validation bug:
      // https://github.com/jaredpalmer/formik/issues/2083
      const changeField = <K extends keyof Values>(
        key: K,
        value: Values[K]
      ): Promise<void | FormikErrors<Values>> => {
        return setFieldValue(key, value).finally(() =>
          setFieldTouched(key, true)
        );
      };

      const onCreate = (draw: MapboxDraw, event: DrawCreateEvent): void => {
        const { features } = event;
        const mode = activelyDrawingFeatureRef.current;
        if (!mode || features.length !== 1) {
          // not sure what we're drawing. get rid of it and bail.
          draw.delete(features.map((f) => f.id as string));
          return;
        }
        // Replace the ID with one keyed by the mode so that we can track it.
        const oldFeature = features[0];
        if (!oldFeature) {
          return;
        }
        const newFeature: Feature<Geometry, GeoJsonProperties> = {
          ...oldFeature,
          id: mode,
        };
        let allFeatures = draw.getAll();
        allFeatures = {
          ...allFeatures,
          features: allFeatures.features
            .map((f) => (f.id === oldFeature.id ? newFeature : f))
            .filter((feature) => !isUndefined(feature)),
        };
        draw.set(allFeatures);

        const key = featureTypeToKey[mode];
        changeField(key, jsonWithoutId(newFeature));
        setActivelyDrawingFeature(undefined);
      };

      const onUpdate = (_: MapboxDraw, event: DrawUpdateEvent): void => {
        const { features } = event;
        for (const feature of features) {
          const { id } = feature;
          if (typeof id !== "string") {
            continue;
          }
          const keys: Readonly<Record<string, FormKeyOfType<string>>> =
            featureTypeToKey;
          const key = keys[id];
          if (!key) {
            return;
          }
          changeField(key, jsonWithoutId(feature));
        }
      };

      const onDelete = (_: MapboxDraw, event: DrawDeleteEvent): void => {
        const { features } = event;
        for (const { id } of features) {
          if (typeof id !== "string") {
            continue;
          }
          const keys: Readonly<Record<string, FormKeyOfType<string>>> =
            featureTypeToKey;
          const key = keys[id];
          if (!key) {
            return;
          }
          changeField(key, "");
        }
      };

      const onModeChange = (
        _: MapboxDraw,
        event: DrawModeChangeEvent
      ): void => {
        const { mode } = event;
        // If the user switches modes outside of our control, e.g. by pressing
        // "Escape", check whether we're still drawing.
        if (!drawModesValues.has(mode)) {
          setActivelyDrawingFeature(undefined);
        }
      };

      return {
        onCreate,
        onUpdate,
        onDelete,
        onModeChange,
      };
    }, [setFieldTouched, setFieldValue, setActivelyDrawingFeature]);

    const { isInternal } = useSelf();
    const { data: summaries, isLoading: robotsLoading } = useQueryPopups(
      useListRobotsQuery({})
    );
    const [showRobotId, setShowRobotId] = useState<number>(-1); // -1 means "none"
    const mapRobots = useMemo<RobotSummaryResponse[]>(() => {
      if (!summaries || showRobotId === -1) {
        return [];
      }
      const robot = summaries.find(
        (summary) => summary.robot?.db?.id === showRobotId
      );
      return robot ? [robot] : [];
    }, [summaries, showRobotId]);

    const onStartDraw = (feature: FeatureType): void => {
      const draw = drawRef.current;
      if (!draw) {
        return;
      }
      // Switch to select mode first, to commit any current drawing state (and
      // trigger the update handler) before we change the mode.
      // Test case: click "draw boundary", start drawing a polygon but don't
      // hit enter, click "draw planting heading".
      draw.changeMode("simple_select");
      setActivelyDrawingFeature(feature);
      draw.changeMode<string>(drawModes[feature]);
    };

    const onCancelDraw = (): void => {
      const draw = drawRef.current;
      if (!draw) {
        return;
      }
      setActivelyDrawingFeature(undefined);
      draw.changeMode("simple_select");
    };

    const drawButton = (feature: FeatureType): ReactNode => {
      const active = activelyDrawingFeature === feature;
      const theme = active ? BLUE_BUTTON : WHITE_BUTTON;
      return (
        <Button
          {...theme}
          aria-pressed={active}
          onClick={active ? onCancelDraw : () => onStartDraw(feature)}
          className={classes(theme.className, "md:self-end")}
        >
          {t("views.fieldDefinitions.controls.draw")}
        </Button>
      );
    };

    // Update a feature of the given type in the mapbox-gl-draw state. If
    // `newFeature` is present, it will be updated or inserted. If it is
    // `undefined`, then any corresponding feature will be removed.
    const updateFeature = <G extends Geometry>(
      type: FeatureType,
      newFeature: Feature<G> | undefined
    ): void => {
      const draw = drawRef.current;
      if (!draw) {
        return;
      }
      if (newFeature) {
        const newFeatureWithId: Feature<G> = { ...newFeature, id: type };
        const { features: oldFeatures, ...rest } = draw.getAll();
        let found = false;
        const newFeatures = oldFeatures.map((oldFeature): Feature<Geometry> => {
          if (oldFeature.id === type) {
            found = true;
            return newFeatureWithId;
          } else {
            return oldFeature;
          }
        });
        // found can be set to true as a side-effect above. idk why tsc doesn't know
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (found) {
          draw.set({ ...rest, features: newFeatures });
        } else {
          draw.add(newFeatureWithId);
        }
      } else {
        draw.delete(type);
      }
    };

    const onBoundaryTextChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      const text = event.target.value;
      let feature: Feature<Polygon> | undefined;
      try {
        feature = parseBoundary(t, text);
      } catch {}
      updateFeature(FeatureType.BOUNDARY, feature);
    };

    const onPlantingHeadingTextChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      const text = event.target.value;
      let feature: Feature<LineString> | undefined;
      try {
        feature = parsePlantingHeading(t, text);
      } catch {}
      updateFeature(FeatureType.PLANTING_HEADING, feature);
    };

    const geojsonTextFieldProps = (
      name: "boundaryText" | "plantingHeadingText"
    ): Partial<FieldAttributes<Values> & EffectfulTextFieldProps> => ({
      ...TEXT_FIELD_DARK,
      name,
      component: EffectfulTextField,
      multiline: true,
      className: "flex-grow",
      inputProps: { className: "font-mono text-xs whitespace-pre" },
      onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
        let prettyValue: string;
        try {
          prettyValue = stringifyPretty(JSON.parse(event.target.value));
        } catch {
          return;
        }
        setFieldValue(name, prettyValue);
      },
    });
    const tallFields = breakpoint >= Breakpoint.md;

    const showRobotLabel = t("utils.actions.showLong", {
      subject: t("models.robots.robot_one"),
    });

    return (
      <Form className="h-full flex flex-col md:flex-row gap-3">
        {/* traditional form controls */}
        <div className="flex flex-col gap-3 md:basis-[360px]">
          <Field
            {...TEXT_FIELD_DARK}
            name="name"
            label={t("models.fieldDefinitions.fields.name")}
            component={FormikTextField}
          />
          <div className="flex md:flex-col gap-2 md:gap-1 items-stretch">
            <Field
              {...geojsonTextFieldProps("boundaryText")}
              label={t("models.fieldDefinitions.fields.boundary")}
              onChange={onBoundaryTextChange}
              rows={tallFields ? 10 : 6}
            />
            {drawButton(FeatureType.BOUNDARY)}
          </div>
          <div className="flex md:flex-col gap-2 md:gap-1 items-stretch">
            <Field
              {...geojsonTextFieldProps("plantingHeadingText")}
              label={t("models.fieldDefinitions.fields.plantingHeading")}
              onChange={onPlantingHeadingTextChange}
              rows={tallFields ? 8 : 4}
            />
            {drawButton(FeatureType.PLANTING_HEADING)}
          </div>
          <LoadingButton
            {...BLUE_LOADING_BUTTON}
            disabled={!dirty}
            type="submit"
            loading={isSubmitting}
            startIcon={<AddIcon />}
          >
            {t("utils.actions.create")}
          </LoadingButton>
        </div>
        {/* map (also a form control, but fancy) */}
        <div className="flex flex-grow flex-col items-stretch gap-3">
          <Map
            className="h-full flex-grow"
            robots={mapRobots}
            zoomOnFocusChange={true}
            allowEmpty={mapRobots.length === 0}
            loading={robotsLoading}
            hideMeasure
            extraControls={
              <DrawControl
                ref={drawRef}
                options={{
                  displayControlsDefault: false,
                  styles: [...DRAW_STYLE, ...EXTRA_DRAW_STYLE],
                }}
                handlers={drawCallbacks}
              />
            }
          />
          <FormControl className="">
            <InputLabel {...SMALL_LABEL}>{showRobotLabel}</InputLabel>
            <Select<number>
              {...SELECT_DARK}
              className={classes(SELECT_DARK.classes, "w-full")}
              value={showRobotId}
              defaultValue={-1} // tsc wants this; don't know why
              onChange={(event) => {
                let value: string | number = event.target.value;
                if (typeof value === "string") {
                  value = value.length === 0 ? -1 : Number(value);
                }
                setShowRobotId(value);
              }}
              input={
                <OutlinedInput {...SMALL_INPUT_DARK} label={showRobotLabel} />
              }
            >
              <MenuItem key={-1} value={-1} className="opacity-75 italic">
                {t("utils.descriptors.none")}
              </MenuItem>
              {summaries?.flatMap((summary) => {
                const { robot } = summary;
                if (!robot || !robot.db) {
                  return [];
                }
                const { serial } = robot;
                const { id } = robot.db;
                return [
                  <MenuItem key={id} value={id}>
                    {isInternal ? serial : getCustomerSerial(t, serial)}
                  </MenuItem>,
                ];
              })}
            </Select>
          </FormControl>
        </div>
      </Form>
    );
  }
);

interface DrawControlHandlers {
  onCreate: (draw: MapboxDraw, event: DrawCreateEvent) => void;
  onUpdate: (draw: MapboxDraw, event: DrawUpdateEvent) => void;
  onDelete: (draw: MapboxDraw, event: DrawDeleteEvent) => void;
  onModeChange: (draw: MapboxDraw, event: DrawModeChangeEvent) => void;
}

interface DrawControlProps {
  options: ConstructorParameters<typeof MapboxDraw>[0];
  handlers: DrawControlHandlers;
}

const DrawControl = forwardRef<MapboxDraw, DrawControlProps>(
  function DrawControl(props, ref) {
    const { options, handlers } = props;
    const latestHandlers = useRef<DrawControlHandlers>(handlers);
    useEffect(() => {
      latestHandlers.current = handlers;
    }, [handlers]);

    type RegisteredHandlers = {
      [K in keyof DrawControlHandlers]: (
        event: Parameters<DrawControlHandlers[K]>[1]
      ) => void;
    };
    const registeredHandlers = useRef<RegisteredHandlers>();
    const draw = useControl<MapboxDraw>(
      () => new MapboxDraw(options),
      ({ map }) => {
        const handlers = registeredHandlers.current;
        if (!handlers) {
          return;
        }
        map.on("draw.create", handlers.onCreate);
        map.on("draw.update", handlers.onUpdate);
        map.on("draw.delete", handlers.onDelete);
        map.on("draw.modechange", handlers.onModeChange);
      },
      ({ map }) => {
        const handlers = registeredHandlers.current;
        if (!handlers) {
          return;
        }
        map.off("draw.create", handlers.onCreate);
        map.off("draw.update", handlers.onUpdate);
        map.off("draw.delete", handlers.onDelete);
        map.off("draw.modechange", handlers.onModeChange);
      }
    );

    if (!registeredHandlers.current) {
      registeredHandlers.current = Object.fromEntries(
        keys(handlers).map((k: keyof DrawControlHandlers) => [
          k,
          (event: any) => latestHandlers.current[k](draw, event),
        ])
      ) as RegisteredHandlers;
    }

    useImperativeHandle(ref, () => draw, [draw]);

    return <></>;
  }
);
