import { Button, IconButton, Link, Modal, Tooltip } from "@mui/material";
import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { DateTime } from "luxon";
import { debounce } from "portal/utils/timing";
import { getImageDate, getImageLocation } from "portal/utils/images";
import { GridColDef, GridPaginationModel } from "@mui/x-data-grid-premium";
import { Image } from "protos/portal/veselka";
import { isUndefined } from "portal/utils/identity";
import { NoScroll } from "portal/components/Page";
import { titleCase } from "portal/utils/strings";
import { useLazyListUploadsQuery } from "portal/state/portalApi";
import { useLazyPopups } from "portal/utils/hooks/useApiPopups";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import NextIcon from "@mui/icons-material/ArrowForwardIos";
import PreviousIcon from "@mui/icons-material/ArrowBackIosNew";
import React, { FunctionComponent, useState } from "react";

const defaultColumn: Partial<GridColDef> = {
  sortable: false,
  disableColumnMenu: true,
};

const LIMIT = 100;

export const RobotUploads: FunctionComponent = withAuthenticationRequired(
  function RobotUploads() {
    const { serial } = useParams();
    const { t, i18n } = useTranslation();

    const [pagination, setPagination] = useState<GridPaginationModel>({
      page: 1,
      pageSize: LIMIT,
    });
    const [uploads, setUploads] = useState<Image[]>([]);
    const [listUploads, { isLoading, isSuccess }] = useLazyPopups(
      useLazyListUploadsQuery()
    );

    const [lightbox, setLightbox] = useState<Image | undefined>();

    const columns: GridColDef<Image>[] = [
      {
        ...defaultColumn,
        field: "capturedAt",
        headerName: t("models.images.fields.capturedAt"),
        renderCell: ({ row: image }) => {
          const date = getImageDate(image);
          if (!date) {
            return "Unknown";
          }
          return (
            <Tooltip
              arrow
              title={date.toLocaleString(DateTime.DATETIME_FULL, {
                locale: i18n.language,
              })}
            >
              <span>{date.toRelative({ locale: i18n.language })}</span>
            </Tooltip>
          );
        },
      },
      {
        ...defaultColumn,
        field: "crop",
        headerName: titleCase(t("models.crops.crop_one")),
      },
      {
        ...defaultColumn,
        field: "geoJson",
        headerName: t("models.images.fields.geoJson"),
        width: 200,
        valueGetter: (value, image) => {
          const [geohash] = getImageLocation(image);
          return geohash;
        },
        cellClassName: "cursor-pointer underline text-blue-500",
        renderCell: ({ row: image }) => {
          const [geohash, point] = getImageLocation(image);
          if (!point) {
            return geohash;
          }
          return (
            <Link
              href={`https://www.google.com/maps/search/${point.join(",")}`}
              target="_blank"
              color="secondary"
              className="font-mono text-sm"
            >
              {geohash}
            </Link>
          );
        },
      },
      {
        ...defaultColumn,
        field: "camera",
        width: 150,
        headerName: t("models.images.fields.camera"),
        valueGetter: (value, image) => {
          const rowName = titleCase(image.rowId).replace(/(\d+)$/, " $1");
          let camera = image.camId;
          if (camera) {
            camera = titleCase(camera).replace(/(\d+)$/, " $1");
          }
          const rowPrefix = rowName ? `${rowName} - ` : "";
          return `${rowPrefix}${camera}`;
        },
      },
      {
        ...defaultColumn,
        field: "url",
        headerName: titleCase(t("models.images.image_one")),
        renderCell: ({ row: image }) => (
          <Button
            variant="text"
            onClick={() => setLightbox(image)}
            color="secondary"
          >
            {t("models.images.fields.url")}
          </Button>
        ),
      },
    ];

    if (isSuccess && uploads.length === 0) {
      return (
        <div className="flex flex-col h-full w-full items-center justify-center">
          <div className="text-lighten-400 flex items-center gap-2">
            {t("views.fleet.robots.uploads.errors.empty")}
          </div>
        </div>
      );
    }

    return (
      <NoScroll>
        <CarbonDataGrid<Image>
          rows={uploads}
          getRowId={(row) => row.id}
          columns={columns}
          disableRowSelectionOnClick
          loading={isLoading}
          initialState={{
            sorting: { sortModel: [{ field: "capturedAt", sort: "desc" }] },
          }}
          paginationModel={pagination}
          onPaginationModelChange={debounce(async ({ page, pageSize }) => {
            setPagination({ page, pageSize });
            if (!serial) {
              return;
            }
            if ((page + 1) * pageSize >= uploads.length) {
              const data = await listUploads(
                {
                  serial,
                  pagination: {
                    page: uploads.length / pageSize + 1,
                    limit: LIMIT,
                  },
                },
                true
              );
              setUploads([...uploads, ...(data.data ?? [])]);
            }
          })}
          pagination
          autoPageSize
        />
        <Modal
          key={lightbox?.id}
          open={!isUndefined(lightbox)}
          onClose={() => setLightbox(undefined)}
          className="max-h-full max-w-full m-20 flex justify-center items-center gap-8"
        >
          <>
            <IconButton
              onClick={() => {
                const index = uploads.findIndex(
                  (image) => image.id === lightbox?.id
                );
                if (index > 0) {
                  setLightbox(uploads[index - 1]);
                }
              }}
              size="large"
              className="flex-shrink-0 text-white text-3xl"
            >
              <PreviousIcon />
            </IconButton>
            <img
              src={lightbox?.url}
              alt={String(lightbox?.id)}
              className="max-w-full max-h-full bg-gray-500"
            />
            <IconButton
              onClick={() => {
                const index = uploads.findIndex(
                  (image) => image.id === lightbox?.id
                );
                if (index !== uploads.length - 1) {
                  setLightbox(uploads[index - 1]);
                }
              }}
              size="large"
              className="flex-shrink-0 text-white text-3xl"
            >
              <NextIcon />
            </IconButton>
          </>
        </Modal>
      </NoScroll>
    );
  }
);
