import {
  Alert,
  Button,
  capitalize,
  IconButton,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/SaveOutlined";

import { AdvancedFormulaCard } from "./AdvancedFormulaCard";
import { areRowsSynced } from "portal/utils/velocityEstimator";
import { BLUE_BUTTON, RED_BUTTON } from "portal/utils/theme";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { FormulaCard } from "./FormulaCard";
import { isUndefined } from "portal/utils/identity";
import { Loading } from "../Loading";
import { skipToken } from "@reduxjs/toolkit/query";
import { titleCase } from "portal/utils/strings";
import {
  TVEProfile,
  TVERowProfile,
} from "protos/target_velocity_estimator/target_velocity_estimator";
import {
  useDeleteGlobalTargetVelocityEstimatorMutation,
  useDeleteTargetVelocityEstimatorMutation,
  useGetGlobalTargetVelocityEstimatorQuery,
  useGetRobotQuery,
  useGetTargetVelocityEstimatorQuery,
  useSetGlobalTargetVelocityEstimatorMutation,
  useSetTargetVelocityEstimatorMutation,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import ParentIcon from "@mui/icons-material/ArrowBackOutlined";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";

interface Props {
  adminEditing?: boolean;
  parentLink?: string;
  serial?: string;
  uuid: string;
  useGetQuery:
    | typeof useGetTargetVelocityEstimatorQuery
    | typeof useGetGlobalTargetVelocityEstimatorQuery;
  useSetMutation:
    | typeof useSetTargetVelocityEstimatorMutation
    | typeof useSetGlobalTargetVelocityEstimatorMutation;
  useDeleteMutation:
    | typeof useDeleteTargetVelocityEstimatorMutation
    | typeof useDeleteGlobalTargetVelocityEstimatorMutation;
}

export const VelocityEstimator: FunctionComponent<Props> = ({
  adminEditing = false,
  uuid,
  parentLink,
  serial,
  useGetQuery,
  useSetMutation,
  useDeleteMutation,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: summary } = useQueryPopups(
    useGetRobotQuery(serial ? { serial } : skipToken),
    { errorVariant: "warning" }
  );
  // view state
  const [isSyncAllRows, setSyncAllRows] = useState<boolean>(true);

  // local changes
  const [updatedTve, setUpdatedTve] = useState<TVEProfile | undefined>();

  // remote data
  const {
    data: freezedTargetVelocityEstimator,
    isError,
    error,
  } = useQueryPopups(useGetQuery({ uuid }));
  useEffect(() => {
    setSyncAllRows(areRowsSynced(freezedTargetVelocityEstimator));
  }, [freezedTargetVelocityEstimator]);

  // mutations
  const [updateTve] = useMutationPopups(useSetMutation(), {
    success: capitalize(
      t("utils.actions.savedLong", {
        subject: titleCase(
          t("models.velocityEstimators.velocityEstimator_one")
        ),
      })
    ),
  });
  const [deleteTve] = useMutationPopups(useDeleteMutation(), {
    success: titleCase(
      t("utils.actions.deletedLong", {
        subject: titleCase(
          t("models.velocityEstimators.velocityEstimator_one")
        ),
      })
    ),
  });

  // confirmation dialog
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  // unfreezed state
  const unfreezedTargetVelocityEstimator = useMemo<TVEProfile | undefined>(
    () =>
      freezedTargetVelocityEstimator
        ? TVEProfile.fromPartial(
            structuredClone(freezedTargetVelocityEstimator)
          )
        : undefined,
    [freezedTargetVelocityEstimator]
  );

  // visible state
  const targetVelocityEstimator =
    updatedTve ?? unfreezedTargetVelocityEstimator;
  const isActive =
    !isUndefined(targetVelocityEstimator?.id) &&
    targetVelocityEstimator.id ===
      summary?.robot?.health?.fieldConfig?.activeDiscriminatorId;

  const { isInternal } = useSelf();
  const canEdit =
    (adminEditing && isInternal) ||
    !(targetVelocityEstimator?.protected ?? false);

  if (!targetVelocityEstimator) {
    return <Loading failed={isError} error={error} />;
  }

  return (
    <div className="flex flex-col min-h-full">
      {adminEditing && (
        <Alert severity="warning" className="mb-8">
          {t("components.velocityEstimator.configs.warnings.admin")}
        </Alert>
      )}
      {/* Header */}
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-8 w-full gap-4">
        {/* Left Controls */}
        <div className="flex items-center">
          {/* Breadcrumb */}
          {parentLink && (
            <IconButton component={Link} to={parentLink} className="text-white">
              <ParentIcon />
            </IconButton>
          )}
        </div>

        {/* Title */}
        <Typography variant="h4">{targetVelocityEstimator.name}</Typography>

        {/* Right Controls */}
        <div className="flex items-center gap-4">
          {canEdit && (
            <Button
              {...RED_BUTTON}
              onClick={() => setConfirmDelete(true)}
              startIcon={<DeleteIcon />}
            >
              {t("utils.actions.delete")}
            </Button>
          )}
          {confirmDelete && (
            <ConfirmationDialog
              title={t("utils.actions.deleteLong", {
                subject: t("models.velocityEstimators.velocityEstimator_one"),
              })}
              description={
                isActive ? (
                  <Alert severity="warning" className="mb-8">
                    {t(
                      "components.ConfirmationDialog.delete.descriptionActive",
                      {
                        subject: t(
                          "models.velocityEstimators.velocityEstimator_one"
                        ),
                      }
                    )}
                  </Alert>
                ) : (
                  t("components.ConfirmationDialog.delete.description", {
                    subject: t(
                      "models.velocityEstimators.velocityEstimator_one"
                    ),
                  })
                )
              }
              destructive
              yesText={t("utils.actions.delete", {
                subject: targetVelocityEstimator.name,
              })}
              yesDisabled={isActive}
              onClose={() => setConfirmDelete(false)}
              onYes={async () => {
                await deleteTve({ uuid: targetVelocityEstimator.id });
                if (parentLink) {
                  navigate(parentLink);
                }
              }}
            />
          )}
          <Button
            variant="text"
            color="info"
            classes={{ disabled: "text-lighten-200" }}
            onClick={() => {
              setUpdatedTve(undefined);
              setSyncAllRows(false);
            }}
          >
            {t("utils.actions.cancel")}
          </Button>
          <Button
            {...BLUE_BUTTON}
            onClick={async () => {
              setUpdatedTve(targetVelocityEstimator);
              await updateTve({ serial, targetVelocityEstimator });
              setUpdatedTve(undefined);
            }}
            disabled={!updatedTve}
            startIcon={<SaveIcon />}
          >
            {t("utils.actions.save")}
          </Button>
        </div>
      </div>

      {isActive && (
        <Alert severity="warning" className="mb-8">
          {t("components.velocityEstimator.configs.warnings.production")}
        </Alert>
      )}

      {updatedTve && updatedTve !== unfreezedTargetVelocityEstimator && (
        <Alert severity="warning" className="mb-8">
          {t("components.velocityEstimator.configs.warnings.unsavedChanges")}
        </Alert>
      )}

      {!canEdit && (
        <Alert severity="warning" className="mb-8">
          {t("components.velocityEstimator.configs.warnings.protected")}
        </Alert>
      )}

      {/* Main View */}
      <FormulaCard
        tveProfile={targetVelocityEstimator}
        areRowsSynced={isSyncAllRows}
        onToggleSyncAllRows={() => {
          if (!isSyncAllRows) {
            const firstRowConfig =
              targetVelocityEstimator.rows[1] ?? TVERowProfile.fromPartial({});
            setUpdatedTve({
              ...targetVelocityEstimator,
              rows: {
                1: firstRowConfig,
                2: firstRowConfig,
                3: firstRowConfig,
              },
            });
          }
          return setSyncAllRows(!isSyncAllRows);
        }}
        setUpdatedTve={setUpdatedTve}
        canEdit={canEdit}
      />

      <AdvancedFormulaCard
        tveProfile={targetVelocityEstimator}
        setUpdatedTve={setUpdatedTve}
        canEdit={canEdit}
      />
    </div>
  );
};
