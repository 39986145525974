import { useEffect, useMemo, useRef, useState } from "react";

/**
 * TODO: should be replaced with useIntervalWhen from usehooks when that's stable
 * (currently it relies on experimental React features)
 *
 * This hook manages an interval timer that can be dynamically edited for timing,
 * and has pause/play/toggle functions. Also exposes pause state to consumers
 */
interface IntervalState {
  play: () => void;
  pause: () => void;
  toggle: () => void;
  reset: () => void;
  isPaused: boolean;
}

export const useInterval = (
  callback: () => void,
  delay: number,
  startPaused: boolean = false
): IntervalState => {
  const intervalRef = useRef<NodeJS.Timeout>();
  const [isPaused, setPaused] = useState(startPaused);

  // keep track of the latest callback:
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (isPaused) {
      return;
    }

    intervalRef.current = setInterval(() => callbackRef.current(), delay);

    return () => clearInterval(intervalRef.current);
  }, [delay, isPaused]);

  return useMemo<IntervalState>(
    () => ({
      isPaused,
      play: () => setPaused(false),
      pause: () => setPaused(true),
      toggle: () => setPaused((isPaused) => !isPaused),
      reset: () => {
        clearInterval(intervalRef.current);
        if (isPaused) {
          return;
        }
        intervalRef.current = setInterval(() => callbackRef.current(), delay);
      },
    }),
    [delay, isPaused]
  );
};
