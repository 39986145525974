import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { capitalize } from "portal/utils/strings";
import { classes, INPUT, LABEL, RED_BUTTON, SELECT } from "portal/utils/theme";
import { ConfirmationDialog } from "portal/components/ConfirmationDialog";
import {
  DEFAULT_LANG,
  DISPLAY_LANGUAGES,
  isPortalLanguage,
  LANGUAGES,
  portalLanguageOrDefault,
} from "portal/i18nConstants";
import { Header } from "portal/components/header/Header";
import { isExperimental } from "portal/utils/auth";
import { Loading } from "portal/components/Loading";
import { MeasurementSystem } from "portal/utils/units/units";
import { Page } from "portal/components/Page";
import { Path } from "portal/utils/routing";
import { setLocale } from "portal/utils/i18n";
import { Trans, useTranslation } from "react-i18next";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
  useDeleteUserMutation,
  useUpdateUserMutation,
} from "portal/state/portalApi";
import { useMutationPopups } from "portal/utils/hooks/useApiPopups";
import { useNavigate } from "react-router-dom";
import { useSelf } from "portal/state/store";
import { WithSkeleton } from "portal/components/WithSkeleton";
import DeleteAccountIcon from "@mui/icons-material/DeleteForever";
import Grid from "@mui/system/Unstable_Grid";
import React, { FunctionComponent, useEffect, useState } from "react";
import type { TFunction } from "i18next";

const getIdentityProvider = (t: TFunction, userId: string): string => {
  if (userId.startsWith("google-oauth2|")) {
    return t("views.settings.accountProvider.google");
  } else if (userId.startsWith("apple|")) {
    return t("views.settings.accountProvider.apple");
  } else if (userId.startsWith("auth0|")) {
    return t("views.settings.accountProvider.auth0");
  } else {
    return t("views.settings.accountProvider.unknown");
  }
};

export const Settings: FunctionComponent = withAuthenticationRequired(
  function Settings() {
    const { logout } = useAuth0();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { user, isInternal, measurementSystem, isLoading } = useSelf();

    const [updateUser] = useMutationPopups(useUpdateUserMutation(), {
      success: capitalize(
        t("utils.actions.savedLong", {
          subject: t("models.users.user_one"),
        })
      ),
    });

    const [deleteUser] = useMutationPopups(useDeleteUserMutation(), {
      success: capitalize(
        t("utils.actions.deletedLong", {
          subject: t("models.users.user_one"),
        })
      ),
    });

    const [unit, setUnit] = useState<MeasurementSystem>(
      MeasurementSystem.imperial
    );

    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    useEffect(() => {
      setUnit(measurementSystem);
    }, [measurementSystem]);

    if (!user) {
      return <Loading failed={!isLoading} />;
    }

    return (
      <>
        <Header title={t("views.settings.title")}>
          <div className="w-full flex items-center justify-end">
            <Button
              variant="text"
              classes={{ text: "text-white" }}
              onClick={() =>
                logout({ logoutParams: { returnTo: window.location.origin } })
              }
            >
              {t("views.settings.logOut")}
            </Button>
          </div>
        </Header>
        <Page>
          <Grid container className="w-full items-stretch" spacing={2}>
            <Grid xs={12} lg={6}>
              <Card className="p-4 h-full flex flex-col gap-2">
                <Typography variant="h4" className="mb-2 text-lg">
                  {t("views.settings.cards.localization")}
                </Typography>

                <FormControl>
                  <InputLabel {...LABEL}>
                    {t("views.settings.fields.language")}
                  </InputLabel>
                  <Select<string>
                    {...SELECT}
                    classes={{
                      ...SELECT.classes,
                      root: classes(SELECT.classes?.root, "w-full"),
                    }}
                    input={
                      <OutlinedInput
                        {...INPUT}
                        label={t("views.settings.fields.language")}
                      />
                    }
                    defaultValue={DEFAULT_LANG}
                    placeholder={t("views.settings.fields.language")}
                    value={i18n.language}
                    onChange={(event) => {
                      const locale = portalLanguageOrDefault(
                        event.target.value
                      );
                      setLocale(i18n, locale);
                      if (user.userId) {
                        updateUser({
                          userId: user.userId,
                          user: { userMetadata: { language: locale } },
                        });
                      }
                    }}
                    renderValue={(value) =>
                      capitalize(
                        isPortalLanguage(value)
                          ? new Intl.DisplayNames([value], {
                              type: "language",
                            }).of(DISPLAY_LANGUAGES[value])
                          : ""
                      )
                    }
                  >
                    {LANGUAGES.map((language) => (
                      <MenuItem value={language} key={language}>
                        {capitalize(
                          new Intl.DisplayNames([language], {
                            type: "language",
                          }).of(DISPLAY_LANGUAGES[language])
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <WithSkeleton
                  variant="rectangular"
                  className="min-h-6 min-w-12 mr-4"
                  success={Boolean(user)}
                >
                  <FormControl>
                    <FormLabel>
                      {t("views.settings.fields.measurement.name")}
                    </FormLabel>
                    <RadioGroup
                      row
                      name="measurement-system"
                      value={unit}
                      onChange={(event) => {
                        const value = event.target.value as MeasurementSystem;
                        setUnit(value);
                        if (!user.email || !user.userId) {
                          return;
                        }
                        updateUser({
                          userId: user.userId,
                          user: { userMetadata: { unit: value } },
                        });
                      }}
                    >
                      {[
                        MeasurementSystem.imperial,
                        MeasurementSystem.metric,
                      ].map((unitType) => (
                        <FormControlLabel
                          key={unitType}
                          value={unitType}
                          control={<Radio />}
                          // carbon.actions.compareKeys.ignoreDynamic
                          label={t(
                            `views.settings.fields.measurement.values.${unitType}`
                          )}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </WithSkeleton>
              </Card>
            </Grid>
            <Grid xs={12} lg={6}>
              <Card className="p-4 h-full flex flex-col gap-2">
                <Typography variant="h4" className="mb-2 text-lg">
                  {t("views.settings.cards.advanced")}
                </Typography>
                {isInternal && (
                  <FormControl>
                    <FormControlLabel
                      className="flex-shrink-0"
                      control={
                        <Switch
                          checked={isExperimental(isInternal, user)}
                          color="warning"
                          onChange={(event, checked) => {
                            if (!user.email || !user.userId) {
                              return;
                            }
                            updateUser({
                              userId: user.userId,
                              user: { userMetadata: { experimental: checked } },
                            });
                          }}
                        />
                      }
                      label={t("views.settings.fields.experimental")}
                    />
                  </FormControl>
                )}
              </Card>
            </Grid>
            <Grid xs={12} lg={6}>
              <Card className="p-4 h-full flex flex-col gap-2">
                <Typography variant="h4" className="mb-2 text-lg">
                  {t("views.settings.cards.account")}
                </Typography>
                <Typography title={user.userId}>
                  {user.userId ? (
                    <Trans
                      i18nKey="views.settings.accountProvider.account"
                      t={t}
                      values={{
                        email: user.email,
                        identityProvider: getIdentityProvider(t, user.userId),
                      }}
                      components={[
                        <strong key="email" />,
                        <span key="identityProvider" />,
                      ]}
                    />
                  ) : (
                    <strong>{user.email}</strong>
                  )}
                </Typography>
                {confirmDelete && (
                  <ConfirmationDialog
                    title={t("views.settings.cards.account")}
                    description={t("views.settings.delete.dialog.description")}
                    destructive
                    yesText={t("utils.actions.deleteLong", {
                      subject: user.email,
                    })}
                    onClose={() => setConfirmDelete(false)}
                    onYes={async () => {
                      if (user.userId) {
                        await deleteUser({ userId: user.userId });
                        navigate(Path.LOGOUT);
                      }
                    }}
                  />
                )}
                <div>
                  <Button
                    {...RED_BUTTON}
                    onClick={() => setConfirmDelete(true)}
                    startIcon={<DeleteAccountIcon />}
                  >
                    {t("views.settings.delete.deleteAccount")}
                  </Button>
                </div>
              </Card>
            </Grid>
            <Grid xs={12} lg={6}>
              <span className="block font-mono text-xs text-right text-lighten-500">
                {t("views.settings.version", {
                  version: window._jsenv.REACT_APP_RELEASE,
                  hash: window._jsenv.REACT_APP_COMMITHASH.slice(0, 8),
                })}
              </span>
            </Grid>
          </Grid>
        </Page>
      </>
    );
  }
);
