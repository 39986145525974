import {
  Button,
  Chip,
  ChipProps,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { BUTTON, classes } from "portal/utils/theme";
import { capitalize, formatList, titleCase } from "portal/utils/strings";
import { findWhere } from "portal/utils/arrays";
import { FleetRobotCards } from "./FleetRobotCards";
import { FleetRobotTable } from "./FleetRobotTable";
import { FleetSubpath, getFleetPath, getRobotPath } from "portal/utils/routing";
import { FleetView, ViewMode, viewModeFromJSON } from "protos/portal/users";
import { FleetViewEditor } from "./FleetViewEditor";
import {
  generateAliases,
  ImplementationStatus,
  isOffline,
  toImplementationStatus,
} from "portal/utils/robots";
import {
  generateDefaultFleetView,
  getColumns,
  getDefaultFleetView,
  GroupedRobotSummaryResponse,
} from "portal/utils/fleetViews";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid-premium";
import { isUndefined } from "portal/utils/identity";
import { Loading } from "portal/components/Loading";
import { Map } from "portal/components/map/Map";
import { RobotSummaryResponse } from "protos/portal/robots";
import { SearchField } from "portal/components/header/SearchField";
import { setFleetView } from "portal/state/self";
import {
  useCreateFleetViewMutation,
  useListCustomersQuery,
  useListRobotsQuery,
  useUpdateUserMutation,
} from "portal/state/portalApi";
import { useDispatch } from "react-redux";
import { useFuzzySearch } from "portal/utils/hooks/useFuzzySearch";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useNavigate } from "react-router-dom";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { values } from "portal/utils/objects";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AddIcon from "@mui/icons-material/AddOutlined";
import DropdownIcon from "@mui/icons-material/ArrowDropDownOutlined";
import EmptyStarIcon from "@mui/icons-material/StarBorderOutlined";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import FullStarIcon from "@mui/icons-material/StarOutlined";
import Fuse from "fuse.js";
import MissionControlIcon from "@mui/icons-material/SlideshowOutlined";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";

export const FleetRobotList: FunctionComponent = withAuthenticationRequired(
  function FleetRobotList() {
    const dispatch = useDispatch();
    const { isInternal, user, fleetViews, measurementSystem } = useSelf();
    const { t, i18n } = useTranslation();
    const apiRef = useGridApiRef();
    const newView = useMemo<FleetView>(
      () => generateDefaultFleetView(t, user),
      [t, user]
    );

    const [menuAnchor, setMenuAnchor] = useState<
      HTMLButtonElement | undefined
    >();
    const defaultView = useMemo(
      () => getDefaultFleetView(fleetViews, user),
      [fleetViews, user]
    );
    const [selectedView, setSelectedView] = useState<number | undefined>(
      defaultView?.db?.id
    );
    const fleetView = useMemo<FleetView>(() => {
      const fallback = defaultView ?? newView;
      if (!selectedView) {
        return fallback;
      }
      const selected = fleetViews[selectedView];
      return selected ?? fallback;
    }, [fleetViews, defaultView, newView, selectedView]);

    // get all robots
    const { data: summaries, isLoading } = useQueryPopups(
      useListRobotsQuery({})
    );

    // editing state for fleet view
    const [isEditing, setEditing] = useState<boolean>(false);
    const [localView, setLocalView] = useState<FleetView | undefined>(
      fleetView
    );
    useEffect(() => {
      setLocalView(fleetView);
    }, [fleetView]);

    const { data: customers, isSuccess: isCustomerSuccess } = useQueryPopups(
      useListCustomersQuery(undefined, {
        skip:
          !isInternal ||
          !localView?.customerIds ||
          localView.customerIds.length === 0,
      })
    );

    const chipProps: ChipProps = {
      onClick: () => setEditing(true),
      className: "bg-zinc-700 text-white",
    };

    const navigate = useNavigate();

    const [createFleetView] = useMutationPopups(useCreateFleetViewMutation(), {
      success: capitalize(
        t("utils.actions.createdLong", {
          subject: capitalize(t("views.fleet.views.fleetView_one")),
        })
      ),
    });
    const [updateUser] = useMutationPopups(useUpdateUserMutation(), {
      success: capitalize(t("utils.actions.saved")),
    });

    const filteredSummaries = useMemo<RobotSummaryResponse[]>(() => {
      if (!summaries) {
        return [];
      }
      return summaries.filter((summary) => {
        const isRobotInternal = !summary.customer;
        const implementationStatus =
          summary.robot?.implementationStatus ?? ImplementationStatus.ACTIVE;
        if (!localView) {
          return false;
        } else if (!localView.showOffline && isOffline(summary.robot)) {
          return false;
        } else if (
          isRobotInternal &&
          (!isInternal || !localView.showInternal)
        ) {
          return false;
        } else if (
          localView.customerIds.length > 0 &&
          !localView.customerIds.includes(summary.customer?.db?.id ?? -1)
        ) {
          return false;
        } else if (
          localView.statuses.includes(
            toImplementationStatus(implementationStatus)
          )
        ) {
          return true;
        } else {
          return false;
        }
      });
    }, [isInternal, localView, summaries]);

    const columnIds = localView?.columns;
    const columns = useMemo<GridColDef<GroupedRobotSummaryResponse>[]>(() => {
      if (!user || !columnIds) {
        return [];
      }
      const allColumns = getColumns(i18n, t, user, measurementSystem);
      return columnIds
        .map((id) => findWhere(allColumns, { id }))
        .filter((column) => !isUndefined(column));
    }, [columnIds, i18n, measurementSystem, t, user]);

    const options = useMemo<Fuse.IFuseOptions<RobotSummaryResponse>>(() => {
      return {
        keys: [
          "robot.serial",
          {
            name: "aliases",
            getFn: (summary) => generateAliases(t, summary.robot),
          },
          ...columns
            // don't search for non-string columns
            .filter((column) => column.type === "string")
            .map<Fuse.FuseOptionKey<RobotSummaryResponse>>((column) => ({
              name: column.field,
              getFn: (summary) => {
                let value = summary[column.field as keyof RobotSummaryResponse];
                if (column.valueGetter) {
                  value = column.valueGetter(
                    value as never,
                    { ...summary, group: "" },
                    column,
                    apiRef
                  );
                }
                return String(value ?? "");
              },
            })),
        ],
      };
    }, [apiRef, columns, t]);

    const { searchText, setSearchText, results } =
      useFuzzySearch<RobotSummaryResponse>({
        items: filteredSummaries,
        options,
      });

    if (!localView) {
      return <Loading />;
    }

    const { pinnedRobotIds } = localView;

    return (
      <>
        <FleetViewEditor
          view={localView}
          open={isEditing}
          onChange={setLocalView}
          onCancel={() => {
            setLocalView(fleetView);
            setEditing(false);
          }}
          onSave={(view) => {
            setEditing(false);
            setSelectedView(view.db?.id);
          }}
        />
        <div className="flex justify-between items-center pb-2">
          <div>
            <Button
              onClick={
                isLoading
                  ? undefined
                  : (event) => setMenuAnchor(event.currentTarget)
              }
              variant="text"
              className="text-white normal-case"
              endIcon={
                isLoading ? (
                  <CircularProgress color="info" className="w-3 h-3" />
                ) : (
                  <DropdownIcon />
                )
              }
            >
              <Typography variant="h3">{localView.name}</Typography>
            </Button>
            <Menu
              onClose={() => setMenuAnchor(undefined)}
              open={!isUndefined(menuAnchor)}
              anchorEl={menuAnchor}
              className="max-h-[95%]"
            >
              {values(fleetViews).map((fleetView) => {
                const isDefault =
                  fleetView.db?.id === user?.userMetadata?.defaultFleetViewId;
                return (
                  <MenuItem
                    value={fleetView.db?.id}
                    key={fleetView.db?.id}
                    className="group"
                    onClick={() => {
                      setMenuAnchor(undefined);
                      setSelectedView(fleetView.db?.id);
                    }}
                  >
                    <IconButton
                      size="small"
                      className={classes(
                        "opacity-0 group-hover:opacity-100 text-white",
                        {
                          "opacity-100": isDefault,
                        }
                      )}
                      onClick={async () => {
                        if (!user?.userId) {
                          return;
                        }
                        await updateUser({
                          userId: user.userId,
                          user: {
                            userMetadata: {
                              defaultFleetViewId: fleetView.db?.id,
                            },
                          },
                        });
                      }}
                    >
                      {isDefault ? <FullStarIcon /> : <EmptyStarIcon />}
                    </IconButton>
                    {fleetView.name}
                  </MenuItem>
                );
              })}
              <MenuItem
                className="group"
                onClick={async () => {
                  if (!user?.userId) {
                    return;
                  }
                  setMenuAnchor(undefined);
                  const response = await createFleetView({
                    userId: user.userId,
                    fleetView: newView,
                  });
                  if (!("data" in response) || !response.data) {
                    return;
                  }
                  const newData = response.data;
                  dispatch(setFleetView(newData));
                  setSelectedView(newData.db?.id);
                  setEditing(true);
                }}
              >
                <IconButton size="small" className="text-white">
                  <AddIcon />
                </IconButton>
                {t("utils.actions.newLong", {
                  subject: t("views.fleet.views.fleetView_one"),
                })}
              </MenuItem>
            </Menu>
            <Button
              {...BUTTON}
              className="my-4"
              onClick={() => setEditing(true)}
              startIcon={<FilterIcon />}
            >
              {t("utils.actions.editLong", {
                subject: t("views.fleet.views.fleetView_one"),
              })}
            </Button>
          </div>
          <SearchField
            dark
            value={searchText}
            onChange={setSearchText}
            onSelect={() => {
              const serial = results[0]?.robot?.serial;
              if (!serial) {
                return;
              }
              navigate(getRobotPath(serial));
            }}
            placeholder={localView.search}
          />
        </div>
        <div className="flex items-center gap-3 pb-4 flex-wrap">
          {isInternal && localView.customerIds.length > 0 && (
            <Chip
              {...chipProps}
              label={`${titleCase(
                t("models.customers.customer", {
                  count: localView.customerIds.length,
                })
              )}: ${
                isCustomerSuccess
                  ? formatList(
                      t,
                      customers
                        .filter(
                          (customer) =>
                            customer.db &&
                            localView.customerIds.includes(customer.db.id)
                        )
                        .map(({ name }) => name)
                    )
                  : t("components.Loading.placeholder")
              }`}
            />
          )}
          {localView.statuses.length > 0 && (
            <Chip
              {...chipProps}
              label={`${t(
                "components.RobotImplementationSelector.status"
              )}: ${formatList(
                t,
                localView.statuses.map((status) =>
                  // carbon.actions.compareKeys.ignoreDynamic
                  t(`components.robots.RobotSummary.${status}`)
                )
              )}`}
            />
          )}
          <Chip
            {...chipProps}
            label={`${t("utils.descriptors.offline")}: ${
              localView.showOffline
                ? t("utils.descriptors.yes")
                : t("utils.descriptors.no")
            }`}
          />
          {isInternal && (
            <Chip
              {...chipProps}
              label={`${t("views.fleet.robots.toggleable.internal")}: ${
                localView.showInternal
                  ? t("utils.descriptors.yes")
                  : t("utils.descriptors.no")
              }`}
            />
          )}
        </div>
        {localView.showMap && (
          <Map
            robots={results}
            className="w-full max-h-96 min-h-48 mb-2"
            zoomToFacilities
            loading={isLoading}
            extraControls={
              <Tooltip
                title={t("views.fleet.missionControl.title")}
                placement="right"
                arrow
              >
                <div
                  className={classes(
                    "mt-[10px] ml-[10px] print:hidden",
                    "mapboxgl-ctrl mapboxgl-ctrl-group w-fit"
                  )}
                >
                  <button
                    onClick={() =>
                      navigate(getFleetPath(FleetSubpath.MISSION_CONTROL))
                    }
                  >
                    <MissionControlIcon className="p-1 mapboxgl-ctrl-icon" />
                  </button>
                </div>
              </Tooltip>
            }
          />
        )}
        <div
          className={classes("flex-shrink-0 flex-grow flex flex-col min-h-96", {
            "overflow-y-auto":
              localView.viewMode === viewModeFromJSON(ViewMode.VIEW_MODE_CARDS),
          })}
        >
          {localView.viewMode === viewModeFromJSON(ViewMode.VIEW_MODE_CARDS) ? (
            <FleetRobotCards
              columns={columnIds}
              pinnedRobotIds={pinnedRobotIds}
              visibleRobots={results}
              isLoading={isLoading}
            />
          ) : (
            <FleetRobotTable
              columns={columnIds}
              isLoading={isLoading}
              name={localView.name}
              pinnedRobotIds={pinnedRobotIds}
              setSelectedView={setSelectedView}
              visibleRobots={results}
            />
          )}
        </div>
      </>
    );
  }
);
