import { AlarmResponse } from "protos/portal/alarms";
import {
  AlmanacConfig,
  DiscriminatorConfig,
  ModelinatorConfig,
} from "protos/almanac/almanac";
import { APIErrorResponse } from "stream-chat";
import { auth, CarbonUser } from "portal/utils/auth";
import {
  BaseQueryError,
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  TagDescription,
} from "@reduxjs/toolkit/query/react";
import {
  BlocksByDateByRobotResponse,
  BlocksResponse,
} from "protos/portal/spatial";
import { camelToSnake, snakeToCamel } from "portal/utils/strings";
import { ConfigNode } from "protos/config/api/config_service";
import { ConfigResponse } from "protos/portal/configs";
import { Crop, Image, Model, RobotCrop } from "protos/portal/veselka";
import { CustomerResponse } from "protos/portal/customers";
import {
  DailyMetricResponse,
  DailyMetricsByDateByRobotResponse,
} from "protos/portal/metrics";
import { DateTime } from "luxon";
import {
  entries,
  isEmpty,
  isObject,
  transformKeys,
  values,
} from "portal/utils/objects";
import { FieldDefinition } from "protos/portal/geo";
import { findWhere, sortBy, spliceIfExists } from "portal/utils/arrays";
import { FleetView, UserResponse } from "protos/portal/users";
import { getModelinatorId } from "portal/utils/almanac";
import {
  getNodeFromPath,
  getParentNodeFromPath,
  setValue,
  sortByName,
} from "portal/utils/configs";
import { GlobalAlarmlists, RobotAlarmlists } from "protos/portal/admin";
import { HardwareResponse } from "protos/portal/hardware";
import { HistoryResponse } from "protos/portal/health";
import { isUndefined } from "portal/utils/identity";
import { Message, MessagesResponse } from "protos/portal/messages";
import { Method, Pagination } from "portal/utils/api";
import { PortalJob } from "protos/portal/jobs";
import { ReportInstanceResponse, ReportResponse } from "protos/portal/reports";
import { RobotResponse, RobotSummaryResponse } from "protos/portal/robots";
import { robotSort } from "portal/utils/robots";
import { SerializedError } from "@reduxjs/toolkit";
import { TFunction } from "i18next";
import { toQuery } from "portal/utils/browser";
import { TVEProfile } from "protos/target_velocity_estimator/target_velocity_estimator";

enum Tag {
  ADMIN_ALARM = "Admin/Alarm",
  ADMIN_ALMANAC = "Admin/Almananc",
  ADMIN_TARGET_VELOCITY_ESTIMATOR = "Admin/TargetVelocityEstimator",
  ALARM = "Alarm",
  ALMANAC = "Almananc",
  CONFIG = "Config",
  CONFIG_TEMPLATE = "ConfigTemplate",
  CROP = "Crop",
  CUSTOMER = "Customer",
  DISCRIMINATOR = "Discriminator",
  FIELD_DEFINITION = "FieldDefinition",
  IMAGE = "Image",
  JOB_METRIC = "Job/Metric",
  MESSAGE = "Message",
  METRIC = "Metric",
  MODEL = "Model",
  MODELINDATOR = "Modelinator",
  REPORT = "Report",
  REPORT_INSTANCE = "ReportInstance",
  ROBOT = "Robot",
  ROBOT_CROP = "Robot/Crop",
  ROBOT_HARDWARE = "Robot/Hardware",
  ROBOT_JOB = "Robot/Job",
  SPATIAL = "SPATIAL",
  TARGET_VELOCITY_ESTIMATOR = "TargetVelocityEstimator",
  USER = "User",
  FLEET_VIEW = "FleetView",
}

export const isAbort = (error: unknown): boolean =>
  isObject(error) && "name" in error && error.name === "AbortError";

export const ignoreAborts = <T, P extends Promise<T>>(promise: P): P => {
  promise.catch((error) => {
    if (isAbort(error)) {
      // ignore aborts
    } else {
      throw error;
    }
  });

  return promise;
};

interface ErrorResponse {
  error?: string;
}

export const formatError = (
  t: TFunction,
  error: FetchBaseQueryError | SerializedError | string | undefined
): string | undefined => {
  if (isUndefined(error)) {
    return;
  }
  if (typeof error === "string") {
    return error;
  }
  if ("message" in error) {
    return error.message;
  }
  if ("data" in error && isObject(error.data) && "error" in error.data) {
    return error.data.error;
  }
  if ("error" in error) {
    return error.error;
  }
  if ("status" in error) {
    return String(error.status);
  }
  return t("components.ErrorBoundary.error");
};

export const isAPIErrorResponse = (error: unknown): error is APIErrorResponse =>
  isObject(error) && "StatusCode" in error;

export const handleError = <BaseQuery extends BaseQueryFn>(
  t: TFunction,
  isError: boolean,
  error:
    | FetchBaseQueryError
    | SerializedError
    | BaseQueryError<BaseQuery>
    | undefined,
  callback: (message?: string) => void
): void => {
  if (!isError) {
    return;
  }
  const fallback = t("components.ErrorBoundary.error");
  if (!error || typeof error !== "object" || !("data" in error)) {
    callback(fallback);
    return;
  }
  callback((error.data as ErrorResponse).error ?? fallback);
};

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (arguments_, api, extraOptions = {}) => {
  return await fetchBaseQuery({
    baseUrl: "/v1/",
    prepareHeaders: async (headers) => {
      const accessToken = await auth.getAccessTokenSilently();
      headers.set("Authorization", `Bearer ${accessToken}`);
      headers.set("X-AUTH0-audience", window._jsenv.REACT_APP_AUTH0_AUDIENCE);
      headers.set(
        "X-CARBON-Feature-Flags",
        JSON.stringify([
          "MetricValidCrops",
          "EncodingProtoJSON",
          "MetricOperatorEffectiveness",
        ])
      );

      const now = DateTime.local();
      if (now.zoneName) {
        headers.set("Timezone", now.zoneName);
      }
      return headers;
    },
    paramsSerializer: toQuery,
  })(arguments_, api, extraOptions);
};

export const portalApi = createApi({
  reducerPath: "portalApi",
  keepUnusedDataFor: 60 * 5, // 5 minutes
  baseQuery,
  tagTypes: values(Tag),
  endpoints: (builder) => ({
    // AWS
    getS3UploadUrl: builder.query<string, { filename: string }>({
      query: ({ filename }) => ({
        url: `aws/s3/upload`,
        params: { filename },
      }),
      transformResponse: ({ url }: { url: string }) => url,
    }),

    // ALARMS
    listAlarms: builder.query<AlarmResponse[], void>({
      query: () => ({
        url: `alarms`,
      }),
      transformResponse: (response: AlarmResponse[]) =>
        response.map((alarm) => AlarmResponse.fromJSON(alarm)),
      providesTags: (result) =>
        result
          ? result.map((alarm) => ({ type: Tag.ALARM, id: alarm.db?.id }))
          : [],
    }),

    // HARDWARE
    getHardware: builder.query<HardwareResponse, void>({
      query: () => `hardware`,
      transformResponse: (response: HardwareResponse) =>
        HardwareResponse.fromJSON(response),
      providesTags: (result) => {
        const tags: TagDescription<any>[] = [];
        const robots = new Map<number, boolean>();
        if (result?.lasers) {
          for (const laser of result.lasers) {
            robots.set(laser.robotId, true);
          }
        }
        for (const robotId of robots.keys()) {
          tags.push({
            type: Tag.ROBOT_HARDWARE,
            id: robotId,
          });
        }
        return tags;
      },
    }),

    // CONFIGS
    getConfig: builder.query<
      ConfigResponse,
      { serial: string; fetchFirst?: boolean; noCache?: boolean }
    >({
      keepUnusedDataFor: 60 * 1, // 1 minute
      query: ({ serial, ...options }) => {
        if (options.noCache) {
          portalApi.util.invalidateTags([{ type: Tag.CONFIG, id: serial }]);
        }
        return { url: `configs/${serial}`, params: options };
      },
      transformResponse: (response: ConfigResponse) => {
        const { config, template, ...result } =
          ConfigResponse.fromJSON(response);
        return {
          ...result,
          config: config ? sortByName(config) : undefined,
          template: template ? sortByName(template) : undefined,
        };
      },
      providesTags: (result, error, { serial }) => [
        { type: Tag.CONFIG, id: serial },
      ],
    }),
    setConfigValue: builder.mutation<
      undefined,
      { serial: string; path: string; value: any }
    >({
      query: ({ serial, path, value }) => ({
        url: `configs/${serial}`,
        method: Method.POST,
        body: { path, value },
      }),
      onQueryStarted: async (
        { serial, path, value },
        { dispatch, queryFulfilled }
      ) => {
        await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData("getConfig", { serial }, (data) => {
            const node = getNodeFromPath(data.config, path);
            if (!node) {
              dispatch(
                portalApi.util.invalidateTags([
                  { type: Tag.CONFIG, id: serial },
                ])
              );
              return data;
            }
            setValue(node, value);
            return data;
          })
        );
      },
    }),
    deleteConfigPath: builder.mutation<
      undefined,
      { serial: string; path: string }
    >({
      query: ({ serial, path }) => ({
        url: `configs/${serial}/${path}`,
        method: Method.DELETE,
      }),
      onQueryStarted: async (
        { serial, path },
        { dispatch, queryFulfilled }
      ) => {
        await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData("getConfig", { serial }, (data) => {
            const node = getParentNodeFromPath(data.config, path);
            const keyName = path.split("/").pop();
            if (!node?.children) {
              dispatch(
                portalApi.util.invalidateTags([
                  { type: Tag.CONFIG, id: serial },
                ])
              );
              return data;
            }
            for (let index = node.children.length - 1; index > 0; index--) {
              const child = node.children[index];
              if (child?.name === keyName) {
                node.children.splice(index, 1);
              }
            }
            return data;
          })
        );
      },
    }),
    getConfigTemplate: builder.query<ConfigNode, { serial: string }>({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: ({ serial }) => ({ url: `configs/templates/${serial}` }),
      transformResponse: (response: ConfigNode) => {
        const result = ConfigNode.fromJSON(response);
        return sortByName(result);
      },
      providesTags: (result, error, { serial }) => [
        { type: Tag.CONFIG_TEMPLATE, id: serial },
      ],
    }),
    setConfigTemplateValue: builder.mutation<
      undefined,
      { serial: string; path: string; value: any }
    >({
      query: ({ serial, path, value }) => ({
        url: `configs/templates/${serial}`,
        method: Method.POST,
        body: { path, value, timestamp: Date.now() },
      }),
      onQueryStarted: async (
        { serial, path, value },
        { dispatch, queryFulfilled }
      ) => {
        await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "getConfigTemplate",
            { serial },
            (template) => {
              const node = getNodeFromPath(template, path);
              if (!node) {
                dispatch(
                  portalApi.util.invalidateTags([
                    { type: Tag.CONFIG_TEMPLATE, id: serial },
                  ])
                );
                return template;
              }
              setValue(node, value);
              return template;
            }
          )
        );
      },
    }),
    deleteConfigTemplatePath: builder.mutation<
      undefined,
      { serial: string; path: string }
    >({
      query: ({ serial, path }) => ({
        url: `configs/templates/${serial}`,
        method: Method.DELETE,
        body: {
          path,
          timestamp: Date.now(),
        },
      }),
      onQueryStarted: async (
        { serial, path },
        { dispatch, queryFulfilled }
      ) => {
        await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "getConfigTemplate",
            { serial },
            (template) => {
              const node = getParentNodeFromPath(template, path);
              const keyName = path.split("/").pop();
              if (!node?.children) {
                dispatch(
                  portalApi.util.invalidateTags([
                    { type: Tag.CONFIG_TEMPLATE, id: serial },
                  ])
                );
                return template;
              }
              for (let index = node.children.length - 1; index > 0; index--) {
                const child = node.children[index];
                if (child?.name === keyName) {
                  node.children.splice(index, 1);
                }
              }
              return template;
            }
          )
        );
      },
    }),

    // CUSTOMERS
    listCustomers: builder.query<CustomerResponse[], void>({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: () => "customers",
      transformResponse: (response: CustomerResponse[]) =>
        sortBy(response, "name").map((customer) =>
          CustomerResponse.fromJSON(customer)
        ),
      providesTags: (customers) => [
        Tag.CUSTOMER,
        ...(customers?.map((customer) => ({
          type: Tag.CUSTOMER,
          id: customer.db?.id,
        })) ?? []),
      ],
    }),
    getCustomer: builder.query<
      CustomerResponse | undefined,
      { customerId: number }
    >({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: () => "customers",
      transformResponse: (
        response: CustomerResponse[] = [],
        _,
        { customerId }
      ) => {
        const customer = response.find(
          (customer) => customer.db?.id === customerId
        );
        return customer ? CustomerResponse.fromJSON(customer) : undefined;
      },
      onQueryStarted: async ({ customerId }, { dispatch, queryFulfilled }) => {
        const { data: customer } = await queryFulfilled;
        if (!customer) {
          return;
        }
        dispatch(
          portalApi.util.updateQueryData(
            "listCustomers",
            undefined,
            (customers) => {
              const index = customers.findIndex(
                (customer) => customer.db?.id === customerId
              );
              customers[index] = customer;
              return customers;
            }
          )
        );
      },
      providesTags: (customer) => [
        { type: Tag.CUSTOMER, id: customer?.db?.id },
      ],
    }),
    createCustomer: builder.mutation<
      CustomerResponse,
      Omit<CustomerResponse, "db" | "featureFlags">
    >({
      query: (customer) => ({
        url: "customers",
        method: Method.POST,
        body: CustomerResponse.toJSON(CustomerResponse.fromPartial(customer)),
      }),
      transformResponse: (customer) => CustomerResponse.fromJSON(customer),
      onQueryStarted: async (customer, { dispatch, queryFulfilled }) => {
        const { data: newCustomer } = await queryFulfilled;
        if (!newCustomer.db?.id) {
          return;
        }
        dispatch(
          portalApi.util.updateQueryData(
            "listCustomers",
            undefined,
            (customers) => {
              customers.push(newCustomer);
              return customers;
            }
          )
        );
        dispatch(
          portalApi.util.updateQueryData(
            "getCustomer",
            { customerId: newCustomer.db.id },
            () => newCustomer
          )
        );
      },
    }),
    updateCustomer: builder.mutation<
      CustomerResponse,
      {
        customerId: number;
        customer: Omit<CustomerResponse, "db" | "featureFlags">;
      }
    >({
      query: ({ customerId, customer }) => ({
        url: `customers/${customerId}`,
        method: Method.POST,
        body: CustomerResponse.toJSON(CustomerResponse.fromPartial(customer)),
      }),
      transformResponse: (customer) => CustomerResponse.fromJSON(customer),
      onQueryStarted: async ({ customerId }, { dispatch, queryFulfilled }) => {
        const { data: updatedCustomer } = await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "listCustomers",
            undefined,
            (customers) => {
              const index = customers.findIndex(
                (customer) => customer.db?.id === customerId
              );
              customers[index] = updatedCustomer;
              return customers;
            }
          )
        );
        dispatch(
          portalApi.util.updateQueryData(
            "getCustomer",
            { customerId },
            () => updatedCustomer
          )
        );
      },
    }),

    // MESSAGES
    listMessages: builder.query<
      Message[],
      { serial: string; pagination?: Pagination }
    >({
      keepUnusedDataFor: 60 * 1, // 1 minute
      query: ({ serial, pagination }) => ({
        url: `messages/robot/${serial}`,
        params: pagination,
      }),
      transformResponse: (response: MessagesResponse) => {
        const { messages } = MessagesResponse.fromJSON(response);
        return sortBy(messages, (message) => message.db?.createdAt ?? 0);
      },
      providesTags: (messages) => [
        Tag.MESSAGE,
        ...(messages?.map((message) => ({
          type: Tag.MESSAGE,
          id: message.db?.id,
        })) ?? []),
      ],
    }),
    sendMessage: builder.mutation<Message, { serial: string; message: string }>(
      {
        query: ({ serial, message }) => ({
          url: `messages/robot/${serial}`,
          method: Method.POST,
          body: { message },
        }),
        transformResponse: (response: Message) => Message.fromJSON(response),
        invalidatesTags: (message) => [
          Tag.MESSAGE,
          { type: Tag.MESSAGE, id: message?.db?.id },
        ],
      }
    ),
    // REPORTS
    listReports: builder.query<ReportResponse[], void>({
      query: () => "reports",
      transformResponse: (response: ReportResponse[]) =>
        response.map((report) => ReportResponse.fromJSON(report)),
      providesTags: (reports) => [
        Tag.REPORT,
        ...(reports?.map((report) => ({
          type: Tag.REPORT,
          id: report.slug,
        })) ?? []),
      ],
    }),
    getReport: builder.query<
      ReportResponse,
      { reportSlug: string; instance?: string }
    >({
      query: ({ reportSlug, instance }) => ({
        url: `reports/${reportSlug}`,
        params: { instance },
      }),
      transformResponse: (response: ReportResponse) =>
        ReportResponse.fromJSON(response),
      providesTags: (report) => [{ type: Tag.REPORT, id: report?.slug }],
      onQueryStarted: async ({ reportSlug }, { dispatch, queryFulfilled }) => {
        const { data: report } = await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "listReports",
            undefined,
            (reports) => {
              const index = reports.findIndex(
                (report) => report.slug === reportSlug
              );
              reports[index] = report;
              return reports;
            }
          )
        );
      },
    }),
    createReport: builder.mutation<ReportResponse, ReportResponse>({
      query: (report) => ({
        url: "reports/",
        method: Method.POST,
        body: ReportResponse.toJSON(ReportResponse.fromPartial(report)),
      }),
      transformResponse: (response: ReportResponse) =>
        ReportResponse.fromJSON(response),
      onQueryStarted: async (report, { dispatch, queryFulfilled }) => {
        const { data: newReport } = await queryFulfilled;
        if (!newReport.db?.id) {
          return;
        }
        dispatch(
          portalApi.util.updateQueryData(
            "listReports",
            undefined,
            (reports) => {
              reports.push(newReport);
              return reports;
            }
          )
        );
        dispatch(
          portalApi.util.updateQueryData(
            "getReport",
            { reportSlug: newReport.slug },
            () => newReport
          )
        );
      },
    }),
    updateReport: builder.mutation<ReportResponse, ReportResponse>({
      query: (report) => ({
        url: `reports/${report.slug}`,
        method: Method.POST,
        body: ReportResponse.toJSON(ReportResponse.fromPartial(report)),
      }),
      transformResponse: (response: ReportResponse) =>
        ReportResponse.fromJSON(response),
      onQueryStarted: async (report, { dispatch, queryFulfilled }) => {
        const { data: updatedReport } = await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "listReports",
            undefined,
            (reports) => {
              const index = reports.findIndex(
                (report) => report.slug === updatedReport.slug
              );
              reports[index] = updatedReport;
              return reports;
            }
          )
        );
        dispatch(
          portalApi.util.updateQueryData(
            "getReport",
            { reportSlug: updatedReport.slug },
            () => updatedReport
          )
        );
      },
    }),
    deleteReport: builder.mutation<void, { slug: string }>({
      query: ({ slug }) => ({
        url: `reports/${slug}`,
        method: Method.DELETE,
      }),
      onQueryStarted: async ({ slug }, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "listReports",
            undefined,
            (reports) => {
              spliceIfExists(reports, (report) => report.slug === slug, 1);
              return reports;
            }
          )
        );
        dispatch(
          portalApi.util.invalidateTags([{ type: Tag.REPORT, id: slug }])
        );
      },
    }),
    listReportInstances: builder.query<
      ReportInstanceResponse[],
      { slug: string }
    >({
      query: ({ slug }) => `reports/${slug}/runs`,
      transformResponse: (response: ReportInstanceResponse[]) =>
        response.map((instance) => ReportInstanceResponse.fromJSON(instance)),
      providesTags: (instances) => [
        Tag.REPORT_INSTANCE,
        ...(instances?.map((instance) => ({
          type: Tag.REPORT_INSTANCE,
          id: instance.slug,
        })) ?? []),
      ],
    }),
    getReportInstance: builder.query<
      ReportInstanceResponse,
      { reportSlug: string; instanceSlug: string }
    >({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: ({ reportSlug, instanceSlug }) =>
        `reports/${reportSlug}/runs/${instanceSlug}`,
      transformResponse: (response: ReportInstanceResponse) =>
        ReportInstanceResponse.fromJSON(response),
      providesTags: (instance) => [
        { type: Tag.REPORT_INSTANCE, id: instance?.slug },
      ],
      onQueryStarted: async (
        { reportSlug, instanceSlug },
        { dispatch, queryFulfilled }
      ) => {
        const { data: instance } = await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "listReportInstances",
            { slug: reportSlug },
            (instances) => {
              const index = instances.findIndex(
                (instance) => instance.slug === instanceSlug
              );
              instances[index] = instance;
              return instances;
            }
          )
        );
      },
    }),
    createReportInstance: builder.mutation<
      ReportInstanceResponse,
      { reportSlug: string; instance: ReportInstanceResponse }
    >({
      query: ({ reportSlug, instance }) => ({
        url: `reports/${reportSlug}/runs`,
        method: Method.POST,
        body: ReportInstanceResponse.toJSON(
          ReportInstanceResponse.fromPartial(instance)
        ),
      }),
      transformResponse: (response: ReportInstanceResponse) =>
        ReportInstanceResponse.fromJSON(response),
      onQueryStarted: async ({ reportSlug }, { dispatch, queryFulfilled }) => {
        const { data: newInstance } = await queryFulfilled;
        if (!newInstance.db?.id) {
          return;
        }
        dispatch(
          portalApi.util.updateQueryData(
            "listReportInstances",
            { slug: reportSlug },
            (instances) => {
              instances.push(newInstance);
              return instances;
            }
          )
        );
        dispatch(
          portalApi.util.updateQueryData(
            "getReportInstance",
            { reportSlug, instanceSlug: newInstance.slug },
            () => newInstance
          )
        );
      },
    }),
    updateReportInstance: builder.mutation<
      ReportInstanceResponse,
      { reportSlug: string; instance: ReportInstanceResponse }
    >({
      query: ({ reportSlug, instance }) => ({
        url: `reports/${reportSlug}/runs/${instance.slug}`,
        method: Method.POST,
        body: ReportInstanceResponse.toJSON(
          ReportInstanceResponse.fromPartial(instance)
        ),
      }),
      transformResponse: (response: ReportInstanceResponse) =>
        ReportInstanceResponse.fromJSON(response),
      onQueryStarted: async ({ reportSlug }, { dispatch, queryFulfilled }) => {
        const { data: updatedInstance } = await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "listReportInstances",
            { slug: reportSlug },
            (instances) => {
              const index = instances.findIndex(
                (instance) => instance.slug === updatedInstance.slug
              );
              instances[index] = updatedInstance;
              return instances;
            }
          )
        );
        dispatch(
          portalApi.util.updateQueryData(
            "getReportInstance",
            { reportSlug, instanceSlug: updatedInstance.slug },
            () => updatedInstance
          )
        );
      },
    }),
    deleteReportInstance: builder.mutation<
      void,
      { reportSlug: string; instanceSlug: string }
    >({
      query: ({ reportSlug, instanceSlug }) => ({
        url: `reports/${reportSlug}/runs/${instanceSlug}`,
        method: Method.DELETE,
      }),
      onQueryStarted: async (
        { reportSlug, instanceSlug },
        { dispatch, queryFulfilled }
      ) => {
        await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "listReportInstances",
            { slug: reportSlug },
            (instances) => {
              spliceIfExists(
                instances,
                (instance) => instance.slug === instanceSlug,
                1
              );
              return instances;
            }
          )
        );
        dispatch(
          portalApi.util.invalidateTags([
            { type: Tag.REPORT_INSTANCE, id: instanceSlug },
          ])
        );
      },
    }),
    getDateMetrics: builder.query<
      DailyMetricsByDateByRobotResponse,
      { serials: string[]; dates: string[]; instance?: string }
    >({
      query: (parameters) => ({
        url: "metrics/certified",
        params: parameters,
      }),
      transformResponse: (response: DailyMetricsByDateByRobotResponse) =>
        DailyMetricsByDateByRobotResponse.fromJSON(response),
      providesTags: (response) => {
        const tags: TagDescription<Tag>[] = [];
        for (const [serial, metricsByDate] of entries(
          response?.metrics ?? {}
        )) {
          for (const [date] of entries(metricsByDate.metrics)) {
            tags.push({ type: Tag.METRIC, id: `${serial}-${date}` });
          }
        }
        return tags;
      },
    }),
    getSpatial: builder.query<
      BlocksByDateByRobotResponse,
      { serials: string[]; dates: string[] }
    >({
      query: (parameters) => ({
        url: "metrics/spatial",
        params: parameters,
      }),
      transformResponse: (response: BlocksByDateByRobotResponse) =>
        BlocksByDateByRobotResponse.fromJSON(response),
      providesTags: (response) => {
        const tags: TagDescription<Tag>[] = [];
        for (const [serial, blocksByDate] of entries(response?.blocks ?? {})) {
          for (const [date] of entries(blocksByDate.blocks)) {
            tags.push({ type: Tag.SPATIAL, id: `${serial}-${date}` });
          }
        }
        return tags;
      },
    }),

    // ROBOTS
    listRobots: builder.query<
      RobotSummaryResponse[],
      {
        instance?: string;
        latestMetrics?: boolean;
      }
    >({
      keepUnusedDataFor: 60 * 1, // 1 minute
      query: ({ instance, latestMetrics = true }) => ({
        url: `robots`,
        params: {
          showOffline: true,
          showInternal: true,
          instance,
          latestMetrics,
        },
      }),
      transformResponse: (response: RobotSummaryResponse[] = []) => {
        response.sort(robotSort);
        for (const summary of response) {
          if (summary.config) {
            summary.config = sortByName(ConfigNode.fromJSON(summary.config));
          }
        }
        return response.map((summary) =>
          RobotSummaryResponse.fromJSON(summary)
        );
      },
      providesTags: (summaries) => [
        Tag.ROBOT,
        ...(summaries?.map((summary) => ({
          type: Tag.ROBOT,
          id: summary.robot?.db?.id,
        })) ?? []),
      ],
    }),
    getRobot: builder.query<RobotSummaryResponse, { serial: string }>({
      keepUnusedDataFor: 60 * 1, // 1 minute
      query: ({ serial }) => `robots/${serial}`,
      providesTags: (summary) => [
        { type: Tag.ROBOT, id: summary?.robot?.db?.id },
        ...(summary?.alarmList
          ? summary.alarmList.map((alarm) => ({
              type: Tag.ALARM,
              id: alarm.db?.id,
            }))
          : []),
      ],
      transformResponse: (summary: RobotSummaryResponse) =>
        RobotSummaryResponse.fromJSON(summary),
      onQueryStarted: async ({ serial }, { dispatch, queryFulfilled }) => {
        const { data: summary } = await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData("listRobots", {}, (robots) => {
            const index = robots.findIndex(
              (robot) => robot.robot?.serial === serial
            );
            robots[index] = summary;
            return robots;
          })
        );
      },
    }),
    getRobotHistory: builder.query<
      HistoryResponse,
      { serial: string; date: string }
    >({
      query: ({ serial, date }) => `robots/${serial}/history/${date}`,
      transformResponse: (response: HistoryResponse) =>
        HistoryResponse.fromJSON(response),
    }),
    getRobotBlocks: builder.query<
      BlocksResponse,
      { serial: string; date: string }
    >({
      query: ({ serial, date }) => `robots/${serial}/blocks/${date}`,
      transformResponse: (response: BlocksResponse) =>
        BlocksResponse.fromJSON(response),
    }),
    getRobotMetrics: builder.query<
      DailyMetricResponse,
      { serial: string; date: string }
    >({
      query: ({ serial, date }) => `robots/${serial}/metrics/${date}`,
      transformResponse: (response: DailyMetricResponse) =>
        DailyMetricResponse.fromJSON(response),
      providesTags: (result, error, { serial, date }) => [
        { type: Tag.METRIC, id: `${serial}-${date}` },
      ],
    }),
    createRobot: builder.mutation<
      RobotResponse,
      { serial: string; copyFrom: string }
    >({
      query: (parameters) => ({
        url: "robots",
        method: Method.POST,
        body: parameters,
      }),
      transformResponse: (summary: RobotResponse) =>
        RobotResponse.fromJSON(summary),
      // can't splice this into the cache because it's not a RobotSummaryResponse
      invalidatesTags: () => [Tag.ROBOT],
    }),
    updateRobot: builder.mutation<
      RobotResponse,
      {
        serial: string;
        robot: Partial<
          Pick<RobotResponse, "implementationStatus" | "supportSlack">
        >;
      }
    >({
      query: ({ serial, robot }) => ({
        url: `robots/${serial}`,
        method: Method.POST,
        body: RobotResponse.toJSON(RobotResponse.fromPartial(robot)),
      }),
      transformResponse: (summary: RobotResponse) =>
        RobotResponse.fromJSON(summary),
      onQueryStarted: async ({ serial }, { dispatch, queryFulfilled }) => {
        const { data: robot } = await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData("listRobots", {}, (summaries) => {
            const index = summaries.findIndex(
              (instance) => instance.robot?.serial === serial
            );
            if (summaries[index]) {
              summaries[index].robot = robot;
            }
            return summaries;
          })
        );
        dispatch(
          portalApi.util.updateQueryData("getRobot", { serial }, (summary) => {
            summary.robot = robot;
            return summary;
          })
        );
      },
    }),
    assignRobot: builder.mutation<
      RobotResponse,
      { serial: string; customerId: number }
    >({
      query: ({ serial, customerId }) => ({
        url: `robots/${serial}/assign`,
        method: Method.POST,
        body: { customerId },
      }),
      transformResponse: (summary: RobotResponse) =>
        RobotResponse.fromJSON(summary),
      // can't splice this into the cache because it's not a RobotSummaryResponse
      invalidatesTags: (robot) => [
        Tag.ROBOT,
        { type: Tag.ROBOT, id: robot?.db?.id },
      ],
    }),
    getRobotProxy: builder.mutation<RobotResponse, { serial: string }>({
      query: ({ serial }) => `robots/${serial}/remote`,
      transformResponse: (summary: RobotResponse) =>
        RobotResponse.fromJSON(summary),
    }),
    listRobotCrops: builder.query<RobotCrop[], { serial: string }>({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: ({ serial }) => `robots/${serial}/crops`,
      transformResponse: (response: RobotCrop[]) =>
        response.map((crop) => RobotCrop.fromJSON(crop)),
      providesTags: (robotCrops) => [
        Tag.ROBOT_CROP,
        ...(robotCrops?.map(({ crop }) => ({
          type: Tag.ROBOT_CROP,
          id: crop?.id,
        })) ?? []),
      ],
    }),
    listRobotJobs: builder.query<
      PortalJob[],
      { serial: string; startDate: string; endDate: string }
    >({
      query: ({ serial, startDate, endDate }) =>
        `robots/${serial}/jobs/${startDate}/${endDate}`,
      transformResponse: (response: PortalJob[]) =>
        response.map((job) => PortalJob.fromJSON(job)),
      providesTags: (jobs) => [
        Tag.ROBOT_JOB,
        ...(jobs?.map((job) => ({
          type: Tag.ROBOT_JOB,
          id: job.jobId,
        })) ?? []),
      ],
    }),
    getJob: builder.query<PortalJob, { jobId: string }>({
      query: ({ jobId }) => `jobs/${jobId}`,
      transformResponse: (response: PortalJob) => PortalJob.fromJSON(response),
      providesTags: (job) => [
        Tag.ROBOT_JOB,
        { type: Tag.ROBOT_JOB, id: job?.jobId },
        { type: Tag.JOB_METRIC, id: job?.jobId },
      ],
    }),
    getJobHistory: builder.query<HistoryResponse, { jobId: string }>({
      query: ({ jobId }) => `jobs/${jobId}/history`,
      transformResponse: (response: HistoryResponse) =>
        HistoryResponse.fromJSON(response),
    }),
    getJobBlocks: builder.query<BlocksResponse, { jobId: string }>({
      query: ({ jobId }) => `jobs/${jobId}/blocks`,
      transformResponse: (response: BlocksResponse) =>
        BlocksResponse.fromJSON(response),
    }),
    getRobotHardware: builder.query<HardwareResponse, { serial: string }>({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: ({ serial }) => `robots/${serial}/hardware`,
      transformResponse: (response: HardwareResponse) =>
        HardwareResponse.fromJSON(response),
      providesTags: (robotCrops, error, { serial }) => [
        Tag.ROBOT_HARDWARE,
        {
          type: Tag.ROBOT_HARDWARE,
          id: serial,
        },
      ],
    }),

    // FIELD DEFINITIONS
    createFieldDefinition: builder.mutation<
      FieldDefinition,
      {
        serial: string;
        fieldDefinition: Required<
          Omit<FieldDefinition, "db" | "fieldId" | "robotId">
        >;
      }
    >({
      query: ({ serial, fieldDefinition }) => ({
        url: `fields/robots/${serial}/definitions`,
        method: "POST",
        body: FieldDefinition.toJSON(
          FieldDefinition.fromPartial(fieldDefinition)
        ),
      }),
      transformResponse: (response: FieldDefinition) =>
        FieldDefinition.fromJSON(response),
    }),

    // USERS
    listUsers: builder.query<CarbonUser[], void>({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: () => "users",
      transformResponse: (response: CarbonUser[]) =>
        transformKeys(response, snakeToCamel),
      providesTags: (users) => [
        Tag.USER,
        ...(users?.map((user) => ({
          type: Tag.USER,
          id: user.email,
        })) ?? []),
      ],
    }),
    getUser: builder.query<UserResponse, { userId: string }>({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: ({ userId }) => {
        return `users/${userId}`;
      },
      transformResponse: (response: UserResponse) =>
        UserResponse.fromJSON(response),
      providesTags: (response) => {
        const tags = [{ type: Tag.USER, id: response?.user?.userId }];
        if (!isEmpty(response?.customer)) {
          tags.push({
            type: Tag.CUSTOMER,
            id: String(response?.customer?.db?.id),
          });
        }
        for (const fleetView of response?.fleetViews ?? []) {
          if (fleetView.db) {
            tags.push({
              type: Tag.FLEET_VIEW,
              id: String(fleetView.db.id),
            });
          }
        }
        return tags;
      },
    }),
    updateUser: builder.mutation<
      undefined,
      { userId: string; user: Partial<CarbonUser> }
    >({
      query: ({ userId, user }) => {
        const { appMetadata, userMetadata } = user;
        return {
          url: `users/${userId}`,
          method: Method.POST,
          body: {
            ...transformKeys(user, camelToSnake),
            app_metadata: appMetadata,
            user_metadata: userMetadata,
          },
        };
      },
      onQueryStarted: async (
        { userId, user },
        { dispatch, queryFulfilled }
      ) => {
        await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData("listUsers", undefined, (users) => {
            const index = users.findIndex((user) => user.userId === userId);
            users[index] = user;
            return users;
          })
        );
        dispatch(
          portalApi.util.invalidateTags([{ type: Tag.USER, id: userId }])
        );
      },
    }),
    deleteUser: builder.mutation<undefined, { userId: string }>({
      query: ({ userId }) => ({
        url: `users/${userId}`,
        method: Method.DELETE,
      }),
      onQueryStarted: async ({ userId }, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData("listUsers", undefined, (users) => {
            spliceIfExists(users, (user) => user.userId === userId, 1);
            return users;
          })
        );
        dispatch(
          portalApi.util.invalidateTags([{ type: Tag.USER, id: userId }])
        );
      },
    }),
    inviteUser: builder.mutation<
      undefined,
      { email: string; customerId?: number }
    >({
      query: ({ email, customerId }) => ({
        url: "users/invite",
        method: Method.POST,
        body: { email, customerId },
      }),
      invalidatesTags: (result, error, { email }) => [
        Tag.USER,
        { type: Tag.USER, id: email },
      ],
    }),
    createFleetView: builder.mutation<
      FleetView,
      { userId: string; fleetView: FleetView }
    >({
      query: ({ userId, fleetView }) => {
        return {
          url: `users/${userId}/fleetviews`,
          method: Method.POST,
          body: FleetView.toJSON(FleetView.fromPartial(fleetView)),
        };
      },
      transformResponse: (fleetView: FleetView) =>
        FleetView.fromJSON(fleetView),
      onQueryStarted: async ({ userId }, { dispatch, queryFulfilled }) => {
        const { data: newFleetView } = await queryFulfilled;
        if (!newFleetView.db?.id) {
          return;
        }
        dispatch(
          portalApi.util.updateQueryData("getUser", { userId }, (user) => {
            user.fleetViews.push(newFleetView);
            return user;
          })
        );
      },
    }),
    updateFleetView: builder.mutation<
      undefined,
      { userId: string; fleetView: FleetView }
    >({
      query: ({ userId, fleetView }) => {
        return {
          url: `users/${userId}/fleetviews/${fleetView.db?.id}`,
          method: Method.POST,
          body: FleetView.toJSON(FleetView.fromPartial(fleetView)),
        };
      },
      onQueryStarted: async (
        { userId, fleetView },
        { dispatch, queryFulfilled }
      ) => {
        await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData("getUser", { userId }, (user) => {
            const index = user.fleetViews.findIndex(
              (current) => current.db?.id === fleetView.db?.id
            );
            user.fleetViews[index] = fleetView;
            return user;
          })
        );
      },
    }),
    deleteFleetView: builder.mutation<
      undefined,
      { userId: string; fleetViewId: number }
    >({
      query: ({ userId, fleetViewId }) => ({
        url: `users/${userId}/fleetviews/${fleetViewId}`,
        method: Method.DELETE,
      }),
      onQueryStarted: async (
        { userId, fleetViewId },
        { dispatch, queryFulfilled }
      ) => {
        await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData("getUser", { userId }, (user) => {
            spliceIfExists(
              user.fleetViews,
              (fleetView) => fleetView.db?.id === fleetViewId,
              1
            );
            return user;
          })
        );
      },
    }),

    // VESELKA
    listUploads: builder.query<
      Image[],
      { serial: string; pagination?: Pagination }
    >({
      query: ({ serial, pagination }) => ({
        url: `veselka/uploads/${serial}`,
        params: pagination,
      }),
      transformResponse: (images: Image[]) =>
        images.map((image) => Image.fromJSON(image)),
      providesTags: (images) => [
        Tag.IMAGE,
        ...(images?.map((image) => ({
          type: Tag.IMAGE,
          id: image.id,
        })) ?? []),
      ],
    }),
    listCrops: builder.query<Crop[], void>({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: () => "veselka/models/crops",
      transformResponse: (crops: Crop[]) =>
        crops.map((crop) => Crop.fromJSON(crop)),
      providesTags: (crops) => [
        Tag.CROP,
        ...(crops?.map((crop) => ({
          type: Tag.CROP,
          id: crop.id,
        })) ?? []),
      ],
    }),
    getCrop: builder.query<Crop | undefined, { cropId: string }>({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: () => "veselka/models/crops",
      providesTags: (crop) =>
        crop
          ? [
              {
                type: Tag.CROP,
                id: crop.id,
              },
            ]
          : [],
      transformResponse: (crops: Crop[], error, { cropId }) => {
        const crop = findWhere(crops, { id: cropId });
        if (!crop) {
          return;
        }
        return Crop.fromJSON(crop);
      },
      onQueryStarted: async ({ cropId }, { dispatch, queryFulfilled }) => {
        const { data: crop } = await queryFulfilled;
        if (!crop) {
          return;
        }
        dispatch(
          portalApi.util.updateQueryData("listCrops", undefined, (crops) => {
            const index = crops.findIndex((crop) => crop.id === cropId);
            crops[index] = crop;
            return crops;
          })
        );
      },
    }),
    getModel: builder.query<Model, { modelId: string }>({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: ({ modelId }) => `veselka/models/${modelId}`,
      transformResponse: (model: Model) => Model.fromJSON(model),
      providesTags: (result, error, { modelId }) => [
        { type: Tag.MODEL, id: modelId },
      ],
    }),

    // ADMIN
    deleteCaches: builder.mutation<void, void>({
      query: () => ({
        url: `/admin/caches`,
        method: Method.DELETE,
      }),
    }),
    getGlobalAlarmLists: builder.query<GlobalAlarmlists, void>({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: () => "/admin/alarms/lists",
      transformResponse: (response: GlobalAlarmlists) =>
        GlobalAlarmlists.fromJSON(response),
      providesTags: () => [
        {
          type: Tag.ADMIN_ALARM,
          id: -1,
        },
      ],
    }),
    getRobotAlarmLists: builder.query<RobotAlarmlists, { serial: string }>({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: ({ serial }) => `/admin/alarms/lists/${serial}`,
      transformResponse: (response: RobotAlarmlists) =>
        RobotAlarmlists.fromJSON(response),
      providesTags: (result, error, { serial }) => [
        {
          type: Tag.ADMIN_ALARM,
          id: serial,
        },
      ],
    }),
    setGlobalAlarmLists: builder.mutation<
      GlobalAlarmlists,
      Omit<GlobalAlarmlists, "db">
    >({
      query: (lists) => ({
        url: "admin/alarms/lists",
        method: Method.POST,
        body: GlobalAlarmlists.toJSON(lists),
      }),
      transformResponse: (response: GlobalAlarmlists) =>
        GlobalAlarmlists.fromJSON(response),
      onQueryStarted: async (lists, { dispatch, queryFulfilled }) => {
        const { data: newLists } = await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "getGlobalAlarmLists",
            undefined,
            () => newLists
          )
        );
      },
    }),
    setRobotAlarmLists: builder.mutation<
      RobotAlarmlists,
      { serial: string; lists: Omit<RobotAlarmlists, "db"> }
    >({
      query: ({ serial, lists }) => ({
        url: `admin/alarms/lists/${serial}`,
        method: Method.POST,
        body: RobotAlarmlists.toJSON(lists),
      }),
      transformResponse: (response: GlobalAlarmlists) =>
        RobotAlarmlists.fromJSON(response),
      onQueryStarted: async ({ serial }, { dispatch, queryFulfilled }) => {
        const { data: newLists } = await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "getRobotAlarmLists",
            { serial },
            () => newLists
          )
        );
      },
    }),

    // ALMANANC
    listAlmanacsForRobot: builder.query<AlmanacConfig[], { serial?: string }>({
      query: ({ serial }) => `/profiles/almanacs/robots/${serial}`,
      transformResponse: (response: AlmanacConfig[]) =>
        response.map((almanac) => AlmanacConfig.fromJSON(almanac)),
      providesTags: (almanacs) =>
        almanacs?.map((almanac) => ({
          type: Tag.ALMANAC,
          id: almanac.id,
        })) ?? [],
    }),
    getAlmanac: builder.query<
      AlmanacConfig,
      {
        uuid: string;
      }
    >({
      query: ({ uuid }) => `/profiles/almanacs/profiles/${uuid}`,
      transformResponse: (response: AlmanacConfig) =>
        AlmanacConfig.fromJSON(response),
      providesTags: (result, error, { uuid }) => [
        {
          type: Tag.ALMANAC,
          id: uuid,
        },
      ],
    }),
    deleteAlmanac: builder.mutation<void, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/profiles/almanacs/profiles/${uuid}`,
        method: Method.DELETE,
      }),
      invalidatesTags: (result, error, { uuid }) => [
        { type: Tag.ALMANAC, id: uuid },
      ],
    }),
    setAlmanac: builder.mutation<
      AlmanacConfig,
      { serial?: string; almanac: AlmanacConfig }
    >({
      query: ({ serial, almanac }) => ({
        url: `/profiles/almanacs/robots/${serial}`,
        method: Method.POST,
        body: AlmanacConfig.toJSON(almanac),
      }),
      transformResponse: (response: AlmanacConfig) =>
        AlmanacConfig.fromJSON(response),
      onQueryStarted: async ({ serial }, { dispatch, queryFulfilled }) => {
        const { data: newAlmanac } = await queryFulfilled;
        if (!serial || !newAlmanac.id) {
          return;
        }
        dispatch(
          portalApi.util.updateQueryData(
            "listAlmanacsForRobot",
            { serial },
            (almanacs) => {
              almanacs.push(newAlmanac);
              return almanacs;
            }
          )
        );
        dispatch(
          portalApi.util.updateQueryData(
            "getAlmanac",
            { uuid: newAlmanac.id },
            () => newAlmanac
          )
        );
      },
    }),
    // any string is valid for the input. Just matching the signature of
    // listAlmanacsForRobot so they can be used interchangeably
    listGlobalAlmanacs: builder.query<AlmanacConfig[], { serial?: string }>({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: () => `/profiles/almanacs/global`,
      transformResponse: (response: AlmanacConfig[]) =>
        response.map((almanac) => AlmanacConfig.fromJSON(almanac)),
      providesTags: (almanacs) =>
        almanacs?.map((almanac) => ({
          type: Tag.ADMIN_ALMANAC,
          id: almanac.id,
        })) ?? [],
    }),
    getGlobalAlmanac: builder.query<AlmanacConfig, { uuid: string }>({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: ({ uuid }) => `/profiles/almanacs/global/${uuid}`,
      transformResponse: (response: AlmanacConfig) =>
        AlmanacConfig.fromJSON(response),
      providesTags: (result, error, { uuid }) => [
        {
          type: Tag.ADMIN_ALMANAC,
          id: uuid,
        },
      ],
      onQueryStarted: async ({ uuid }, { dispatch, queryFulfilled }) => {
        const { data: newAlmanac } = await queryFulfilled;
        if (!newAlmanac.id) {
          return;
        }
        dispatch(
          portalApi.util.updateQueryData(
            "listGlobalAlmanacs",
            {},
            (almanacs) => {
              const index = almanacs.findIndex(
                (almanac) => almanac.id === uuid
              );
              if (index === -1) {
                almanacs.push(newAlmanac);
              } else {
                almanacs[index] = newAlmanac;
              }
              return almanacs;
            }
          )
        );
      },
    }),
    deleteGlobalAlmanac: builder.mutation<void, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/profiles/almanacs/global/${uuid}`,
        method: Method.DELETE,
      }),
      onQueryStarted: async ({ uuid }, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "listGlobalAlmanacs",
            {},
            (almanacs) => {
              spliceIfExists(almanacs, (almanac) => almanac.id === uuid, 1);
              return almanacs;
            }
          )
        );
        dispatch(
          portalApi.util.invalidateTags([{ type: Tag.ADMIN_ALMANAC, id: uuid }])
        );
      },
    }),
    setGlobalAlmanac: builder.mutation<
      AlmanacConfig,
      { serial?: string; almanac: AlmanacConfig }
    >({
      query: ({ almanac }) => {
        return {
          url: `/profiles/almanacs/global/${almanac.id}`,
          method: Method.POST,
          body: AlmanacConfig.toJSON(almanac),
        };
      },
      transformResponse: (response: AlmanacConfig) =>
        AlmanacConfig.fromJSON(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data: newAlmanac } = await queryFulfilled;
        if (!newAlmanac.id) {
          return;
        }
        dispatch(
          portalApi.util.updateQueryData(
            "listGlobalAlmanacs",
            {},
            (almanacs) => {
              const index = almanacs.findIndex(
                (almanac) => almanac.id === newAlmanac.id
              );
              if (index === -1) {
                almanacs.push(newAlmanac);
              } else {
                almanacs[index] = newAlmanac;
              }
              return almanacs;
            }
          )
        );
        dispatch(
          portalApi.util.updateQueryData(
            "getGlobalAlmanac",
            { uuid: newAlmanac.id },
            () => newAlmanac
          )
        );
      },
    }),

    // DISCRIMINATOR
    listDiscriminatorsForRobot: builder.query<
      DiscriminatorConfig[],
      { serial: string }
    >({
      query: ({ serial }) => `/profiles/discriminators/robots/${serial}`,
      transformResponse: (response: DiscriminatorConfig[]) =>
        response.map((discriminator) =>
          DiscriminatorConfig.fromJSON(discriminator)
        ),
      providesTags: (discriminators) =>
        discriminators?.map((discrimininator) => ({
          type: Tag.DISCRIMINATOR,
          id: discrimininator.id,
        })) ?? [],
    }),
    getDiscriminator: builder.query<DiscriminatorConfig, { uuid: string }>({
      query: ({ uuid }) => `/profiles/discriminators/profiles/${uuid}`,
      transformResponse: (response: DiscriminatorConfig) =>
        DiscriminatorConfig.fromJSON(response),
      providesTags: (result, error, { uuid }) => [
        {
          type: Tag.DISCRIMINATOR,
          id: uuid,
        },
      ],
    }),
    deleteDiscriminator: builder.mutation<void, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/profiles/discriminators/profiles/${uuid}`,
        method: Method.DELETE,
      }),
      invalidatesTags: (result, error, { uuid }) => [
        { type: Tag.DISCRIMINATOR, id: uuid },
      ],
    }),
    setDiscriminator: builder.mutation<
      DiscriminatorConfig,
      { serial: string; discriminator: DiscriminatorConfig }
    >({
      query: ({ serial, discriminator }) => ({
        url: `/profiles/discriminators/robots/${serial}`,
        method: Method.POST,
        body: DiscriminatorConfig.toJSON(discriminator),
      }),
      transformResponse: (response: DiscriminatorConfig) =>
        DiscriminatorConfig.fromJSON(response),
      onQueryStarted: async ({ serial }, { dispatch, queryFulfilled }) => {
        const { data: newDiscriminator } = await queryFulfilled;
        if (!newDiscriminator.id) {
          return;
        }
        dispatch(
          portalApi.util.updateQueryData(
            "listDiscriminatorsForRobot",
            { serial },
            (discriminators) => {
              const index = discriminators.findIndex(
                (discriminator) => discriminator.id === newDiscriminator.id
              );
              if (index === -1) {
                discriminators.push(newDiscriminator);
              } else {
                discriminators[index] = newDiscriminator;
              }
              return discriminators;
            }
          )
        );
        dispatch(
          portalApi.util.updateQueryData(
            "getDiscriminator",
            { uuid: newDiscriminator.id },
            () => newDiscriminator
          )
        );
      },
    }),

    // MODELINATOR
    listModelinatorsForRobotAndCrop: builder.query<
      ModelinatorConfig[],
      { serial: string; cropId: string }
    >({
      query: ({ serial, cropId }) =>
        `/profiles/modelinators/robots/${serial}/crops/${cropId}`,
      transformResponse: (response: ModelinatorConfig[]) =>
        response.map((modelinator) => ModelinatorConfig.fromJSON(modelinator)),
      providesTags: (modelinators, error, { serial }) =>
        modelinators?.map((modelinator) => ({
          type: Tag.MODELINDATOR,
          id: getModelinatorId(serial, modelinator),
        })) ?? [],
    }),
    getModelinator: builder.query<
      ModelinatorConfig | undefined,
      { serial: string; cropId: string; modelId: string }
    >({
      query: ({ serial, cropId }) =>
        `/profiles/modelinators/robots/${serial}/crops/${cropId}`,
      transformResponse: (
        modelinators: ModelinatorConfig[],
        error,
        { cropId, modelId }
      ) => {
        const modelinator = findWhere(modelinators, { cropId, modelId });
        if (!modelinator) {
          return;
        }
        return ModelinatorConfig.fromJSON(modelinator);
      },
      onQueryStarted: async (
        { serial, cropId, modelId },
        { dispatch, queryFulfilled }
      ) => {
        const { data: newModelinator } = await queryFulfilled;
        if (!newModelinator) {
          return;
        }
        dispatch(
          portalApi.util.updateQueryData(
            "listModelinatorsForRobotAndCrop",
            { serial, cropId },
            (modelinators) => {
              const index = modelinators.findIndex(
                (modelinator) => modelinator.modelId === modelId
              );
              if (index === -1) {
                modelinators.push(newModelinator);
              } else {
                modelinators[index] = newModelinator;
              }
              return modelinators;
            }
          )
        );
      },
    }),
    setModelinator: builder.mutation<
      ModelinatorConfig,
      { serial: string; modelinator: ModelinatorConfig }
    >({
      query: ({ serial, modelinator }) => ({
        url: `/profiles/modelinators/robots/${serial}`,
        method: Method.POST,
        body: ModelinatorConfig.toJSON(modelinator),
      }),
      transformResponse: (response: ModelinatorConfig) =>
        ModelinatorConfig.fromJSON(response),
      onQueryStarted: async ({ serial }, { dispatch, queryFulfilled }) => {
        const { data: newModelinator } = await queryFulfilled;
        const { cropId, modelId } = newModelinator;
        dispatch(
          portalApi.util.updateQueryData(
            "listModelinatorsForRobotAndCrop",
            { serial, cropId: newModelinator.cropId },
            (modelinators) => {
              const index = modelinators.findIndex(
                (modelinator) => modelinator.modelId === newModelinator.modelId
              );
              if (index === -1) {
                modelinators.push(newModelinator);
              } else {
                modelinators[index] = newModelinator;
              }
              return modelinators;
            }
          )
        );
        dispatch(
          portalApi.util.updateQueryData(
            "getModelinator",
            { serial, cropId, modelId },
            () => newModelinator
          )
        );
      },
    }),

    // TARGET VELOCITY ESTIMATOR
    // any string is valid for the input. Just matching the signature of
    // listTargetVelocityEstimators so they can be used interchangeably
    listGlobalTargetVelocityEstimators: builder.query<
      TVEProfile[],
      { serial?: string }
    >({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: () => `/profiles/velocity/global`,
      transformResponse: (response: TVEProfile[]) =>
        response.map((targetVelocityEstimator) =>
          TVEProfile.fromJSON(targetVelocityEstimator)
        ),
      providesTags: (targetVelocityEstimators) =>
        targetVelocityEstimators?.map((targetVelocityEstimator) => ({
          type: Tag.TARGET_VELOCITY_ESTIMATOR,
          id: targetVelocityEstimator.id,
        })) ?? [],
    }),
    getGlobalTargetVelocityEstimator: builder.query<
      TVEProfile,
      { uuid: string }
    >({
      keepUnusedDataFor: 60 * 60, // 1 hour
      query: ({ uuid }) => `/profiles/velocity/global/${uuid}`,
      transformResponse: (response: TVEProfile) =>
        TVEProfile.fromJSON(response),
      providesTags: (result, error, { uuid }) => [
        {
          type: Tag.ADMIN_TARGET_VELOCITY_ESTIMATOR,
          id: uuid,
        },
      ],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data: newTargetVelocityEstimator } = await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "listGlobalTargetVelocityEstimators",
            {},
            (targetVelocityEstimators) => {
              const index = targetVelocityEstimators.findIndex(
                (targetVelocityEstimator) =>
                  targetVelocityEstimator.id === newTargetVelocityEstimator.id
              );
              if (index === -1) {
                targetVelocityEstimators.push(newTargetVelocityEstimator);
              } else {
                targetVelocityEstimators[index] = newTargetVelocityEstimator;
              }
              return targetVelocityEstimators;
            }
          )
        );
      },
    }),
    deleteTargetVelocityEstimator: builder.mutation<void, { uuid: string }>({
      query: ({ uuid }) => ({
        url: `/profiles/velocity/profiles/${uuid}`,
        method: Method.DELETE,
      }),
      invalidatesTags: (result, error, { uuid }) => [
        { type: Tag.TARGET_VELOCITY_ESTIMATOR, id: uuid },
      ],
    }),
    deleteGlobalTargetVelocityEstimator: builder.mutation<
      void,
      { uuid: string }
    >({
      query: ({ uuid }) => ({
        url: `/profiles/velocity/global/${uuid}`,
        method: Method.DELETE,
      }),
      onQueryStarted: async ({ uuid }, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "listGlobalTargetVelocityEstimators",
            {},
            (targetVelocityEstimators) => {
              spliceIfExists(
                targetVelocityEstimators,
                (targetVelocityEstimator) =>
                  targetVelocityEstimator.id === uuid,
                1
              );
              return targetVelocityEstimators;
            }
          )
        );
        dispatch(
          portalApi.util.invalidateTags([
            { type: Tag.ADMIN_TARGET_VELOCITY_ESTIMATOR, id: uuid },
          ])
        );
      },
    }),
    setGlobalTargetVelocityEstimator: builder.mutation<
      TVEProfile,
      { serial?: string; targetVelocityEstimator: TVEProfile }
    >({
      query: ({ targetVelocityEstimator }) => {
        return {
          url: `/profiles/velocity/global/${targetVelocityEstimator.id}`,
          method: Method.POST,
          body: TVEProfile.toJSON(targetVelocityEstimator),
        };
      },
      transformResponse: (response: TVEProfile) =>
        TVEProfile.fromJSON(response),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data: newTargetVelocityEstimator } = await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "listGlobalTargetVelocityEstimators",
            {},
            (targetVelocityEstimators) => {
              const index = targetVelocityEstimators.findIndex(
                (targetVelocityEstimator) =>
                  targetVelocityEstimator.id === newTargetVelocityEstimator.id
              );
              if (index === -1) {
                targetVelocityEstimators.push(newTargetVelocityEstimator);
              } else {
                targetVelocityEstimators[index] = newTargetVelocityEstimator;
              }
              return targetVelocityEstimators;
            }
          )
        );
        dispatch(
          portalApi.util.updateQueryData(
            "getGlobalTargetVelocityEstimator",
            { uuid: newTargetVelocityEstimator.id },
            () => newTargetVelocityEstimator
          )
        );
      },
    }),
    listTargetVelocityEstimators: builder.query<
      TVEProfile[],
      { serial?: string }
    >({
      query: ({ serial }) => `/profiles/velocity/robots/${serial}`,
      transformResponse: (response: TVEProfile[]) =>
        response.map((targetVelocityEstimator) =>
          TVEProfile.fromJSON(targetVelocityEstimator)
        ),
      providesTags: (targetVelocityEstimators) =>
        targetVelocityEstimators?.map((targetVelocityEstimator) => ({
          type: Tag.TARGET_VELOCITY_ESTIMATOR,
          id: targetVelocityEstimator.id,
        })) ?? [],
    }),
    getTargetVelocityEstimator: builder.query<TVEProfile, { uuid: string }>({
      query: ({ uuid }) => `/profiles/velocity/profiles/${uuid}`,
      transformResponse: (response: TVEProfile) =>
        TVEProfile.fromJSON(response),
      providesTags: (targetVelocityTestimator) =>
        targetVelocityTestimator
          ? [
              {
                type: Tag.TARGET_VELOCITY_ESTIMATOR,
                id: targetVelocityTestimator.id,
              },
            ]
          : [],
    }),
    setTargetVelocityEstimator: builder.mutation<
      TVEProfile,
      { serial?: string; targetVelocityEstimator: TVEProfile }
    >({
      query: ({ serial, targetVelocityEstimator }) => ({
        url: `/profiles/velocity/robots/${serial}`,
        method: Method.POST,
        body: TVEProfile.toJSON(targetVelocityEstimator),
      }),
      transformResponse: (response: TVEProfile) =>
        TVEProfile.fromJSON(response),
      onQueryStarted: async ({ serial }, { dispatch, queryFulfilled }) => {
        const { data: newTargetVelocityEstimator } = await queryFulfilled;
        dispatch(
          portalApi.util.updateQueryData(
            "listTargetVelocityEstimators",
            { serial },
            (targetVelocityEstimators) => {
              const index = targetVelocityEstimators.findIndex(
                (targetVelocityEstimator) =>
                  targetVelocityEstimator.id === newTargetVelocityEstimator.id
              );
              if (index === -1) {
                targetVelocityEstimators.push(newTargetVelocityEstimator);
              } else {
                targetVelocityEstimators[index] = newTargetVelocityEstimator;
              }
              return targetVelocityEstimators;
            }
          )
        );
        dispatch(
          portalApi.util.updateQueryData(
            "getTargetVelocityEstimator",
            { uuid: newTargetVelocityEstimator.id },
            () => newTargetVelocityEstimator
          )
        );
      },
    }),
  }),
});

export const {
  // AWS
  useGetS3UploadUrlQuery,
  useLazyGetS3UploadUrlQuery,

  // ALARMS
  useListAlarmsQuery,
  useLazyListAlarmsQuery,

  // HARDWARE
  useGetHardwareQuery,
  useLazyGetHardwareQuery,

  // CONFIGS
  useGetConfigQuery,
  useLazyGetConfigQuery,
  useSetConfigValueMutation,
  useDeleteConfigPathMutation,
  useGetConfigTemplateQuery,
  useLazyGetConfigTemplateQuery,
  useSetConfigTemplateValueMutation,
  useDeleteConfigTemplatePathMutation,

  // CUSTOMERS
  useListCustomersQuery,
  useLazyListCustomersQuery,
  useGetCustomerQuery,
  useLazyGetCustomerQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,

  // MESSAGES
  useListMessagesQuery,
  useLazyListMessagesQuery,
  useSendMessageMutation,

  // REPORTS
  useListReportsQuery,
  useLazyListReportsQuery,
  useGetReportQuery,
  useLazyGetReportQuery,
  useCreateReportMutation,
  useDeleteReportMutation,
  useUpdateReportMutation,
  useListReportInstancesQuery,
  useLazyListReportInstancesQuery,
  useGetReportInstanceQuery,
  useLazyGetReportInstanceQuery,
  useCreateReportInstanceMutation,
  useUpdateReportInstanceMutation,
  useDeleteReportInstanceMutation,

  // METRICS
  useLazyGetDateMetricsQuery,
  useLazyGetSpatialQuery,

  // ROBOTS
  useListRobotsQuery,
  useLazyListRobotsQuery,
  useGetRobotQuery,
  useLazyGetRobotQuery,
  useGetRobotHistoryQuery,
  useLazyGetRobotHistoryQuery,
  useGetRobotBlocksQuery,
  useLazyGetRobotBlocksQuery,
  useGetRobotMetricsQuery,
  useLazyGetRobotMetricsQuery,
  useCreateRobotMutation,
  useUpdateRobotMutation,
  useAssignRobotMutation,
  useGetRobotProxyMutation,
  useListRobotCropsQuery,
  useLazyListRobotCropsQuery,
  useListRobotJobsQuery,
  useLazyListRobotJobsQuery,
  useGetJobQuery,
  useLazyGetJobQuery,
  useGetJobHistoryQuery,
  useLazyGetJobHistoryQuery,
  useGetJobBlocksQuery,
  useLazyGetJobBlocksQuery,
  useGetRobotHardwareQuery,
  useLazyGetRobotHardwareQuery,

  // FIELD DEFINITIONS
  useCreateFieldDefinitionMutation,

  // USERS
  useListUsersQuery,
  useLazyListUsersQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useInviteUserMutation,
  useCreateFleetViewMutation,
  useUpdateFleetViewMutation,
  useDeleteFleetViewMutation,

  // VESELKA
  useListUploadsQuery,
  useLazyListUploadsQuery,
  useListCropsQuery,
  useLazyListCropsQuery,
  useGetCropQuery,
  useLazyGetCropQuery,
  useGetModelQuery,
  useLazyGetModelQuery,

  // ADMIN
  useDeleteCachesMutation,
  useGetGlobalAlarmListsQuery,
  useLazyGetGlobalAlarmListsQuery,
  useGetRobotAlarmListsQuery,
  useLazyGetRobotAlarmListsQuery,
  useSetGlobalAlarmListsMutation,
  useSetRobotAlarmListsMutation,

  // ALMANAC
  useListAlmanacsForRobotQuery,
  useLazyListAlmanacsForRobotQuery,
  useGetAlmanacQuery,
  useLazyGetAlmanacQuery,
  useDeleteAlmanacMutation,
  useSetAlmanacMutation,
  useListGlobalAlmanacsQuery,
  useLazyListGlobalAlmanacsQuery,
  useGetGlobalAlmanacQuery,
  useLazyGetGlobalAlmanacQuery,
  useDeleteGlobalAlmanacMutation,
  useSetGlobalAlmanacMutation,

  // DISCRIMINATOR
  useListDiscriminatorsForRobotQuery,
  useLazyListDiscriminatorsForRobotQuery,
  useGetDiscriminatorQuery,
  useLazyGetDiscriminatorQuery,
  useDeleteDiscriminatorMutation,
  useSetDiscriminatorMutation,

  // MODELINATOR
  useListModelinatorsForRobotAndCropQuery,
  useLazyListModelinatorsForRobotAndCropQuery,
  useGetModelinatorQuery,
  useLazyGetModelinatorQuery,
  useSetModelinatorMutation,

  // TARGET VELOCITY ESTIMATOR
  useDeleteGlobalTargetVelocityEstimatorMutation,
  useDeleteTargetVelocityEstimatorMutation,
  useGetGlobalTargetVelocityEstimatorQuery,
  useLazyGetGlobalTargetVelocityEstimatorQuery,
  useGetTargetVelocityEstimatorQuery,
  useLazyGetTargetVelocityEstimatorQuery,
  useListGlobalTargetVelocityEstimatorsQuery,
  useLazyListGlobalTargetVelocityEstimatorsQuery,
  useListTargetVelocityEstimatorsQuery,
  useLazyListTargetVelocityEstimatorsQuery,
  useSetGlobalTargetVelocityEstimatorMutation,
  useSetTargetVelocityEstimatorMutation,
} = portalApi;
