import { combineReducers, StateFromReducersMapObject } from "redux";
import { configureStore, Tuple } from "@reduxjs/toolkit";
import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  PersistedState,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { idmApi } from "./idmApi";
import { measurement } from "./measurement";
import { notifications } from "./notifications";
import { portalApi } from "./portalApi";
import { self } from "./self";
import { setupListeners } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux";
import storage from "redux-persist/lib/storage";

enum AsyncThunkStatus {
  IDLE = "idle",
  PENDING = "pending",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

const reducers = combineReducers({
  [portalApi.reducerPath]: portalApi.reducer,
  [idmApi.reducerPath]: idmApi.reducer,
  [self.name]: self.reducer,
  [measurement.name]: measurement.reducer,
  [notifications.name]: notifications.reducer,
});

const getPersistedState = (
  version: number,
  state: PersistedState,
  overrides?: Partial<StateFromReducersMapObject<typeof reducers>>
): Exclude<PersistedState, undefined> => ({
  _persist: {
    version,
    rehydrated: false,
  },
  ...state,
  ...overrides,
});

const persistedReducers = persistReducer(
  {
    key: "root",
    version: 2,
    storage,
    blacklist: [
      portalApi.reducerPath,
      idmApi.reducerPath,
      notifications.name,
      self.name,
    ],
    migrate: createMigrate({
      // we never used version -1 but just in case
      0: (state) => getPersistedState(0, state),
      // we never used version 0 but just in case
      1: (state) => getPersistedState(1, state),
      // clear cycleSlots because convert library changed
      2: (state) =>
        getPersistedState(2, state, {
          [measurement.name]: measurement.getInitialState(),
        }),
    }),
  },
  reducers
);

export const store = configureStore({
  reducer: persistedReducers,
  middleware: (getDefaultMiddleware) =>
    new Tuple(
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredPaths: [],
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
      portalApi.middleware,
      idmApi.middleware
    ),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useSelf: () => RootState["self"] = (): RootState["self"] =>
  useSelector((state: RootState) => state.self);

export const useMeasurement: () => RootState["measurement"] =
  (): RootState["measurement"] =>
    useSelector((state: RootState) => state.measurement);

export const useNotifications: () => RootState["notifications"] =
  (): RootState["notifications"] =>
    useSelector((state: RootState) => state.notifications);

export interface AsyncDataState<T> {
  data?: T;
  status: AsyncThunkStatus;
}

export const asyncDataIdle = <T>(data?: T): AsyncDataState<T> => ({
  data,
  status: AsyncThunkStatus.IDLE,
});

export const asyncDataPending = <T>(data?: T): AsyncDataState<T> => ({
  data,
  status: AsyncThunkStatus.PENDING,
});

export const asyncDataSuccess = <T>(data: T): AsyncDataState<T> => ({
  data,
  status: AsyncThunkStatus.SUCCEEDED,
});

export const asyncDataFailed = <T>(data?: T): AsyncDataState<T> => ({
  data,
  status: AsyncThunkStatus.FAILED,
});
