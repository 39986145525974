import { ConfigCrop } from "protos/portal/configs";
import { ConfigNode } from "protos/config/api/config_service";
import { Crop, RobotCrop } from "protos/portal/veselka";
import { findWhere, sortBy } from "./arrays";
import { getNodeFromPath, getValue } from "./configs";
import { isBud, isReaper, isSimulator, isSlayer } from "./robots";

export const SLAYER_CROP_IDS_PATH = "command/commander/crop_ids";
export const BUD_CROP_IDS_PATH = `bud/commander/crop_ids`;

export enum CropConfidence {
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  HIGH = "HIGH",
}

export const cropToConfigCrop = (crop: Crop): ConfigCrop => ({
  id: crop.id,
  carbonName: crop.carbonName,
  commonName: crop.commonName,
  pinned: "",
  recommended: "",
  isEnabled: true,
});

export interface SortedCrops {
  pinnedAndEnabledCrops: number;
  archived: ConfigCrop[];
  enabled: ConfigCrop[];
  disabled: ConfigCrop[];
  all: ConfigCrop[];
}

export const getCrops = (
  serial?: string,
  config?: ConfigNode,
  crops?: RobotCrop[]
): SortedCrops => {
  const result: SortedCrops = {
    pinnedAndEnabledCrops: 0,
    archived: [],
    enabled: [],
    disabled: [],
    all: [],
  };
  if (!serial) {
    return result;
  }
  let cropIdsNode: ConfigNode | undefined;
  if (isSlayer(serial) || isSimulator(serial) || isReaper(serial)) {
    cropIdsNode = getNodeFromPath(config, SLAYER_CROP_IDS_PATH);
  } else if (isBud(serial)) {
    cropIdsNode = getNodeFromPath(config, BUD_CROP_IDS_PATH);
  }
  if (!cropIdsNode) {
    return result;
  }
  for (const cropNode of cropIdsNode.children) {
    const veselkaCrop = findWhere(crops, { crop: { id: cropNode.name } });
    const carbonName =
      veselkaCrop?.crop?.carbonName ??
      getValue<string>(getNodeFromPath(cropNode, "carbon_name")) ??
      "Unknown";
    const commonName =
      veselkaCrop?.crop?.commonName ??
      getValue<string>(getNodeFromPath(cropNode, "common_name")) ??
      "Unknown";
    const isEnabled =
      getValue<boolean>(getNodeFromPath(cropNode, "enabled")) ?? false;
    const pinned = getValue<string>(getNodeFromPath(cropNode, "pinned_model"));
    const recommended = getValue<string>(
      getNodeFromPath(cropNode, "recommended_model")
    );
    if (isEnabled && pinned) {
      result.pinnedAndEnabledCrops++;
    }
    const crop = {
      id: cropNode.name,
      carbonName,
      commonName,
      pinned: pinned ?? "",
      recommended: recommended ?? "",
      isEnabled,
    };
    if (isEnabled) {
      result.enabled.push(crop);
    } else if (veselkaCrop && !veselkaCrop.crop?.archived) {
      result.disabled.push(crop);
    } else {
      result.archived.push(crop);
    }
  }
  sortBy(result.enabled, "commonName");
  sortBy(result.disabled, "commonName");
  result.all = [...result.enabled, ...result.disabled];
  return result;
};

export const getConfigKey = (serial: string, cropId: string): string =>
  `${isBud(serial) ? BUD_CROP_IDS_PATH : SLAYER_CROP_IDS_PATH}/${cropId}`;
