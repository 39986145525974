import { Bounds, Point, sanitizeBounds } from "portal/utils/geo";
import { ReactComponent as CarbonLogo } from "portal/images/logo_icon_border.svg";
import { FilterData } from "portal/utils/map";
import { getClass, getCustomerSerial } from "portal/utils/robots";
import { getRobotPath } from "portal/utils/routing";
import { isUndefined } from "portal/utils/identity";
import { Link } from "react-router-dom";
import { Marker } from "react-map-gl";
import { RobotSummaryResponse } from "protos/portal/robots";
import { RobotTypeIcon } from "../RobotTypeIcon";
import { Tooltip } from "@mui/material";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import React, { ReactNode, useMemo } from "react";

export const useMapRobots = (
  robots: RobotSummaryResponse[] | undefined,
  mapFilters: FilterData<boolean>
): {
  markers: ReactNode[];
  focus?: Point;
  bounds?: Bounds;
} => {
  const { isInternal } = useSelf();
  const { t } = useTranslation();

  const focus = useMemo<Point | undefined>(() => {
    if (!robots) {
      return;
    }
    const firstRobotWithLocation = robots.find(
      (summary) =>
        !isUndefined(summary.robot?.health?.location?.x) &&
        !isUndefined(summary.robot.health.location.y)
    );
    const firstRobotLatitude =
      firstRobotWithLocation?.robot?.health?.location?.x;
    const firstRobotLongitude =
      firstRobotWithLocation?.robot?.health?.location?.y ?? 0;
    if (firstRobotLatitude && firstRobotLongitude) {
      return [firstRobotLongitude, firstRobotLatitude];
    }
  }, [robots]);

  const [markers, bounds] = useMemo<[ReactNode[], Bounds | undefined]>(() => {
    let hasBounds = false;
    let minX = Infinity;
    let maxX = -Infinity;
    let minY = Infinity;
    let maxY = -Infinity;
    const newMarkers: ReactNode[] = [];
    for (const summary of robots ?? []) {
      if (!mapFilters.robots[getClass(summary.robot)]) {
        continue;
      }
      const latitude = summary.robot?.health?.location?.x ?? 0;
      const longitude = summary.robot?.health?.location?.y ?? 0;
      const hasLocation =
        latitude &&
        longitude &&
        !Number.isNaN(latitude) &&
        !Number.isNaN(longitude);
      if (!hasLocation) {
        continue;
      }
      hasBounds = true;
      minX = Math.min(minX, latitude);
      maxX = Math.max(maxX, latitude);
      minY = Math.min(minY, longitude);
      maxY = Math.max(maxY, longitude);
      newMarkers.push(
        <Marker
          style={{ cursor: "pointer" }}
          key={summary.robot?.db?.id}
          latitude={latitude}
          longitude={longitude}
        >
          <Tooltip
            title={
              isInternal
                ? summary.robot?.serial
                : getCustomerSerial(t, summary.robot?.serial)
            }
            arrow
          >
            {/* this wrapper prevents react from trying to ref an SVG which breaks*/}
            <Link to={getRobotPath(summary.robot?.serial)}>
              {isInternal ? (
                <RobotTypeIcon robotType={getClass(summary.robot)} />
              ) : (
                <CarbonLogo className="w-4 h-4 text-orange-500" />
              )}
            </Link>
          </Tooltip>
        </Marker>
      );
    }
    const newBounds =
      hasBounds && minX !== maxX && minY !== maxY
        ? sanitizeBounds({ minX, maxX, minY, maxY })
        : undefined;
    return [newMarkers, newBounds];
  }, [isInternal, mapFilters.robots, robots, t]);

  return { markers, focus, bounds };
};
