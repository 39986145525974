import { Alert, Card, Skeleton, Typography } from "@mui/material";
import { classes } from "portal/utils/theme";
import { DailyMetricResponse } from "protos/portal/metrics";
import { DATE_PATH_FORMAT } from "portal/utils/dates";
import { DateTime } from "luxon";
import { FeatureFlag, useFeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import { filterWhere, range as rangeArray } from "portal/utils/arrays";
import {
  getMetricName,
  METRIC_GROUPS,
  MetricGroup,
} from "portal/utils/certifiedMetrics";
import { isEmpty } from "portal/utils/objects";
import { Measurement } from "./measurement/Measurement";
import { Metric, MetricValidation } from "portal/utils/metrics";
import { titleCase } from "portal/utils/strings";
import { useLazyGetRobotMetricsQuery } from "portal/state/portalApi";
import { useLazyPopups } from "portal/utils/hooks/useApiPopups";
import { useMemoAsync } from "portal/utils/hooks/useMemoAsync";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { ViewPlaceholder } from "./ViewPlaceholder";
import Grid from "@mui/material/Unstable_Grid2";
import React, { FunctionComponent } from "react";

interface CertifiedMetricsBaseProps {
  className?: string;
}

interface CertifiedMetricsParameterProps extends CertifiedMetricsBaseProps {
  serial: string;
  date: DateTime;
}

interface CertifiedMetricsObjectProps extends CertifiedMetricsBaseProps {
  metrics: DailyMetricResponse;
}

type CertifiedMetricsProps =
  | CertifiedMetricsParameterProps
  | CertifiedMetricsObjectProps;

export const CertifiedMetrics: FunctionComponent<CertifiedMetricsProps> = (
  props
) => {
  const { t } = useTranslation();
  const isObjectProps = "metrics" in props;

  let inputMetrics: DailyMetricResponse | undefined;
  let serial: string;
  let date: string;

  const today = DateTime.local().toFormat(DATE_PATH_FORMAT);

  if (isObjectProps) {
    inputMetrics = props.metrics;
    serial = inputMetrics.serial;
    date = inputMetrics.date;
  } else {
    date = today;
    ({ serial } = props);
  }

  const [getMetrics] = useLazyPopups(useLazyGetRobotMetricsQuery());
  // fetch metrics if they aren't set
  const [metrics, { isLoading }] = useMemoAsync<
    DailyMetricResponse | undefined
  >(
    async () => {
      if (!serial) {
        return;
      }
      if (isObjectProps) {
        return inputMetrics;
      }
      const { data } = await getMetrics({ serial, date }, true);
      return data;
    },
    [serial, isObjectProps, getMetrics, date, inputMetrics],
    undefined
  );

  if (isLoading) {
    return (
      <>
        {rangeArray(3, true).map((index) => (
          <Skeleton variant="rectangular" className="w-full" key={index} />
        ))}
      </>
    );
  }
  if (!metrics || isEmpty(metrics)) {
    return (
      <ViewPlaceholder
        text={t("views.fleet.robots.history.errors.noMetrics")}
      />
    );
  }

  return (
    <div className={props.className}>
      {date === today && (
        <Alert className="mb-8" severity="warning">
          {t("views.fleet.robots.history.warnings.ongoing")}
        </Alert>
      )}

      <Grid container className="w-full items-stretch" spacing={2}>
        {METRIC_GROUPS.map((group) => (
          <MetricBlock group={group} data={metrics} key={group.i18nKey} />
        ))}
      </Grid>
    </div>
  );
};

interface MetricBlockProps {
  group: MetricGroup;
  data: DailyMetricResponse;
}

const MetricBlock: FunctionComponent<MetricBlockProps> = ({
  group: { i18nKey: titleKey, metrics },
  data,
}) => {
  const { t } = useTranslation();

  const { isEnabled: hasUnvalidatedMetrics } = useFeatureFlag(
    FeatureFlag.UNVALIDATED_METRICS
  );
  const { isInternal } = useSelf();
  const isAllPending =
    filterWhere(metrics, { validation: MetricValidation.VALIDATED })?.length ===
    0;

  if (isAllPending && !isInternal && !hasUnvalidatedMetrics) {
    return;
  }
  return (
    <Grid xs={12} lg={6}>
      <Card className="p-4 h-full">
        <Typography
          variant="h4"
          className={classes(
            "text-md opacity-70 mb-0 leading-1 w-full text-lg",
            {
              "italic text-blue-400": isAllPending,
            }
          )}
        >
          {titleCase(t(titleKey))}
        </Typography>
        <Grid container columnSpacing={1} className="w-full">
          {metrics.map((metric) => (
            <MetricView key={metric.id} data={data} metric={metric} />
          ))}
        </Grid>
      </Card>
    </Grid>
  );
};

interface MetricProps {
  data: DailyMetricResponse;
  metric: Metric;
}
const MetricView: FunctionComponent<MetricProps> = ({ data, metric }) => {
  const { isEnabled: hasUnvalidatedMetrics } = useFeatureFlag(
    FeatureFlag.UNVALIDATED_METRICS
  );
  const { isInternal } = useSelf();
  const value = metric.getValue(
    data[metric.id as keyof DailyMetricResponse],
    data,
    isInternal
  );

  const { t } = useTranslation();
  const wrapperClasses = classes({
    "italic text-blue-600": metric.validation === MetricValidation.PENDING,
    "italic text-orange-200": metric.validation === MetricValidation.INTERNAL,
  });

  if (
    metric.validation === MetricValidation.PENDING &&
    !isInternal &&
    !hasUnvalidatedMetrics
  ) {
    return;
  }

  if (metric.validation === MetricValidation.INTERNAL && !isInternal) {
    return;
  }

  return (
    <>
      <Grid
        className={classes(wrapperClasses, "whitespace-nowrap text-sm")}
        xs={12}
        md={8}
      >
        {getMetricName(t, metric)}
      </Grid>
      <Grid
        className={classes(
          wrapperClasses,
          "font-mono font-bold whitespace-nowrap"
        )}
        xs={12}
        md={4}
      >
        <Measurement metric={metric} value={value} />
      </Grid>
    </>
  );
};
