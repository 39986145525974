import { COLOR_PINK } from "./blocks";
import { Feature, Geometry, LineString, Polygon } from "geojson";
import { FillLayer, LngLatBoundsLike, SymbolLayer } from "react-map-gl";
import { isObject, keys } from "./objects";
import { isUndefined } from "./identity";
import { TFunction } from "i18next";
import { theme } from "./theme";
import turfArea from "@turf/area";

export type Point = [number, number];
export type Path = Point[];
export interface Bounds {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
}

export const sanitizeGeoNumber = (input: unknown): number | undefined => {
  if (typeof input === "number" && !Number.isNaN(input)) {
    if (
      input === Number.MAX_SAFE_INTEGER ||
      input === Number.MIN_SAFE_INTEGER ||
      input === Infinity ||
      input === -Infinity
    ) {
      return undefined;
    }
    return input;
  }
  if (typeof input === "string") {
    const parsed = Number.parseFloat(input);
    if (!Number.isNaN(parsed)) {
      return parsed;
    }
  }
  return undefined;
};

export const sanitizeLatitude = (input: unknown): number | undefined => {
  const latitude = sanitizeGeoNumber(input);
  if (isUndefined(latitude) || latitude < -90 || latitude > 90) {
    return undefined;
  }
  return latitude;
};

export const sanitizeLongitude = (input: unknown): number | undefined => {
  const longitude = sanitizeGeoNumber(input);
  if (isUndefined(longitude) || longitude < -180 || longitude > 180) {
    return undefined;
  }
  return longitude;
};

export const sanitizeBounds = (input: unknown): Bounds | undefined => {
  if (
    !isObject(input) ||
    keys(input).length !== 4 ||
    !("minX" in input) ||
    !("maxX" in input) ||
    !("minY" in input) ||
    !("maxY" in input)
  ) {
    return undefined;
  }
  const minX = sanitizeLatitude(input.minX);
  const maxX = sanitizeLatitude(input.maxX);
  const minY = sanitizeLongitude(input.minY);
  const maxY = sanitizeLongitude(input.maxY);
  if (
    isUndefined(minX) ||
    isUndefined(maxX) ||
    isUndefined(minY) ||
    isUndefined(maxY)
  ) {
    return undefined;
  }
  return {
    minX,
    maxX,
    minY,
    maxY,
  };
};

export const mergeBounds = (
  a: Bounds | undefined,
  b: Bounds | undefined
): Bounds | undefined => {
  a = sanitizeBounds(a);
  b = sanitizeBounds(b);
  if (!isUndefined(a) && !isUndefined(b)) {
    return {
      minX: Math.min(a.minX, b.minX),
      maxX: Math.max(a.maxX, b.maxX),
      minY: Math.min(a.minY, b.minY),
      maxY: Math.max(a.maxY, b.maxY),
    };
  }
  if (isUndefined(b) && !isUndefined(a)) {
    return a;
  }
  if (isUndefined(a) && !isUndefined(b)) {
    return b;
  }
  if (isUndefined(a) && isUndefined(b)) {
    return undefined;
  }
  return undefined;
};

export const toLngLatBounds = (
  bounds: Bounds | undefined
): LngLatBoundsLike | undefined => {
  bounds = sanitizeBounds(bounds);
  return bounds
    ? [
        [bounds.minY, bounds.minX],
        [bounds.maxY, bounds.maxX],
      ]
    : undefined;
};

export const LINE_STYLE_DEBUG: Omit<FillLayer, "id"> = {
  type: "fill",
  paint: {
    "fill-color": COLOR_PINK.toString(),
  },
};

export const DRAW_STYLE = [
  // ACTIVE (being drawn)
  // line stroke
  {
    id: "gl-draw-line",
    type: "line",
    filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": theme.colors.carbon.map.draw.border,
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  // polygon fill
  {
    id: "gl-draw-polygon-fill",
    type: "fill",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    paint: {
      "fill-color": theme.colors.carbon.map.draw.fill,
      "fill-outline-color": theme.colors.carbon.map.draw.border,
      "fill-opacity": 0.4,
    },
  },
  // polygon mid points
  {
    id: "gl-draw-polygon-midpoint",
    type: "circle",
    filter: ["all", ["==", "$type", "Point"], ["==", "meta", "midpoint"]],
    paint: {
      "circle-radius": 4,
      "circle-color": theme.colors.carbon.map.draw.vertex,
    },
  },
  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    id: "gl-draw-polygon-stroke-active",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    layout: {
      "line-cap": "round",
      "line-join": "round",
    },
    paint: {
      "line-color": theme.colors.carbon.map.draw.border,
      "line-dasharray": [0.2, 2],
      "line-width": 2,
    },
  },
  // vertex point halos
  {
    id: "gl-draw-polygon-and-line-vertex-halo-active",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 5,
      "circle-color": theme.colors.white,
    },
  },
  // vertex points
  {
    id: "gl-draw-polygon-and-line-vertex-active",
    type: "circle",
    filter: [
      "all",
      ["==", "meta", "vertex"],
      ["==", "$type", "Point"],
      ["!=", "mode", "static"],
    ],
    paint: {
      "circle-radius": 3,
      "circle-color": theme.colors.carbon.map.draw.vertex,
    },
  },
];

export const DRAWING_LABEL_STYLE: Omit<SymbolLayer, "id"> = {
  type: "symbol",
  paint: {
    "text-color": theme.colors.carbon.map.draw.text,
    "text-halo-color": theme.colors.carbon.map.draw.textHalo,
    "text-halo-width": 2,
  },
  layout: {
    "text-field": ["get", "label"],
    "text-size": 12,
    visibility: "visible",
  },
};

export enum FacilityType {
  CUSTOMER_OFFICE = "customer_office",
  HQ = "hq",
  PO_BOX = "po_box",
  SHOP = "shop",
  STORAGE = "storage",
  SUPPORT_BASE = "support_base",
}

export interface Facility {
  address: string;
  latitude: number;
  longitude: number;
  name: string;
  type: FacilityType;
  customerId?: number;
}

enum CUSTOMER_IDS {
  Carzalia = 9,
  Duncan = 12,
  Grimmway = 6,
  Owyhee = 17,
}
// sync with https://carbonrobotics.atlassian.net/wiki/spaces/FOPS/pages/64192521/Carbon+Shipping+locations
export const FACILITIES: Facility[] = [
  {
    name: "Shop #001 (Salinas)",
    type: FacilityType.SHOP,
    address: "265 C River Road\nSalinas, CA 93908",
    latitude: 36.603_791_786_092_63,
    longitude: -121.635_266_086_502_97,
  },
  {
    name: "Carbon HQ (807)",
    type: FacilityType.HQ,
    address: "807 Aurora Avenue North\nSeattle WA, 98109",
    latitude: 47.626_867_048_425_37,
    longitude: -122.344_016_859_293_43,
  },
  {
    name: "Coachella Yard (Grimmway Packing Shed)",
    type: FacilityType.SUPPORT_BASE,
    address: "54895 Fillmore Street\nThermal, CA 92274",
    latitude: 33.650_350_381_165_49,
    longitude: -116.130_208_385_632_68,
    customerId: CUSTOMER_IDS.Grimmway,
  },
  {
    name: "Shop #002 (Bakersfield)",
    type: FacilityType.SHOP,
    address: "331 20th Street\nBakersfield, CA 93301",
    latitude: 35.376_940_667_524_74,
    longitude: -119.005_756_201_957_17,
  },
  {
    name: "Bakersfield PO Box",
    type: FacilityType.PO_BOX,
    address:
      "The UPS Store\n8200 Stockdale Highway\nSuite M10-355\nBakersfield CA 93311",
    latitude: 35.355_113_155_814_03,
    longitude: -119.092_728_644_690_58,
  },
  {
    name: "Goodyear Support Base (Duncan Farms)",
    type: FacilityType.SUPPORT_BASE,
    address: "2701 North Citrus Road\nGoodyear AZ, 85395",
    latitude: 33.477_570_276_543_595,
    longitude: -112.443_463_459_566_43,
    customerId: CUSTOMER_IDS.Duncan,
  },
  {
    name: "Duncan Farms HQ",
    type: FacilityType.CUSTOMER_OFFICE,
    address: "17072 West Indian School Road\nGoodYear, AZ 85395",
    latitude: 33.494_318_391_927_29,
    longitude: -112.426_096_401_895_62,
    customerId: CUSTOMER_IDS.Duncan,
  },
  {
    name: "Carzalia Produce HQ",
    type: FacilityType.CUSTOMER_OFFICE,
    address: "5850 Carzalia Loop\nDeming, NM 88030",
    latitude: 31.791_774_127_231_75,
    longitude: -107.855_700_077_634_74,
    customerId: CUSTOMER_IDS.Carzalia,
  },
  {
    name: "Yuma PO Box",
    type: FacilityType.PO_BOX,
    address: "The UPS Store\n340 West 32nd Street\nPMB 542\nYuma, AZ 85364",
    latitude: 32.671_838_793_841,
    longitude: -114.622_685_886_567_34,
  },
  {
    name: "Shop #003 (Richland)",
    type: FacilityType.SHOP,
    address: "123 Reata Road\nSuite 102\nRichland, WA 99338",
    latitude: 46.199_918_353_217_9,
    longitude: -119.270_417_015_149_09,
  },
  {
    name: "Laser Manufacturing",
    type: FacilityType.SHOP,
    address: "2856 Kingsgate Way\nRichland, WA 99354",
    latitude: 46.335_830_578_617_184,
    longitude: -119.313_164_073_713_57,
  },
  {
    name: "Greeley Shop/Office",
    type: FacilityType.SHOP,
    address: "31466 County Road 39 1/2\nGreeley, CO 80631",
    latitude: 40.457_568_152_617_85,
    longitude: -104.684_358_457_672_32,
  },
  {
    name: "Owyhee Storage Container (Owyhee Produce)",
    type: FacilityType.STORAGE,
    address: "1445 North 3rd Street\nNyssa OR, 97913",
    latitude: 43.892_102_478_106_715,
    longitude: -116.996_815_859_069_6,
    customerId: CUSTOMER_IDS.Owyhee,
  },
  {
    name: "Dickman Farms HQ",
    type: FacilityType.CUSTOMER_OFFICE,
    address:
      "15829 Mount Angel Scotts Mill Road Northeast\nSilverton, OR 97381",
    latitude: 45.053_508_091_729_82,
    longitude: -122.734_672_317_936_59,
  },
  {
    name: "Roush - Assembly",
    type: FacilityType.SHOP,
    address: "Building 28\n12068 Market Street\nLivonia, MI 48150",
    latitude: 42.371_115_005_602_07,
    longitude: -83.404_313_563_181_4,
  },
  {
    name: "Roush - Flex",
    type: FacilityType.SHOP,
    address: "Building 50\n28400 Plymouth Rd\nLivonia, MI 48150",
    latitude: 42.370_129_510_038_296,
    longitude: -83.322_622_314_225_2,
  },
  {
    name: "Roush - Electrical",
    type: FacilityType.SHOP,
    address: "333 Republic Drive\nAllen Park, MI 48101",
    latitude: 42.298_970_625_726_234,
    longitude: -83.201_678_524_127_16,
  },
  {
    name: "Carbon North (CRN)",
    type: FacilityType.HQ,
    address: "802 134th Street Southwest\nEverett WA, 98204",
    latitude: 47.875_103_729_310_11,
    longitude: -122.244_675_771_128_43,
  },
  {
    name: "Rousseau Farming HQ",
    type: FacilityType.CUSTOMER_OFFICE,
    address: "11803 East McDonald Drive\nScottsdale, AZ 85256",
    latitude: 33.522_368_422_154_64,
    longitude: -111.823_069_781_376_83,
  },
  {
    name: "Keho Lakes Farm HQ",
    type: FacilityType.CUSTOMER_OFFICE,
    address: "110047 Range Road 222\nNobleford, AB TOK 1VO, Canada",
    latitude: 49.882_310_120_142_76,
    longitude: -112.915_295_437_655_93,
  },
  {
    name: "Pictsweet Farms HQ",
    type: FacilityType.CUSTOMER_OFFICE,
    address: "685 Tar Hill Road\nDyersburg, TN 38024",
    latitude: 36.096_538_726_085_45,
    longitude: -89.502_791_813_408_02,
  },
  {
    name: "Kerr Farms HQ",
    type: FacilityType.CUSTOMER_OFFICE,
    address: "309 Indian Creek Road East\nChatham, ON N7M 5J6, Canada",
    latitude: 42.393_856_120_849_03,
    longitude: -82.156_658_700_000_56,
  },
  {
    name: "Roth Farms HQ",
    type: FacilityType.CUSTOMER_OFFICE,
    address: "27502 East Canal Street South\nLoxahatchee, FL 33470",
    latitude: 26.684_602_829_690_178,
    longitude: -80.390_091_601_851_61,
  },
  {
    name: "LBM Rovadi",
    type: FacilityType.SUPPORT_BASE,
    address:
      "Agrarisch Bedrijventerrein, Agrobaan 13, 5813 EB Ysselsteyn, Netherlands",
    latitude: 51.495_870_174_513_03,
    longitude: 5.895_165_068_945_022,
  },
  {
    name: "Netherlands Shop",
    type: FacilityType.SHOP,
    address: "Smitspol 14, 3861 RS\nNijkerk, Netherlands",
    latitude: 52.241_496_163_083_42,
    longitude: 5.480_695_370_827_643,
  },
];

export const calculateArea = (draw: MapboxDraw): number | undefined => {
  const data = draw.getAll();
  if (data.features.length > 0) {
    const area = turfArea(data);
    // Restrict the area to 2 decimal points.
    const roundedArea = Math.round(area * 100) / 100;
    console.log("area", roundedArea);
    return roundedArea;
  }
};

// This GeoJSON validation is best-effort and meant to catch common errors like
// forgetting `properties` or passing a Geometry instead of a Feature. The
// backend will validate more fully.
export const parseGeojsonFeature = (
  t: TFunction,
  text: string
): Feature<Geometry> => {
  const json: any = JSON.parse(text);
  if (!isObject(json)) {
    throw new Error(t("views.fieldDefinitions.errors.wrongJsonType"));
  }
  const { type, properties, geometry } = json;
  if (type !== "Feature") {
    throw new Error(
      t("views.fieldDefinitions.errors.wrongFieldType", {
        field: "type",
        want: '"Feature"',
      })
    );
  }
  if (properties !== null && !isObject(properties)) {
    throw new Error(
      t("views.fieldDefinitions.errors.wrongFieldType", {
        field: "properties",
        want: "object/null",
      })
    );
  }
  if (!isObject(geometry)) {
    throw new Error(
      t("views.fieldDefinitions.errors.wrongFieldType", {
        field: "geometry",
        want: "object",
      })
    );
  }
  return json as any;
};

export const parseBoundary = (t: TFunction, text: string): Feature<Polygon> => {
  const { geometry, ...rest } = parseGeojsonFeature(t, text);
  if (geometry.type !== "Polygon") {
    throw new Error(
      t("views.fieldDefinitions.errors.wrongGeometryType", {
        want: "Polygon",
      })
    );
  }
  return { ...rest, geometry };
};

export const parsePlantingHeading = (
  t: TFunction,
  text: string
): Feature<LineString> => {
  const { geometry, ...rest } = parseGeojsonFeature(t, text);
  if (geometry.type !== "LineString") {
    throw new Error(
      t("views.fieldDefinitions.errors.wrongGeometryType", {
        want: "LineString",
      })
    );
  }
  if (geometry.coordinates.length !== 2) {
    throw new Error(t("views.fieldDefinitions.errors.exactlyTwoPoints"));
  }
  return { ...rest, geometry };
};
