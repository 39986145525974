import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { BLUE_BUTTON, classes, OUTLINED_BUTTON } from "portal/utils/theme";
import { capitalize, titleCase } from "portal/utils/strings";
import { DateTime } from "luxon";
import {
  DEFAULT_REPORT,
  formatWeeklyDay,
  formatWeeklyHour,
  isNewReport,
  secondsToRange,
} from "portal/utils/reports";
import { FeatureFlag } from "portal/utils/hooks/useFeatureFlag";
import {
  getReportInstancesPath,
  getReportPath,
  Path,
  Slug,
} from "portal/utils/routing";
import { Header } from "portal/components/header/Header";
import { ImplementationStatus } from "portal/utils/robots";
import { isUndefined } from "portal/utils/identity";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Loading } from "portal/components/Loading";
import { NoScroll, Page } from "portal/components/Page";
import { ReportEditor } from "portal/components/reports/ReportEditor";
import { ReportResponse } from "protos/portal/reports";
import { ReportTable } from "portal/components/reports/ReportTable";
import { ReportTools } from "portal/components/reports/ReportTools";
import { RunReportDialog } from "portal/components/reports/RunReportDialog";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useCreateReportMutation,
  useGetReportQuery,
  useListRobotsQuery,
  useUpdateReportMutation,
} from "portal/state/portalApi";
import {
  useMutationPopups,
  useQueryPopups,
} from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { withFeatureFlag } from "portal/components/RequireFeatureFlag";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";

export const Report: FunctionComponent = withAuthenticationRequired(
  withFeatureFlag(
    function Report() {
      const { report: slug } = useParams();

      const { t, i18n } = useTranslation();
      const navigate = useNavigate();

      const { user, isInternal } = useSelf();

      /**
       * Report
       */
      const { data, isLoading, isSuccess } = useQueryPopups(
        useGetReportQuery(slug ? { reportSlug: slug } : skipToken, {
          skip: slug === Slug.NEW,
        })
      );

      const [createReport] = useMutationPopups(useCreateReportMutation(), {
        success: capitalize(
          t("utils.actions.createdLong", {
            subject: capitalize(t("models.reports.report_one")),
          })
        ),
      });

      const [updateReport] = useMutationPopups(useUpdateReportMutation(), {
        success: capitalize(
          t("utils.actions.savedLong", {
            subject: capitalize(t("models.reports.report_one")),
          })
        ),
      });
      const report = useMemo<ReportResponse | undefined>(() => {
        if (!user) {
          return;
        }
        if (slug === Slug.NEW) {
          return structuredClone(DEFAULT_REPORT);
        } else if (data) {
          return data;
        }
      }, [data, slug, user]);

      const reportExists =
        isNewReport(report) || (isSuccess && Boolean(report));

      // get all robots
      const { data: summaries } = useQueryPopups(
        useListRobotsQuery({}, { skip: !report?.automateWeekly })
      );

      const activeRobotIds = useMemo<number[] | undefined>(() => {
        if (report?.automateWeekly && summaries) {
          return summaries
            .filter(
              (summary) =>
                summary.robot?.implementationStatus ===
                  ImplementationStatus.ACTIVE &&
                summary.customer?.db?.id === report.customerId
            )
            .map((summary) => summary.robot?.db?.id)
            .filter((id) => !isUndefined(id));
        }
      }, [report?.automateWeekly, report?.customerId, summaries]);

      // keep track of editing state
      const [isEditing, setEditing] = useState<boolean>(slug === Slug.NEW);
      // local version of report for editing
      const [localReport, setLocalReport] = useState<
        ReportResponse | undefined
      >();
      useEffect(() => {
        setLocalReport(report);
      }, [report]);

      // run report
      const [isRunning, setRunning] = useState<boolean>(false);
      let viewingReport = isEditing ? localReport : report;
      if (viewingReport?.automateWeekly) {
        const now = DateTime.local();
        viewingReport = {
          ...viewingReport,
          robotIds: activeRobotIds ?? [],
          startDate: now
            .plus({
              days: -(report?.customer?.weeklyReportLookbackDays ?? 14),
            })
            .toUnixInteger(),
          endDate: now.toUnixInteger(),
        };
      }

      if (!localReport) {
        return <Loading />;
      }

      return (
        <>
          <Header
            title={titleCase(t("models.reports.report_other"))}
            parentLink={Path.REPORTS}
            hideMascot
          >
            <div className="flex-grow"></div>
            {(isLoading || reportExists) && (
              <div className="flex items-center gap-2">
                <ReportTools
                  className={classes("hidden", { "md:flex": !isEditing })}
                  readOnly
                  dateRange={secondsToRange(
                    viewingReport?.startDate,
                    viewingReport?.endDate
                  )}
                  selectedRobots={viewingReport?.robotIds}
                />
                {!isEditing && (
                  <>
                    <Button
                      variant="text"
                      className="my-4 md:hidden"
                      component={Link}
                      to={getReportInstancesPath(slug)}
                    >
                      {t("models.reportInstances.run_other")}
                    </Button>
                    <Button
                      {...OUTLINED_BUTTON}
                      className={classes(
                        OUTLINED_BUTTON.className,
                        "md:hidden"
                      )}
                      onClick={() => setEditing(true)}
                    >
                      {t("utils.actions.edit")}
                    </Button>
                  </>
                )}
                {!isNewReport(report) && (
                  <Button
                    {...OUTLINED_BUTTON}
                    onClick={() => setRunning(true)}
                    disabled={isEditing}
                  >
                    <span className="ml-1 hidden sm:block">
                      {t("utils.actions.runLong", {
                        subject: t("models.reports.report_one"),
                      })}
                    </span>
                    <span className="ml-1 sm:hidden">
                      {t("utils.actions.run")}
                    </span>
                  </Button>
                )}
              </div>
            )}
          </Header>
          <Page maxWidth={false}>
            <NoScroll>
              {!isLoading && !reportExists && (
                <Alert severity="warning">
                  {t("views.reports.scheduled.errors.noReport")}
                </Alert>
              )}
              {(isLoading || reportExists) && (
                <div
                  className={classes("flex flex-col h-full", {
                    "mr-80": isEditing,
                  })}
                >
                  <ReportTools
                    className="md:hidden"
                    readOnly
                    dateRange={secondsToRange(
                      viewingReport?.startDate,
                      viewingReport?.endDate
                    )}
                    selectedRobots={viewingReport?.robotIds}
                  />
                  {(() => {
                    if (!report?.automateWeekly || !isInternal) {
                      return <></>;
                    }
                    if (
                      !report.customer ||
                      report.customer.emails.length === 0 ||
                      isUndefined(report.customer.weeklyReportEnabled) ||
                      isUndefined(report.customer.weeklyReportDay) ||
                      isUndefined(report.customer.weeklyReportHour) ||
                      !report.customer.weeklyReportTimezone
                    ) {
                      return (
                        <Alert severity="error" className="mb-4">
                          <AlertTitle>
                            {t("views.reports.scheduled.automation.errorTitle")}
                          </AlertTitle>
                          <ul className="list-none p-0">
                            <li>
                              {report.customer ? "✅" : "❌"}
                              <strong>
                                {t("models.customers.customer_one")}:
                              </strong>{" "}
                              {report.customer?.name ??
                                t(
                                  "views.reports.scheduled.automation.reportCustomer.errors.none"
                                )}
                            </li>
                            <li>
                              {(report.customer?.emails.length ?? 0) > 0
                                ? "✅"
                                : "❌"}
                              <strong>
                                {t(
                                  "views.reports.scheduled.automation.reportEmails.name"
                                )}
                                :
                              </strong>{" "}
                              {(report.customer?.emails.length ?? 0) > 0
                                ? report.customer?.emails.join(", ")
                                : t(
                                    "views.reports.scheduled.automation.reportEmails.errors.none"
                                  )}
                            </li>
                            <li>
                              {report.customer?.weeklyReportEnabled
                                ? "✅"
                                : "❌"}
                              <strong>
                                {t(
                                  "views.reports.scheduled.automation.customerReports"
                                )}
                                :
                              </strong>{" "}
                              {report.customer?.weeklyReportEnabled
                                ? t("utils.descriptors.enabled")
                                : t("utils.descriptors.disabled")}
                            </li>
                            <li>
                              {report.customer?.weeklyReportDay ? "✅" : "❌"}
                              <strong>
                                {t(
                                  "views.reports.scheduled.automation.reportDay.name"
                                )}
                                :
                              </strong>{" "}
                              {report.customer?.weeklyReportDay
                                ? formatWeeklyDay(
                                    t,
                                    i18n,
                                    report.customer.weeklyReportDay
                                  )
                                : t(
                                    "views.reports.scheduled.automation.reportDay.errors.none"
                                  )}
                            </li>
                            <li>
                              {report.customer?.weeklyReportHour ? "✅" : "❌"}
                              <strong>
                                {t(
                                  "views.reports.scheduled.automation.reportHour.name"
                                )}
                                :
                              </strong>{" "}
                              {report.customer?.weeklyReportHour
                                ? formatWeeklyHour(
                                    t,
                                    i18n,
                                    report.customer.weeklyReportHour
                                  )
                                : t(
                                    "views.reports.scheduled.automation.reportHour.errors.none"
                                  )}
                            </li>
                            <li>
                              {report.customer?.weeklyReportTimezone
                                ? "✅"
                                : "❌"}
                              <strong>
                                {t(
                                  "views.reports.scheduled.automation.reportTimezone.name"
                                )}
                                :
                              </strong>{" "}
                              {report.customer?.weeklyReportTimezone ??
                                t(
                                  "views.reports.scheduled.automation.reportTimezone.errors.none"
                                )}
                            </li>
                            <li>
                              {report.customer?.weeklyReportLookbackDays
                                ? "✅"
                                : "❌"}
                              <strong>
                                {t(
                                  "views.reports.scheduled.automation.reportLookback.name"
                                )}
                                :
                              </strong>{" "}
                              {report.customer?.weeklyReportLookbackDays
                                ? `${
                                    report.customer.weeklyReportLookbackDays
                                  } ${t("utils.units.dLong", {
                                    count:
                                      report.customer.weeklyReportLookbackDays,
                                  })}`
                                : t(
                                    "views.reports.scheduled.automation.reportLookback.errors.none"
                                  )}
                            </li>
                          </ul>
                        </Alert>
                      );
                    }
                    return (
                      <Alert severity="warning" className="mb-4">
                        <AlertTitle>
                          {t("views.reports.scheduled.automation.warningTitle")}
                        </AlertTitle>
                        {t(
                          "views.reports.scheduled.automation.warningDescription",
                          {
                            day: formatWeeklyDay(
                              t,
                              i18n,
                              report.customer.weeklyReportDay
                            ),
                            hour: formatWeeklyHour(
                              t,
                              i18n,
                              report.customer.weeklyReportHour
                            ),
                            timezone: report.customer.weeklyReportTimezone,
                            lookback: report.customer.weeklyReportLookbackDays,
                            customer: report.customer.name,
                          }
                        )}
                      </Alert>
                    );
                  })()}
                  <div className="flex justify-between items-center pb-4">
                    <div>
                      <Typography variant="h3">
                        {(isEditing ? viewingReport : report)?.name}
                      </Typography>
                      {t("views.reports.scheduled.byline", {
                        author:
                          report?.authorName ??
                          t("views.reports.scheduled.authorUnknown"),
                      })}
                      {report?.customer
                        ? ` ${t("views.reports.scheduled.toLine", {
                            customer: report.customer.name,
                          })}`
                        : ""}
                    </div>
                    <div className="gap-2 items-center hidden md:flex">
                      {!isEditing && (
                        <>
                          <Button
                            variant="text"
                            className="my-4"
                            component={Link}
                            to={getReportInstancesPath(slug)}
                          >
                            {t("utils.actions.viewLong", {
                              subject: t("models.reportInstances.run_other"),
                            })}
                          </Button>
                          {(isInternal ||
                            report?.authorId === user?.userId) && (
                            <Button
                              {...BLUE_BUTTON}
                              className="my-4"
                              onClick={() => setEditing(true)}
                            >
                              {t("utils.actions.editLong", {
                                subject: t("models.reports.report_one"),
                              })}
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <ReportTable
                    report={viewingReport}
                    loading={isLoading}
                    onColumnOrderChange={
                      isEditing
                        ? ({ column, targetIndex, oldIndex }) => {
                            const { visibleColumns } = localReport;
                            const offset =
                              oldIndex - visibleColumns.indexOf(column.field);
                            visibleColumns.splice(oldIndex - offset, 1);
                            visibleColumns.splice(
                              targetIndex - offset,
                              0,
                              column.field
                            );
                            setLocalReport({ ...localReport, visibleColumns });
                          }
                        : undefined
                    }
                  />
                </div>
              )}
              <ReportEditor
                report={localReport}
                open={isEditing}
                onChange={setLocalReport}
                onCancel={() => {
                  if (isNewReport(report)) {
                    navigate(Path.REPORTS);
                  } else {
                    setEditing(false);
                  }
                }}
                onSave={async (newReport) => {
                  if (isNewReport(newReport)) {
                    const createdReport = await createReport(
                      newReport
                    ).unwrap();
                    setEditing(false);
                    navigate(getReportPath(createdReport.slug));
                  } else {
                    await updateReport(newReport);
                    setEditing(false);
                  }
                }}
              />
              <RunReportDialog
                report={isNewReport(report) ? undefined : report}
                open={isRunning}
                onCancel={() => setRunning(false)}
              />
            </NoScroll>
          </Page>
        </>
      );
    },
    {
      flag: FeatureFlag.REPORTS,
      noFlag: "/",
    }
  )
);
