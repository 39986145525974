import { Almanac } from "portal/components/almanac/Almanac";
import { AlmanacConfig } from "protos/almanac/almanac";
import { capitalize } from "portal/utils/strings";
import { CarbonDataGrid } from "portal/components/CarbonDataGrid";
import { classes } from "portal/utils/theme";
import { GridColDef } from "@mui/x-data-grid-premium";
import { NewAlmanac } from "portal/components/almanac/NewAlmanac";
import { NoScroll } from "../Page";
import { SearchField } from "../header/SearchField";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useDeleteAlmanacMutation,
  useDeleteGlobalAlmanacMutation,
  useGetAlmanacQuery,
  useGetGlobalAlmanacQuery,
  useGetRobotQuery,
  useListAlmanacsForRobotQuery,
  useListGlobalAlmanacsQuery,
  useSetAlmanacMutation,
  useSetGlobalAlmanacMutation,
} from "portal/state/portalApi";
import { useFuzzySearch } from "portal/utils/hooks/useFuzzySearch";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useTranslation } from "react-i18next";
import React, { FunctionComponent } from "react";

const CELL_PADDING_CLASS = "py-4";

const defaultColumn: Partial<GridColDef> = {
  disableColumnMenu: true,
  cellClassName: () => CELL_PADDING_CLASS,
};

interface Props {
  adminEditing?: boolean;
  serial?: string;
  basePath: string;
  useListQuery:
    | typeof useListAlmanacsForRobotQuery
    | typeof useListGlobalAlmanacsQuery;
  useGetQuery: typeof useGetAlmanacQuery | typeof useGetGlobalAlmanacQuery;
  useDeleteMutation:
    | typeof useDeleteAlmanacMutation
    | typeof useDeleteGlobalAlmanacMutation;
  useSetMutation:
    | typeof useSetAlmanacMutation
    | typeof useSetGlobalAlmanacMutation;
}

export const AlmanacList: FunctionComponent<Props> = ({
  adminEditing = false,
  serial,
  basePath,
  useListQuery,
  useGetQuery,
  useDeleteMutation,
  useSetMutation,
}) => {
  const { t } = useTranslation();

  const { data: summary, isSuccess } = useQueryPopups(
    useGetRobotQuery(serial ? { serial } : skipToken),
    {
      errorVariant: "warning",
    }
  );

  const { data: almanacs, isSuccess: hasLoaded } = useQueryPopups(
    useListQuery({ serial })
  );

  const navigate = useNavigate();
  const { pathname } = useLocation();
  let uuid: string = "";
  if (!pathname.endsWith(basePath)) {
    uuid = pathname.replace(`${basePath}/`, "");
  }

  const { searchText, setSearchText, results } = useFuzzySearch<AlmanacConfig>({
    items: almanacs ?? [],
  });

  const columns: readonly GridColDef<AlmanacConfig>[] = [
    {
      ...defaultColumn,
      field: "name",
      headerName: t("models.almanacs.fields.name"),
    },
    {
      ...defaultColumn,
      field: "isActive",
      headerName: t("utils.descriptors.active"),
      cellClassName: ({ row: almanac }) => {
        const isActive =
          almanac.id === summary?.robot?.health?.fieldConfig?.activeAlmanacId;
        return classes(
          CELL_PADDING_CLASS,
          isActive ? "font-bold bg-green-500 text-white" : "text-gray-500"
        );
      },
      valueGetter: (value, almanac) => {
        if (!isSuccess) {
          return t("components.Loading.placeholder");
        }
        const isActive =
          almanac.id === summary.robot?.health?.fieldConfig?.activeAlmanacId;
        return isActive
          ? t("utils.descriptors.active")
          : t("utils.descriptors.inactive");
      },
    },
  ];

  if (uuid) {
    return (
      <Almanac
        adminEditing={adminEditing}
        parentLink={basePath}
        serial={serial}
        uuid={uuid}
        useGetQuery={useGetQuery}
        useDeleteMutation={useDeleteMutation}
        useSetMutation={useSetMutation}
      />
    );
  }

  return (
    <NoScroll>
      <div className="flex flex-col h-full">
        <CarbonDataGrid<AlmanacConfig>
          header={
            <>
              <SearchField
                value={searchText}
                onChange={setSearchText}
                label={t("utils.actions.searchLong", {
                  subject: capitalize(
                    t("models.almanacs.almanac", {
                      count: almanacs?.length ?? 0,
                    })
                  ),
                })}
              />
              <NewAlmanac
                serial={serial}
                basePath={basePath}
                useSetMutation={useSetMutation}
                hotkey="n"
              />
            </>
          }
          className="flex flex-1"
          rows={results}
          getRowId={({ id }) => id}
          columns={columns}
          getRowHeight={() => "auto"}
          hideFooter
          onRowClick={({ row: almanac }) =>
            navigate(`${basePath}/${almanac.id}`)
          }
          disableRowSelectionOnClick
          getRowClassName={() => "cursor-pointer"}
          loading={!hasLoaded}
        />
      </div>
    </NoScroll>
  );
};
