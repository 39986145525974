import "./app.scss";
import { auth, isActivated } from "portal/utils/auth";
import { BG_BACKDROP, classes } from "portal/utils/theme";
import { Breakpoint, useBreakpoint } from "./utils/hooks/useBreakpoint";
import { EnvironmentFlag } from "portal/components/EnvironmentFlag";
import { Helmet } from "react-helmet";
import { isPortalLanguage } from "portal/i18nConstants";
import { KeyboardShortcutsDialog } from "portal/components/KeyboardShortcutsDialog";
import { Loading } from "portal/components/Loading";
import { LOCALSTORAGE_DRAWER_EXPANDED } from "portal/utils/localStorage";
import { Outlet, useLocation } from "react-router-dom";
import { Path } from "./utils/routing";
import { PendingActivationOverlay } from "portal/components/PendingActivationOverlay";
import { setLocale } from "portal/utils/i18n";
import { setSelf } from "portal/state/self";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { useGetUserQuery } from "./state/portalApi";
import { useLocalStorage } from "@uidotdev/usehooks";
import { useQueryPopups } from "./utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import analytics from "react-ga";
import CssBaseline from "@mui/material/CssBaseline";
import React, { ReactElement, useEffect } from "react";

const App = (): ReactElement => {
  /**
   * Handle background user auth
   */
  const dispatch = useDispatch();
  const {
    isAuthenticated,
    user: auth0User,
    isLoading: isAuth0UserLoading,
    getAccessTokenSilently,
    error,
  } = useAuth0();
  const { data: userResponse } = useQueryPopups(
    useGetUserQuery(auth0User?.sub ? { userId: auth0User.sub } : skipToken)
  );
  const { user, isLoading: isCarbonUserLoading } = useSelf();
  const { pathname } = useLocation();
  const isLoading =
    isAuth0UserLoading || (isAuthenticated && isCarbonUserLoading);

  const { i18n } = useTranslation();
  // if there is a saved language on the user, use it
  useEffect(() => {
    const locale = userResponse?.user?.userMetadata?.language;
    if (locale && isPortalLanguage(locale)) {
      setLocale(i18n, locale);
    }
  }, [i18n, userResponse]);

  const breakpoint = useBreakpoint();
  const isSmall = breakpoint <= Breakpoint.sm;

  auth.setAccessTokenSilently(getAccessTokenSilently);
  useEffect(() => {
    if (userResponse) {
      dispatch(setSelf(userResponse));
    }
  }, [dispatch, userResponse]);

  // track page views
  useEffect(() => {
    if (window._jsenv.REACT_APP_GOOGLE_MEASUREMENT_ID) {
      analytics.initialize(window._jsenv.REACT_APP_GOOGLE_MEASUREMENT_ID);
      analytics.pageview(window.location.pathname + window.location.search);
    }
    // eslint doesn't want us to depend on these properties for some reason
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, window.location.search]);

  const [isExpanded] = useLocalStorage<boolean>(
    LOCALSTORAGE_DRAWER_EXPANDED,
    false
  );

  // don't let pending users see UI
  if (pathname !== Path.LOGOUT && user && !isActivated(user)) {
    console.debug("App.tsx:unactivated");
    return <PendingActivationOverlay />;
  }

  if (isLoading || error) {
    console.debug("App.tsx:Loading: isLoading=", isLoading, "error=", error);
    return <Loading failed={Boolean(error)} error={error} overlay />;
  }

  console.debug("App.tsx:mainpath:", window.location.pathname);
  return (
    <>
      <CssBaseline />
      <EnvironmentFlag />
      <Helmet>
        {/* proper noun, no localization required */}
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <title>Carbon Robotics</title>
      </Helmet>
      <div
        className={classes(
          "h-screen",
          BG_BACKDROP,
          "text-lighten-900",
          "flex flex-col items-center justify-start",
          {
            "pl-16": !isSmall && !isExpanded,
            "pl-60": !isSmall && isExpanded,
          }
        )}
      >
        <Outlet />
      </div>
      <KeyboardShortcutsDialog />
    </>
  );
};

export default App;
