import {
  Alert,
  AlertTitle,
  Button,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Paper,
} from "@mui/material";
import { AnyObjectSchema, boolean, number, object, string } from "yup";
import {
  BLUE_LOADING_BUTTON,
  classes,
  INPUT_DARK,
  RED_LOADING_BUTTON,
  SELECT_DARK,
  TEXT_FIELD_DARK,
} from "portal/utils/theme";
import { capitalize, titleCase } from "portal/utils/strings";
import { ConfigNode } from "protos/config/api/config_service";
import { expandPathsTo, getParentPath, Tree } from "./ConfigTree";
import { Field, Form, Formik } from "formik";
import {
  filterConfigTree,
  getConfigChanges,
  getNodeFromPath,
  isBooleanNode,
  isFloatNode,
  isIntNode,
  isLeafNode,
  isListNode,
  isStringNode,
  isUintNode,
} from "portal/utils/configs";
import { TextField as FormikTextField, Select } from "formik-mui";
import { LoadingButton } from "@mui/lab";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { SwitchWithLabel } from "portal/components/SwitchWithLabel";
import {
  useDeleteConfigPathMutation,
  useDeleteConfigTemplatePathMutation,
  useLazyGetConfigQuery,
  useLazyGetConfigTemplateQuery,
  useSetConfigTemplateValueMutation,
  useSetConfigValueMutation,
} from "portal/state/portalApi";
import {
  useLazyPopups,
  useMutationPopups,
} from "portal/utils/hooks/useApiPopups";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemoAsync } from "portal/utils/hooks/useMemoAsync";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import SaveIcon from "@mui/icons-material/SaveOutlined";

interface BaseProps {
  basePath?: string;
}

interface SerialProps extends BaseProps {
  serial?: string;
}

interface TemplateProps extends BaseProps {
  robotClass?: string;
}

const isPropsSerial = (
  props: SerialProps | TemplateProps
): props is SerialProps => "serial" in props;

const isPropsTemplate = (
  props: SerialProps | TemplateProps
): props is TemplateProps => "robotClass" in props;

interface ConfigPair {
  config: ConfigNode | undefined;
  template: ConfigNode | undefined;
}
const EMPTY_CONFIG_PAIR: ConfigPair = {
  config: undefined,
  template: undefined,
};

export const Config: FunctionComponent<SerialProps | TemplateProps> = ({
  basePath: inputPath,
  ...props
}) => {
  const { t } = useTranslation();
  const basePath = inputPath?.replace(/\/+$/, "") ?? "";
  const isSerial = isPropsSerial(props);
  const isTemplate = isPropsTemplate(props);
  const useSetValueMutation = isTemplate
    ? useSetConfigTemplateValueMutation
    : useSetConfigValueMutation;
  const useDeletePathMutation = isTemplate
    ? useDeleteConfigTemplatePathMutation
    : useDeleteConfigPathMutation;
  let serial: string | undefined;
  if (isTemplate) {
    serial = props.robotClass;
  }
  if (isSerial) {
    serial = props.serial;
  }

  const [getConfig] = useLazyPopups(useLazyGetConfigQuery());
  const [getTemplate] = useLazyPopups(useLazyGetConfigTemplateQuery());
  const [{ config, template }] = useMemoAsync<ConfigPair>(
    async () => {
      if (!serial) {
        return EMPTY_CONFIG_PAIR;
      }
      if (isSerial) {
        const { data } = await getConfig({ serial }, true);
        if (!data) {
          return EMPTY_CONFIG_PAIR;
        }
        const { config, template } = data;
        return { config, template };
      }
      if (isTemplate) {
        const { data: config } = await getTemplate({ serial }, true);
        return { config, template: undefined };
      }
      return EMPTY_CONFIG_PAIR;
    },
    [getConfig, getTemplate, isSerial, isTemplate, serial],
    EMPTY_CONFIG_PAIR
  );

  // get default config path from URL
  const { pathname, state: locationState } = useLocation();
  let path = pathname.replaceAll(basePath, "");
  // fix any double slashed
  path = path.replaceAll(/\/{2,}/g, "/");
  // remove leading slash if any
  path = path.replace(/^\//, "");
  const [selectedPath, setSelectedPath] = useState<string>(path || "");

  // keep config path up to date
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedPath) {
      navigate(`${basePath}/${selectedPath}`, {
        replace: true,
        state: locationState,
      });
    }
  }, [basePath, navigate, selectedPath, locationState]);

  const {
    changedPathsTree,
    changedPathsExpanded,
    addedPaths,
    changedPaths,
    removedPaths,
  } = useMemo<{
    changedPathsTree?: ConfigNode | undefined;
    changedPathsExpanded?: string[] | undefined;
    addedPaths?: string[] | undefined;
    changedPaths?: string[] | undefined;
    removedPaths?: string[] | undefined;
  }>(() => {
    if (!config || !template) {
      return {};
    }
    const { addedPaths, changedPaths, removedPaths, allChangedPaths } =
      getConfigChanges(config, template);
    const expandedPaths = [
      ...allChangedPaths,
      ...expandPathsTo(allChangedPaths),
    ];
    return {
      changedPathsTree: filterConfigTree(config, expandedPaths, "", {
        ignoreRoot: true,
        showChildren: false,
      }),
      changedPathsExpanded: expandedPaths,
      addedPaths,
      changedPaths,
      removedPaths,
    };
  }, [config, template]);

  const selectedNode = getNodeFromPath(config, selectedPath);
  const parentPath = getParentPath(selectedPath);
  const parentNode = getNodeFromPath(config, parentPath);
  const templateNode = getNodeFromPath(template, selectedPath);

  // handle update value
  const [setConfigValue] = useMutationPopups(useSetValueMutation(), {
    success: capitalize(
      t("utils.actions.savedLong", {
        subject: t("models.configs.value_one"),
      })
    ),
  });
  const updateItem = async (value: any): Promise<void> => {
    if (!serial) {
      return;
    }
    await setConfigValue({
      serial,
      path: selectedPath,
      value,
    });
    if (isListNode(selectedNode)) {
      setSelectedPath(`${selectedPath}/${value}`);
    }
  };

  // handle list delete
  const [deleteValue, { status }] = useMutationPopups(useDeletePathMutation(), {
    success: capitalize(
      t("utils.actions.deletedLong", {
        subject: t("models.configs.key_one"),
      })
    ),
  });

  const isDeleting = status === QueryStatus.pending;
  const deleteItem = async (path: string): Promise<void> => {
    if (!serial) {
      return;
    }
    await deleteValue({ serial, path });
    setSelectedPath(parentPath);
  };

  // generarte appropriate field
  let field: ReactElement | null;
  let defaultValue: string | number | boolean = "";
  let schema: AnyObjectSchema | undefined;
  let value: string | number | boolean = "";
  const units = selectedNode?.def?.units;
  let hint = selectedNode?.def?.hint;
  const isDeprecated = hint && hint.toLowerCase().includes("deprecated");
  const deprecationWarning = isDeprecated ? hint : undefined;
  if (isDeprecated) {
    hint = undefined;
  }
  const deprecationAlert = deprecationWarning ? (
    <Alert severity="error">{deprecationWarning}</Alert>
  ) : (
    <></>
  );
  if (isBooleanNode(selectedNode)) {
    const formattedUnits = units ? ` ${units}` : "";
    field = (
      <FormControl>
        <div className="flex gap-2">
          <Field
            component={SwitchWithLabel}
            type="checkbox"
            name="value"
            label={`${selectedNode?.name}${formattedUnits}`}
          />
        </div>
        {hint && <FormHelperText>{hint}</FormHelperText>}
        {deprecationAlert}
      </FormControl>
    );
    schema = object({
      value: boolean()
        .required(t("utils.form.required"))
        .typeError(t("utils.form.booleanType")),
    });
    value = selectedNode?.value?.boolVal ?? false;
    defaultValue = templateNode?.value?.boolVal ?? false;
  } else if (isStringNode(selectedNode)) {
    const { sizeLimit = Infinity, choices } =
      selectedNode?.def?.stringDef ?? {};
    if (Array.isArray(choices) && choices.length > 0) {
      field = (
        <>
          <Field
            {...SELECT_DARK}
            labelId={`field-${selectedNode?.name}`}
            component={Select}
            className={classes(SELECT_DARK.className, "min-w-52")}
            autoWidth
            name="value"
            input={<OutlinedInput {...INPUT_DARK} label={selectedNode?.name} />}
            formHelperText={{ children: hint }}
          >
            {choices.map((choice: string) => (
              <MenuItem key={choice} value={choice}>
                {choice}
                {units ? ` ${units}` : ""}
              </MenuItem>
            ))}
          </Field>
          {deprecationAlert}
        </>
      );
      schema = object({
        value: string(),
      });
    } else {
      field = (
        <>
          <Field
            {...TEXT_FIELD_DARK}
            component={FormikTextField}
            InputProps={{
              endAdornment: units && (
                <InputAdornment position="end">{units}</InputAdornment>
              ),
            }}
            name="value"
            label={selectedNode?.name}
            helperText={hint}
          />
          {deprecationAlert}
        </>
      );
      schema = object({
        value: string()
          .test(
            "size_limit",
            t("utils.form.maxSize", { limit: sizeLimit }),
            (value) => (value ? value.length <= sizeLimit : true)
          )
          .typeError(t("utils.form.stringType")),
      });
    }
    value = selectedNode?.value?.stringVal ?? "";
    defaultValue = templateNode?.value?.stringVal ?? "";
  } else if (isIntNode(selectedNode) || isUintNode(selectedNode)) {
    field = (
      <>
        <Field
          {...TEXT_FIELD_DARK}
          component={FormikTextField}
          name="value"
          label={selectedNode?.name}
          InputProps={{
            endAdornment: units && (
              <InputAdornment position="end">{units}</InputAdornment>
            ),
          }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]+" }}
          helperText={hint}
        />
        {deprecationAlert}
      </>
    );
    if (isIntNode(selectedNode)) {
      value = selectedNode?.value?.int64Val ?? 0;
      defaultValue = String(templateNode?.value?.int64Val ?? 0);
    } else {
      value = selectedNode?.value?.uint64Val ?? 0;
      defaultValue = String(templateNode?.value?.uint64Val ?? 0);
    }
    schema = object({
      value: number()
        .integer(t("utils.form.integerType"))
        .required(t("utils.form.required"))
        .typeError(t("utils.form.numberType")),
    });
  } else if (isFloatNode(selectedNode)) {
    field = (
      <>
        <Field
          {...TEXT_FIELD_DARK}
          component={FormikTextField}
          InputProps={{
            endAdornment: units && (
              <InputAdornment position="end">{units}</InputAdornment>
            ),
          }}
          name="value"
          label={selectedNode?.name}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]+\\.?[0-9]*" }}
          helperText={hint}
        />
        {deprecationAlert}
      </>
    );
    schema = object({
      value: number()
        .required(t("utils.form.required"))
        .typeError(t("utils.form.numberType")),
    });
    value = selectedNode?.value?.floatVal ?? 0;
    defaultValue = templateNode?.value?.floatVal ?? 0;
  } else if (isListNode(selectedNode)) {
    field = (
      <>
        <Field
          {...TEXT_FIELD_DARK}
          component={FormikTextField}
          name="value"
          label={t("components.config.newKey", { key: selectedNode?.name })}
          helperText={hint}
        />
        {deprecationAlert}
      </>
    );
    schema = object({
      value: string()
        .required(t("utils.form.required"))
        .matches(/^[\w.-]+$/, t("components.config.stringReqs"))
        .typeError(t("utils.form.stringType")),
    });
  }

  const missingDefaults: Set<string> = new Set();
  if (removedPaths) {
    for (const path of removedPaths) {
      if (path.startsWith(selectedPath)) {
        const truncated = path.replace(`${selectedPath}/`, "");
        const segments = truncated.split("/");
        if (segments.length > 0) {
          const firstSegment = segments[0];
          if (!firstSegment) {
            continue;
          }
          missingDefaults.add(firstSegment);
        }
      }
    }
  }

  const showContent =
    isLeafNode(selectedNode) ||
    isListNode(selectedNode) ||
    isListNode(parentNode);

  return (
    <>
      <div className="flex flex-col-reverse md:flex-row gap-8 h-full">
        <Tree
          className="w-full md:w-auto"
          config={config}
          initialShowChanged={Boolean(locationState?.showChanged)}
          onSelect={(path) => {
            setSelectedPath(path || "");
            document.querySelector("#page")?.scrollTo(0, 0);
          }}
          selectedPath={selectedPath}
          addedPaths={addedPaths}
          removedPaths={removedPaths}
          changedPathsExpanded={changedPathsExpanded}
          changedPathsTree={changedPathsTree}
          isTemplate={isTemplate}
        />
        {showContent && (
          <Paper
            key={selectedPath}
            className={classes("md:flex-grow", "p-8", "bg-gray-700")}
          >
            {" "}
            <Formik
              enableReinitialize
              initialValues={{ value }}
              validationSchema={schema}
              onSubmit={(values) => updateItem(schema?.cast(values).value)}
            >
              {({ submitForm, isSubmitting, dirty, setFieldValue }) => (
                <Form className="flex flex-col items-start gap-4">
                  {field}
                  <LoadingButton
                    {...BLUE_LOADING_BUTTON}
                    disabled={!dirty || isDeleting}
                    loading={isSubmitting}
                    onClick={submitForm}
                    startIcon={
                      isListNode(selectedNode) ? <AddIcon /> : <SaveIcon />
                    }
                  >
                    {isListNode(selectedNode)
                      ? t("utils.actions.addLong", {
                          subject: titleCase(t("models.configs.key_one")),
                        })
                      : t("utils.actions.save")}
                  </LoadingButton>
                  {missingDefaults.size > 0 && isListNode(selectedNode) && (
                    <Alert severity="warning" className="mt-4 mr-8">
                      <AlertTitle>
                        {t("components.config.warnings.valueChanged.title")}
                      </AlertTitle>
                      {t("components.config.warnings.keyMissing.description", {
                        count: missingDefaults.size,
                        keys: [...missingDefaults].join(", "),
                      })}
                    </Alert>
                  )}
                  {isListNode(parentNode) && (
                    <>
                      <LoadingButton
                        {...RED_LOADING_BUTTON}
                        disabled={isSubmitting}
                        loading={isDeleting}
                        onClick={() => deleteItem(selectedPath)}
                        startIcon={<DeleteIcon />}
                      >
                        {t("utils.actions.deleteLong", {
                          subject: t("models.configs.key_one"),
                        })}
                      </LoadingButton>
                      {addedPaths?.includes(selectedPath) && (
                        <Alert severity="warning" className="mt-4 mr-8">
                          <AlertTitle>
                            {t("components.config.warnings.valueChanged.title")}
                          </AlertTitle>
                          {t("components.config.warnings.keyExtra.description")}
                          <LoadingButton
                            {...RED_LOADING_BUTTON}
                            disabled={isSubmitting}
                            loading={isDeleting}
                            onClick={() => deleteItem(selectedPath)}
                            startIcon={<DeleteIcon />}
                          >
                            {t("utils.actions.deleteLong", {
                              subject: t("models.configs.key_one"),
                            })}
                          </LoadingButton>
                        </Alert>
                      )}
                    </>
                  )}
                  {changedPaths?.includes(selectedPath) && (
                    <Alert severity="warning" className="mt-4 mr-8">
                      <AlertTitle>
                        {t("components.config.warnings.valueChanged.title")}
                      </AlertTitle>
                      {t(
                        "components.config.warnings.valueChanged.description",
                        {
                          default: String(defaultValue) || "<BLANK>",
                        }
                      )}
                      <Button
                        onClick={() => {
                          setFieldValue("value", defaultValue);
                          submitForm();
                        }}
                      >
                        {t("utils.actions.resetLong", {
                          subject: t("models.configs.value_one"),
                        })}
                      </Button>
                    </Alert>
                  )}
                </Form>
              )}
            </Formik>
          </Paper>
        )}
      </div>
    </>
  );
};
