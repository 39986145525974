import { Duration } from "luxon";
import { isUndefined } from "./identity";
import { Message } from "protos/portal/messages";

export const isSameAuthor = (a?: Message, b?: Message): boolean =>
  !isUndefined(a) &&
  !isUndefined(b) &&
  a.authorRobotId === b.authorRobotId &&
  a.authorUserId === b.authorUserId;

export const isSoonAfter = (a?: Message, b?: Message): boolean =>
  !isUndefined(a) &&
  !isUndefined(b) &&
  Math.abs((a.db?.createdAt || Infinity) - (b.db?.createdAt || -Infinity)) <
    Duration.fromObject({ hours: 1 }).toMillis();
