import {
  Card,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { GRID_ROW } from "./FormulaCard";
import { numberOrEmptyString } from "portal/utils/forms";
import { SMALL_TEXT_FIELD_DARK } from "portal/utils/theme";
import { SmoothingInputSlider } from "./SmoothingInputSlider";
import { TFunction } from "i18next";
import { TVEProfile } from "protos/target_velocity_estimator/target_velocity_estimator";
import { useTranslation } from "react-i18next";
import React, { Dispatch, FunctionComponent, SetStateAction } from "react";

const getFormula = (
  t: TFunction
): Array<{
  label: string;
  description: string;
  key: keyof TVEProfile;
  min?: number;
  max?: number;
  step?: number;
  isSlider?: boolean;
  unit?: string;
}> => [
  {
    label: t("components.velocityEstimator.configs.cruiseOffsetPercent.label"),
    key: "cruiseOffsetPercent",
    description: t(
      "components.velocityEstimator.configs.cruiseOffsetPercent.description"
    ),
    step: 1,
    max: 100,
  },
  {
    label: t("components.velocityEstimator.configs.increaseSmoothing.label"),
    key: "increaseSmoothing",
    description: t(
      "components.velocityEstimator.configs.increaseSmoothing.description"
    ),
    step: 1,
    max: 100,
    isSlider: true,
  },
  {
    label: t("components.velocityEstimator.configs.decreaseSmoothing.label"),
    key: "decreaseSmoothing",
    description: t(
      "components.velocityEstimator.configs.decreaseSmoothing.description"
    ),
    step: 1,
    max: 100,
    isSlider: true,
  },
  {
    label: t("components.velocityEstimator.configs.minVelMph.label"),
    key: "minVelMph",
    description: t(
      "components.velocityEstimator.configs.minVelMph.description"
    ),
    step: 0.1,
    unit: "mph",
  },
  {
    label: t("components.velocityEstimator.configs.maxVelMph.label"),
    key: "maxVelMph",
    description: t(
      "components.velocityEstimator.configs.maxVelMph.description"
    ),
    step: 0.1,
    unit: "mph",
  },
];

interface Props {
  tveProfile: TVEProfile;
  canEdit: boolean;
  setUpdatedTve: Dispatch<SetStateAction<TVEProfile | undefined>>;
}

export const AdvancedFormulaCard: FunctionComponent<Props> = ({
  tveProfile,
  canEdit,
  setUpdatedTve,
}) => {
  const { t } = useTranslation();
  return (
    <Card className="p-8 my-4 flex-shrink-0">
      {/* Header */}
      <Typography variant="h6" className="mb-4">
        {t("components.velocityEstimator.configs.card.advancedFormulaTitle")}
      </Typography>

      {/* Form */}
      <div className="flex flex-col w-full items-center justify-center gap-8">
        {getFormula(t).map(
          ({
            label,
            description,
            key,
            min = 0,
            max = Infinity,
            step = 1,
            isSlider = false,
            unit = "%",
          }) =>
            isSlider ? (
              <SmoothingInputSlider
                key={key}
                label={label}
                description={description}
                field={key}
                tveProfile={tveProfile}
                setUpdatedTve={setUpdatedTve}
                canEdit={canEdit}
              />
            ) : (
              <Grid {...GRID_ROW} key={key}>
                <Grid item xs={12} md={2} className="font-bold" order={1}>
                  {label}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                  className="flex flex-col items-center"
                  order={{ xs: 3, md: 2 }}
                >
                  <TextField
                    {...SMALL_TEXT_FIELD_DARK}
                    disabled={!canEdit}
                    type="number"
                    inputProps={{ min, max, step }}
                    value={
                      unit === "%" && typeof tveProfile[key] === "number"
                        ? Number(tveProfile[key]) * 100
                        : tveProfile[key]
                    }
                    className="w-full text-center"
                    onChange={(event) => {
                      const value = numberOrEmptyString(event.target.value);
                      setUpdatedTve({
                        ...tveProfile,
                        [key]:
                          unit === "%" && typeof value === "number"
                            ? value / 100
                            : value,
                      });
                    }}
                    InputProps={{
                      ...SMALL_TEXT_FIELD_DARK.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">{unit}</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={8}
                  className="text-sm"
                  order={{ xs: 2, md: 3 }}
                >
                  {description}
                </Grid>
              </Grid>
            )
        )}
      </div>
    </Card>
  );
};
