import {
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Typography,
  TypographyVariant,
} from "@mui/material";
import { classes } from "portal/utils/theme";
import { getCustomerSerial } from "portal/utils/robots";
import { isUndefined } from "portal/utils/identity";
import { RobotSummaryResponse } from "protos/portal/robots";
import { useListRobotsQuery } from "portal/state/portalApi";
import { useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useSelf } from "portal/state/store";
import { useTranslation } from "react-i18next";
import { WithSkeleton } from "../WithSkeleton";
import DropdownIcon from "@mui/icons-material/ArrowDropDownOutlined";
import React, { FunctionComponent, useState } from "react";

interface Props {
  serial?: string;
  typography: TypographyVariant;
  typographyClassName?: string;
  onChange: (summary: RobotSummaryResponse) => void;
}

export const RobotSelector: FunctionComponent<Props> = ({
  serial,
  typography = "body1",
  typographyClassName,
  onChange,
}) => {
  const { isInternal } = useSelf();
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | undefined>();
  const { data: summaries, isLoading } = useQueryPopups(
    useListRobotsQuery({}),
    {
      errorVariant: "warning",
    }
  );

  return (
    <WithSkeleton
      success={!isUndefined(serial)}
      variant="rectangular"
      className="min-h-6 min-w-10 mr-4 flex-shrink-0"
    >
      <>
        <Button
          onClick={
            isLoading
              ? undefined
              : (event) => setMenuAnchor(event.currentTarget)
          }
          variant="text"
          className="text-white normal-case"
          endIcon={
            isLoading ? (
              <CircularProgress color="info" className="w-3 h-3" />
            ) : (
              <DropdownIcon />
            )
          }
        >
          <Typography
            variant={typography}
            color="inherit"
            component="div"
            className={classes("whitespace-nowrap", typographyClassName)}
          >
            {isInternal ? serial : getCustomerSerial(t, serial)}
          </Typography>
        </Button>
        <Menu
          onClose={() => setMenuAnchor(undefined)}
          open={!isUndefined(menuAnchor)}
          anchorEl={menuAnchor}
          className="max-h-[95%]"
        >
          {summaries?.map((summary) => (
            <MenuItem
              value={summary.robot?.serial}
              key={summary.robot?.serial ?? t("models.robots.unknown")}
              className={summary.robot?.serial === serial ? "hidden" : ""}
              onClick={() => {
                setMenuAnchor(undefined);
                onChange(summary);
              }}
            >
              {isInternal
                ? summary.robot?.serial ?? t("models.robots.unknown")
                : getCustomerSerial(t, summary.robot?.serial)}
            </MenuItem>
          ))}
        </Menu>
      </>
    </WithSkeleton>
  );
};
