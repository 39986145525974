import {
  booleanValueFormatter,
  CarbonDataGrid,
  excelFormatter,
  getRenderDateTime,
} from "portal/components/CarbonDataGrid";
import { Breakpoint, useBreakpoint } from "portal/utils/hooks/useBreakpoint";
import { CarbonUnit, loadSavedUnit } from "portal/utils/units/units";
import {
  CERTIFIED_METRICS,
  getMetricById,
  getMetricName,
} from "portal/utils/certifiedMetrics";
import { checkNever, isUndefined } from "portal/utils/identity";
import { classes } from "portal/utils/theme";
import {
  DailyMetricResponse,
  DailyMetricsByDateByRobotResponse,
} from "protos/portal/metrics";
import {
  DataGridPremiumProps,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridColDef,
  GridCsvGetRowsToExportParams,
  gridFilteredDescendantCountLookupSelector,
  gridFilteredSortedRowIdsSelector,
  GridRenderCellParams,
  gridRowTreeSelector,
  GridToolbarExport,
  renderBooleanCell,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";
import { DATE_PATH_FORMAT } from "portal/utils/dates";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateTime } from "luxon";
import {
  DEFAULT_DATE_RANGE,
  GROUP_COLORS,
  isBlankValue,
  PINNED_COLUMN_DATE,
  PINNED_COLUMN_JOB,
  PINNED_COLUMN_ROBOT,
  secondsToRange,
  useDateRange,
} from "portal/utils/reports";
import { formatMetric } from "../measurement/formatters";
import { getCustomerSerial } from "portal/utils/robots";
import { getDateRangeDescription } from "../CarbonDateRangePicker";
import { getSelectedRobotsDescription } from "./ReportTools";
import { isEqual, range } from "portal/utils/arrays";
import {
  isMetricNumber,
  isMetricTime,
  Metric,
  MetricValidation,
} from "portal/utils/metrics";
import { mean, sum } from "portal/utils/math";
import { Measurement, type MetricValue } from "../measurement/Measurement";
import { ParseKeys, TFunction } from "i18next";
import { PortalJob } from "protos/portal/jobs";
import {
  ReportInstanceResponse,
  ReportMode,
  ReportResponse,
} from "protos/portal/reports";
import { titleCase } from "portal/utils/strings";
import {
  useLazyGetDateMetricsQuery,
  useLazyListRobotJobsQuery,
  useListRobotsQuery,
} from "portal/state/portalApi";
import { useLazyPopups, useQueryPopups } from "portal/utils/hooks/useApiPopups";
import { useMeasurement, useSelf } from "portal/state/store";
import { useMemoAsync } from "portal/utils/hooks/useMemoAsync";
import { useStableArray } from "portal/utils/hooks/useStable";
import { useTranslation } from "react-i18next";
import { useUnmountEffect } from "portal/utils/hooks/useUnmountEffect";
import CollapsedIcon from "@mui/icons-material/ChevronRightOutlined";
import ExpandedIcon from "@mui/icons-material/ExpandMoreOutlined";
import React, {
  FunctionComponent,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from "react";

const defaultColumn: Partial<GridColDef> = {
  sortable: true,
  disableColumnMenu: true,
};

interface ReportTableBase {
  loading?: boolean;
  onColumnOrderChange?: DataGridPremiumProps["onColumnOrderChange"];
}

interface ReportTableReport extends ReportTableBase {
  report?: ReportResponse;
}

interface ReportTableInstance extends ReportTableBase {
  instance: ReportInstanceResponse | undefined;
}

type ReportTableProps = ReportTableInstance | ReportTableReport;

const isReport = (input: ReportTableProps): input is ReportTableReport =>
  "report" in input;

const isInstance = (input: ReportTableProps): input is ReportTableInstance =>
  "instance" in input;

const getVisibleColumns = (
  input: ReportTableProps
): ReportResponse["visibleColumns"] => {
  if (isInstance(input)) {
    return input.instance?.visibleColumns ?? [];
  } else if (isReport(input)) {
    return input.report?.visibleColumns ?? [];
  } else {
    return [];
  }
};

const getName = (t: TFunction, input: ReportTableProps): string => {
  if (isInstance(input)) {
    return (
      input.instance?.name ?? t("views.reports.scheduled.table.unknownReport")
    );
  } else if (isReport(input)) {
    return (
      input.report?.name ?? t("views.reports.scheduled.table.unknownReport")
    );
  } else {
    return t("views.reports.scheduled.table.unknownReport");
  }
};

const getShowTotal = (input: ReportTableProps): ReportResponse["showTotal"] => {
  if (isInstance(input)) {
    return input.instance?.showTotal ?? false;
  } else if (isReport(input)) {
    return input.report?.showTotal ?? false;
  } else {
    return false;
  }
};

const getShowAverage = (
  input: ReportTableProps
): ReportResponse["showAverage"] => {
  if (isInstance(input)) {
    return input.instance?.showAverage ?? false;
  } else if (isReport(input)) {
    return input.report?.showAverage ?? false;
  } else {
    return false;
  }
};

const getRobotIds = (input: ReportTableProps): ReportResponse["robotIds"] => {
  if (isInstance(input)) {
    return input.instance?.robotIds ?? [];
  } else if (isReport(input)) {
    return input.report?.robotIds ?? [];
  } else {
    return [];
  }
};

export const getDateRange = (input: ReportTableProps): DateRange<DateTime> => {
  const dateRange = DEFAULT_DATE_RANGE;
  let startDate: number;
  let endDate: number;
  if (isInstance(input)) {
    if (!input.instance) {
      return dateRange;
    }
    ({ startDate, endDate } = input.instance);
  } else if (isReport(input)) {
    if (!input.report) {
      return dateRange;
    }
    ({ startDate, endDate } = input.report);
  } else {
    return dateRange;
  }
  return secondsToRange(startDate, endDate);
};

const getRobotColorClass = (
  selectedRobotIds: number[],
  robotId: number
): string => {
  let colorIndex = selectedRobotIds.indexOf(robotId);
  while (colorIndex + 1 > GROUP_COLORS.length) {
    colorIndex -= GROUP_COLORS.length;
  }
  return GROUP_COLORS[colorIndex] ?? "";
};

// A `ProcessedMetrics` is a `DailyMetricResponse` where we've already called
// `getValue` on every value. Since value getters are not idempotent, we must
// do this exactly once.
const PROCESSED_METRICS_BRAND = Symbol("processedMetrics");
type ProcessedMetrics = DailyMetricResponse & {
  [PROCESSED_METRICS_BRAND]: true;
};

interface Aggregates {
  totals: Partial<ProcessedMetrics>;
  averages: Partial<ProcessedMetrics>;
  unanimous: Partial<ProcessedMetrics>;
}

const getMetricValues = (
  metrics: DailyMetricResponse | undefined
): ProcessedMetrics => {
  // Three kinds of properties:
  //  (a) non-metric properties like "db";
  //  (b) metrics that exist on the `metrics` response; and
  //  (c) synthetic metrics like `totalWeedsInBand` that are not initially on
  //      the response object.
  // Clone to get (a) (as well as (b)), then process to get (b) and (c).
  if (!metrics) {
    return {
      [PROCESSED_METRICS_BRAND]: true,
      ...DailyMetricResponse.fromPartial({}),
    };
  }
  const result: Partial<ProcessedMetrics> = {
    [PROCESSED_METRICS_BRAND]: true,
    ...DailyMetricResponse.fromPartial(metrics),
  };
  for (const metric of CERTIFIED_METRICS) {
    const key = metric.id as keyof DailyMetricResponse;
    const rawValue = metrics[key];
    result[key] = metric.getValue(rawValue, metrics);
  }
  return result as ProcessedMetrics;
};

// Our table is like a SQL query with `GROUP BY ROLLUP(serial, date_or_job)` in
// the general case, so we have four kinds of rows:
enum RowType {
  // GROUP BY (serial, date): Data for one robot on one day.
  SINGLE_DAY = "singleDay",
  // GROUP BY (serial, job): Data for one robot for one job.
  SINGLE_JOB = "singleJob",
  // GROUP BY (serial): Data for one robot, aggregated over all days/jobs.
  ROBOT_AGGREGATES = "robotAggregates",
  // GROUP BY (): Bottom-line data, aggregated over all robots and days/jobs.
  BOTTOM_LINE_AGGREGATES = "bottomLineAggregates",
}

interface SingleDayRow {
  type: RowType.SINGLE_DAY;
  serial: string;
  date: string;
  metrics: ProcessedMetrics;
}
interface SingleJobRow {
  type: RowType.SINGLE_JOB;
  serial: string;
  jobId: string;
  jobName: string;
  metrics: ProcessedMetrics;
}
interface RobotAggregatesRow {
  type: RowType.ROBOT_AGGREGATES;
  serial: string;
  aggregates: Aggregates;
}
interface BottomLineAggregatesRow {
  type: RowType.BOTTOM_LINE_AGGREGATES;
  aggregationType: AggregationType & ParseKeys; // split into separate rows
  aggregates: Aggregates;
}
type DataRow =
  | SingleJobRow
  | SingleDayRow
  | RobotAggregatesRow
  | BottomLineAggregatesRow;

enum AggregationType {
  TOTAL = /* i18nKey: */ "views.reports.scheduled.table.fields.total",
  AVERAGE = /* i18nKey: */ "views.reports.scheduled.table.fields.average",
}

interface JobsData {
  jobsBySerial: Record<string, PortalJob[]>;
  allJobs: PortalJob[];
}

export const ReportTable: FunctionComponent<ReportTableProps> = (props) => {
  const { loading = false, onColumnOrderChange } = props;
  const { isInternal, measurementSystem } = useSelf();
  const { cycleSlots } = useMeasurement();

  const breakpoint = useBreakpoint();
  const isSmall = breakpoint <= Breakpoint.sm;

  const { t, i18n } = useTranslation();

  const { data: summaries } = useQueryPopups(
    useListRobotsQuery(
      isInstance(props) ? { instance: props.instance?.slug } : {}
    )
  );

  // we have to stabilize the robotIds array to prevent unnecessary re-renders
  // otherwise, we use utility functions that accept props but they change on
  // every render so we can't use them as dependencies
  const robotIds = useStableArray(getRobotIds(props));

  const mode = useMemo<ReportMode>(() => {
    let mode: ReportMode | undefined;
    if (isInstance(props)) {
      mode = props.instance?.mode;
    }
    if (isReport(props)) {
      mode = props.report?.mode;
    }
    return mode || ReportMode.REPORT_MODE_DAYS;
  }, [props]);

  const dateRange = useDateRange(getDateRange(props));
  const selectedSerials = useMemo<string[]>(() => {
    if (!summaries) {
      return [];
    }
    return summaries
      .filter(
        ({ robot }) =>
          robot &&
          robot.db?.id &&
          robotIds.includes(robot.db.id) &&
          robot.serial
      )
      .map(({ robot }) => robot?.serial)
      .filter((serial) => !isUndefined(serial));
  }, [robotIds, summaries]);

  /**
   * Calculate individual dates requested
   */
  const dates = useMemo<string[]>(() => {
    const [startDate, endDate] = dateRange;
    if (!startDate || !endDate) {
      return [];
    }
    const dayCount = Math.floor(
      (endDate.diff(startDate, "days").toObject().days ?? 0) + 1
    );
    return range(dayCount).map((dayOffset) =>
      startDate.plus({ days: dayOffset }).toFormat(DATE_PATH_FORMAT)
    );
  }, [dateRange]);

  /**
   * Calculate jobs requested
   */
  const listJobsQueries = useRef<ReturnType<typeof listJobs>[]>([]);
  const [listJobs] = useLazyPopups(useLazyListRobotJobsQuery());
  const [{ jobsBySerial, allJobs }, { isLoading: isJobsDataLoading }] =
    useMemoAsync<JobsData>(
      async () => {
        if (mode !== ReportMode.REPORT_MODE_JOBS) {
          return { jobsBySerial: {}, allJobs: [] };
        }

        // abort previous queries and reset
        for (const query of listJobsQueries.current) {
          query.abort();
        }
        listJobsQueries.current = [];

        const result: JobsData = {
          jobsBySerial: {},
          allJobs: [],
        };
        await Promise.all(
          selectedSerials.map(async (serial, index) => {
            const startDate = dates[0];
            const endDate = dates.at(-1);
            if (!startDate || !endDate) {
              return;
            }
            const query = listJobs(
              {
                serial,
                startDate,
                endDate,
              },
              true
            );
            listJobsQueries.current[index] = query;
            const { data: robotJobs } = await query;
            result.allJobs.push(...(robotJobs ?? []));
            result.jobsBySerial[serial] = robotJobs ?? [];
          })
        );
        return result;
      },
      [dates, listJobs, mode, selectedSerials],
      { jobsBySerial: {}, allJobs: [] }
    );
  // cancel all queries on unmount
  useUnmountEffect(() => {
    for (const query of listJobsQueries.current) {
      query.abort();
    }
  });

  /**
   * Fetch metrics for robots and dates/jobs
   */
  const [getDateMetrics] = useLazyPopups(useLazyGetDateMetricsQuery());
  const getDateMetricsQuery = useRef<ReturnType<typeof getDateMetrics>>();
  const instance = isInstance(props) ? props.instance?.slug : undefined;
  const [dayMetrics, { isLoading: isDayMetricsLoading }] = useMemoAsync<
    DailyMetricsByDateByRobotResponse | undefined
  >(
    async () => {
      if (!summaries || selectedSerials.length === 0) {
        return;
      }
      if (mode === ReportMode.REPORT_MODE_JOBS) {
        return;
      }

      // abort previous queries and reset
      getDateMetricsQuery.current?.abort();

      if (dates.length === 0) {
        return;
      }

      getDateMetricsQuery.current = getDateMetrics(
        {
          serials: selectedSerials,
          dates,
          instance,
        },
        true
      );
      const { data } = await getDateMetricsQuery.current;
      if (!data) {
        return DailyMetricsByDateByRobotResponse.fromPartial({});
      }
      return structuredClone(data);
    },
    [summaries, selectedSerials, mode, dates, getDateMetrics, instance],
    undefined
  );
  // cancel query on unmount
  useUnmountEffect(() => {
    getDateMetricsQuery.current?.abort();
  });

  /**
   * Table
   */
  const isLoading =
    !summaries ||
    (mode === ReportMode.REPORT_MODE_JOBS && isJobsDataLoading) ||
    (mode === ReportMode.REPORT_MODE_DAYS && isDayMetricsLoading) ||
    loading;
  const tableData = useMemo<
    | {
        rows: DataRow[];
        totals: BottomLineAggregatesRow;
        averages: BottomLineAggregatesRow;
        loadedRobots: number[];
      }
    | undefined
  >(() => {
    if (isLoading) {
      return;
    }
    let rows: DataRow[] = [];
    const allMetrics: ProcessedMetrics[] = [];
    const loadedRobotsSet: Set<number> = new Set();
    for (const serial of selectedSerials) {
      const singleRows: Array<SingleDayRow | SingleJobRow> = [];
      if (mode === ReportMode.REPORT_MODE_JOBS) {
        if (!jobsBySerial[serial]) {
          continue;
        }
        for (const job of jobsBySerial[serial]) {
          const { jobId, name: jobName, metrics: jobMetrics } = job;
          if (!jobMetrics) {
            continue;
          }
          const metrics = getMetricValues(jobMetrics);
          allMetrics.push(metrics);
          singleRows.push({
            type: RowType.SINGLE_JOB,
            serial,
            jobId,
            jobName,
            metrics,
          });
        }
      } else if (mode === ReportMode.REPORT_MODE_DAYS) {
        if (!dayMetrics) {
          return;
        }
        for (const date of dates) {
          if (!(serial in dayMetrics.metrics)) {
            continue;
          }
          const robotMetrics = dayMetrics.metrics[serial];
          if (!robotMetrics) {
            continue;
          }
          const dailyMetric = robotMetrics.metrics[date];
          if (!dailyMetric || !dailyMetric.weedingUptimeSeconds) {
            continue;
          }
          const metrics = getMetricValues(dailyMetric);
          allMetrics.push(metrics);
          singleRows.push({
            type: RowType.SINGLE_DAY,
            serial,
            date,
            metrics,
          });
        }
      }
      if (singleRows.length > 0) {
        const { metrics, serial } = singleRows[0] ?? {};
        if (!metrics || !serial) {
          continue;
        }
        const robotId = summaries.find(({ robot }) => robot?.serial === serial)
          ?.robot?.db?.id;
        if (!robotId) {
          console.error(`Missing robot ID for serial ${serial}`);
          continue;
        }
        loadedRobotsSet.add(robotId);
        const baseFields = { serial, robotId };
        const aggregates = getAggregates(
          singleRows.map((row) => row.metrics),
          baseFields
        );
        rows.push({
          type: RowType.ROBOT_AGGREGATES,
          serial,
          aggregates,
        });
      }
      rows.push(...singleRows);
    }

    const loadedRobots = [...loadedRobotsSet];
    if (loadedRobots.length <= 1) {
      rows = rows.filter((row) => row.type !== RowType.ROBOT_AGGREGATES);
    }

    const aggregates = getAggregates(allMetrics, {
      serial: "",
      robotId: -1,
    });
    const totals: BottomLineAggregatesRow = {
      type: RowType.BOTTOM_LINE_AGGREGATES,
      aggregationType: AggregationType.TOTAL,
      aggregates,
    };
    const averages: BottomLineAggregatesRow = {
      type: RowType.BOTTOM_LINE_AGGREGATES,
      aggregationType: AggregationType.AVERAGE,
      aggregates,
    };
    return {
      rows,
      totals,
      averages,
      loadedRobots,
    };
  }, [
    isLoading,
    selectedSerials,
    mode,
    jobsBySerial,
    dayMetrics,
    dates,
    summaries,
  ]);

  const visibleColumnsCacheRef = useRef<string[] | undefined>();
  const visibleColumns: string[] = (() => {
    const result = getVisibleColumns(props);
    // OK to read/write this ref during render, because this is just an
    // identity cache (i.e., it's the same as `useMemo` except that we can
    // also access the previous state)
    const { current: last } = visibleColumnsCacheRef;
    if (last === undefined || !isEqual(result, last)) {
      visibleColumnsCacheRef.current = result;
      return result;
    } else {
      return last;
    }
  })();

  const showTotal = getShowTotal(props);
  const showAverage = getShowAverage(props);

  const dateColumn: GridColDef<DataRow> | undefined = useMemo(() => {
    return {
      ...defaultColumn,
      field: PINNED_COLUMN_DATE,
      headerName: t("views.reports.scheduled.table.fields.date"),
      cellClassName: "font-bold",
      valueGetter: (value, row): string => {
        // Used for exports, and also for the actual date column values
        // when there is only one robot. When there are multiple robots,
        // this is hidden by the `columnVisibilityModel`.
        switch (row.type) {
          case RowType.SINGLE_DAY: {
            return row.date;
          }
          case RowType.ROBOT_AGGREGATES: {
            return "";
          }
          case RowType.BOTTOM_LINE_AGGREGATES: {
            // "Total"/"Average": not a date, but visually we want it in this column
            return t(row.aggregationType);
          }
          case RowType.SINGLE_JOB: {
            console.warn("Unexpected job row in date column:", row);
            return "";
          }
        }
      },
      width: 150,
    };
  }, [t]);

  const jobColumns: GridColDef<DataRow>[] | undefined = useMemo(() => {
    return [
      {
        ...defaultColumn,
        field: PINNED_COLUMN_JOB,
        headerName: titleCase(t("models.jobs.job_one")),
        cellClassName: "font-bold",
        valueGetter: (value, row): string => {
          // Used for exports, and also for the actual job column values
          // when there is only one robot. When there are multiple robots,
          // this is hidden by the `columnVisibilityModel`.
          switch (row.type) {
            case RowType.SINGLE_JOB: {
              return row.jobName;
            }
            case RowType.ROBOT_AGGREGATES: {
              return "";
            }
            case RowType.BOTTOM_LINE_AGGREGATES: {
              // "Total"/"Average": not a date, but visually we want it in this column
              return t(row.aggregationType);
            }
            case RowType.SINGLE_DAY: {
              console.warn("Unexpected date row in job column:", row);
              return "";
            }
          }
        },
        width: 150,
      },
      {
        ...defaultColumn,
        field: "timestamp_ms",
        headerName: titleCase(t("components.DateRangePicker.startDate")),
        type: "dateTime",
        valueGetter: (value, row) => {
          if (row.type === RowType.SINGLE_JOB) {
            const job = allJobs.find((job) => job.jobId === row.jobId);
            if (!job) {
              return;
            }
            return job.timestampMs
              ? DateTime.fromMillis(job.timestampMs)
              : undefined;
          }
        },
        valueFormatter: excelFormatter,
        renderCell: getRenderDateTime(i18n, "short"),
      },
      {
        ...defaultColumn,
        field: "stop_timestamp_ms",
        headerName: titleCase(t("components.DateRangePicker.endDate")),
        type: "dateTime",
        valueGetter: (value, row) => {
          if (row.type === RowType.SINGLE_JOB) {
            const job = allJobs.find((job) => job.jobId === row.jobId);
            if (!job) {
              return;
            }
            return job.stopTimestampMs
              ? DateTime.fromMillis(job.stopTimestampMs)
              : undefined;
          }
        },
        valueFormatter: excelFormatter,
        renderCell: getRenderDateTime(i18n, "short"),
      },
    ];
  }, [allJobs, i18n, t]);

  const visibleMetrics: Metric[] = useMemo(() => {
    return visibleColumns.flatMap((id) => {
      // convert to metrics
      const metric = getMetricById(id as keyof DailyMetricResponse);
      // omit nulls
      return isUndefined(metric) ? [] : [metric];
    });
  }, [visibleColumns]);

  // Pre-select units for each metric, which are used for CSV exports in
  // both the header and the data cells, to ensure that those codepaths
  // agree on what unit is used.
  interface MetricUnits {
    carbonUnits: CarbonUnit;
    headerUnits: string;
  }
  const metricUnits: Map<string, MetricUnits> = useMemo(() => {
    const metricUnits = new Map();
    for (const metric of visibleMetrics) {
      let carbonUnits;
      let headerUnits = "";

      let naturalUnits;
      if (isMetricNumber(metric)) {
        naturalUnits = metric.toUnits;
      } else if (isMetricTime(metric)) {
        naturalUnits = metric.units;
      }

      if (isMetricNumber(metric) || isMetricTime(metric)) {
        const { cycleSlot } = metric;
        const cycle = metric.cycle?.[measurementSystem];
        const savedUnit = loadSavedUnit(
          cycleSlots,
          cycleSlot,
          measurementSystem,
          cycle
        );
        const toUnits = savedUnit ?? naturalUnits;
        const formatted = formatMetric(t, i18n, measurementSystem, metric, 1, {
          toUnits,
        });
        carbonUnits = formatted.carbonUnits;
        headerUnits = formatted.units;
      }
      metricUnits.set(metric.id, { carbonUnits, headerUnits });
    }
    return metricUnits;
  }, [t, i18n, visibleMetrics, cycleSlots, measurementSystem]);

  const getMetricHeaderName = useCallback(
    (metric: Metric): string => {
      const { headerUnits = "" } = metricUnits.get(metric.id) ?? {};
      const unitsPart = headerUnits ? ` (${headerUnits})` : "";
      const metricName = getMetricName(t, metric);
      return `${metricName}${unitsPart}`;
    },
    [t, metricUnits]
  );

  const columns: GridColDef<DataRow>[] = useMemo(() => {
    return [
      {
        ...defaultColumn,
        field: "serial",
        headerName: t("models.robots.fields.serial"),
        valueGetter: (value, row): string => {
          // Used for exports; hidden from UI by `columnVisibilityModel`.
          switch (row.type) {
            case RowType.SINGLE_JOB:
            case RowType.SINGLE_DAY:
            case RowType.ROBOT_AGGREGATES: {
              const { serial } = row;
              return isInternal ? serial : getCustomerSerial(t, serial);
            }
            case RowType.BOTTOM_LINE_AGGREGATES: {
              return "";
            }
          }
        },
      },
      ...(mode === ReportMode.REPORT_MODE_DAYS ? [dateColumn] : []),
      ...(mode === ReportMode.REPORT_MODE_JOBS ? jobColumns : []),
      ...visibleMetrics.map((metric): GridColDef<DataRow> => {
        return {
          ...defaultColumn,
          type: metric.type,
          field: metric.id,
          headerName: getMetricHeaderName(metric),
          renderHeader: () => (
            <span
              className={classes({
                "italic text-blue-600":
                  metric.validation === MetricValidation.PENDING,
                "italic text-orange-200":
                  metric.validation === MetricValidation.INTERNAL,
              })}
            >
              {getMetricName(t, metric)}
            </span>
          ),
          valueFormatter:
            metric.type === "boolean" ? booleanValueFormatter : String,
          valueGetter: (value, row) => {
            // This is only used for exports, since we have a custom cell renderer.
            switch (row.type) {
              case RowType.SINGLE_JOB:
              case RowType.SINGLE_DAY: {
                const metrics: ProcessedMetrics = row.metrics;
                const value = metrics[metric.id as keyof DailyMetricResponse];
                if (metric.type === "boolean") {
                  return value;
                }
                const unitData = metricUnits.get(metric.id);
                if (!unitData) {
                  console.error(
                    "Missing unit data for metric:",
                    metric.id,
                    row
                  );
                  return String(value);
                }
                const toUnits = unitData.carbonUnits;
                const formatted = formatMetric(
                  t,
                  i18n,
                  measurementSystem,
                  metric,
                  value,
                  { toUnits, blankValue: "", forExport: true }
                );
                const { converted, value: stringValue } = formatted;
                return converted !== undefined && !Number.isNaN(converted)
                  ? String(converted)
                  : stringValue;
              }
              case RowType.ROBOT_AGGREGATES:
              case RowType.BOTTOM_LINE_AGGREGATES: {
                // Shouldn't matter; we only export non-grouped rows.
                return;
              }
              default: {
                console.error(
                  "Unexpected row type in metric column:",
                  checkNever(row)
                );
              }
            }
          },
          renderCell:
            metric.type === "boolean"
              ? renderBooleanCell
              : ({ row }) => {
                  return (
                    <CarbonDataCell
                      metric={metric}
                      row={row}
                      showTotals={showTotal}
                      showAverages={showAverage}
                    />
                  );
                },
        };
      }),
    ];
  }, [
    t,
    dateColumn,
    mode,
    jobColumns,
    visibleMetrics,
    isInternal,
    getMetricHeaderName,
    metricUnits,
    i18n,
    measurementSystem,
    showTotal,
    showAverage,
  ]);

  let errorMessage: string | undefined;

  if (robotIds.length === 0) {
    errorMessage = t("views.reports.scheduled.table.errors.noRobots");
  } else if (!dateRange[0]) {
    errorMessage = t("views.reports.scheduled.table.errors.noStartDate");
  } else if (!dateRange[1]) {
    errorMessage = t("views.reports.scheduled.table.errors.noEndDate");
  } else if (getVisibleColumns(props).length === 0) {
    errorMessage = t("views.reports.scheduled.table.errors.noColumns");
  }

  return (
    <CarbonDataGrid<DataRow>
      treeData={tableData && tableData.loadedRobots.length > 1}
      getTreeDataPath={(row) => {
        switch (row.type) {
          case RowType.SINGLE_JOB: {
            return [row.serial, row.jobId];
          }
          case RowType.SINGLE_DAY: {
            return [row.serial, row.date];
          }
          case RowType.ROBOT_AGGREGATES: {
            return [row.serial];
          }
          case RowType.BOTTOM_LINE_AGGREGATES: {
            return [row.aggregationType];
          }
          default: {
            console.error("Unexpected row type in tree:", checkNever(row));
            return [];
          }
        }
      }}
      disableColumnReorder
      disableRowSelectionOnClick
      onColumnOrderChange={onColumnOrderChange}
      groupingColDef={{
        headerName:
          mode === ReportMode.REPORT_MODE_DAYS
            ? t("views.reports.scheduled.table.fields.group")
            : t("views.reports.scheduled.table.fields.groupJob"),
        cellClassName: ({ row }) => {
          const baseClasses = "font-bold";
          switch (row.type) {
            case RowType.SINGLE_JOB:
            case RowType.SINGLE_DAY: {
              const robotId =
                summaries?.find(({ robot }) => robot?.serial === row.serial)
                  ?.robot?.db?.id ?? -1;
              const color = getRobotColorClass(
                tableData?.loadedRobots ?? [],
                robotId
              );
              return classes(baseClasses, color);
            }
            case RowType.ROBOT_AGGREGATES:
            case RowType.BOTTOM_LINE_AGGREGATES: {
              return baseClasses;
            }
          }
        },
        renderCell: (parameters) => (
          <CarbonGroupingCell
            {...parameters}
            showTotals={showTotal}
            showAverages={showAverage}
          />
        ),
        width: 150,
      }}
      columnVisibilityModel={{
        serial: false,
        [PINNED_COLUMN_DATE]:
          !isUndefined(tableData) && tableData.loadedRobots.length <= 1,
        [PINNED_COLUMN_JOB]:
          !isUndefined(tableData) && tableData.loadedRobots.length <= 1,
      }}
      header={
        <>
          <div />
          <GridToolbarExport
            csvOptions={{
              fileName: `${getName(t, props)} - ${getSelectedRobotsDescription(
                t,
                tableData?.loadedRobots,
                summaries
              )} - ${getDateRangeDescription(getDateRange(props))}`,
              fields: [
                "serial",
                ...(mode === ReportMode.REPORT_MODE_DAYS
                  ? [PINNED_COLUMN_DATE]
                  : jobColumns.map((column) => column.field)),
                ...getVisibleColumns(props),
              ],
              getRowsToExport: ({ apiRef }: GridCsvGetRowsToExportParams) => {
                const rows = gridFilteredSortedRowIdsSelector(apiRef);
                const tree = gridRowTreeSelector(apiRef);
                return rows.filter(
                  (rowId) => tree[rowId] && tree[rowId].type !== "group"
                );
              },
            }}
            excelOptions={{ disableToolbarButton: true }}
            printOptions={{ disableToolbarButton: true }}
          />
        </>
      }
      className="flex flex-1"
      getRowId={(row) => {
        switch (row.type) {
          case RowType.SINGLE_DAY: {
            return `t0-${row.serial}-${row.date}`;
          }
          case RowType.ROBOT_AGGREGATES: {
            return `t1-${row.serial}`;
          }
          case RowType.BOTTOM_LINE_AGGREGATES: {
            return `t2-${row.aggregationType}`;
          }
          case RowType.SINGLE_JOB: {
            return `t3-${row.jobId}`;
          }
        }
      }}
      errorMessage={!isLoading && errorMessage}
      pinnedColumns={{
        left: isSmall
          ? []
          : [
              GRID_TREE_DATA_GROUPING_FIELD,
              PINNED_COLUMN_ROBOT,
              PINNED_COLUMN_DATE,
              PINNED_COLUMN_JOB,
            ],
      }}
      loading={isLoading || !tableData}
      rows={tableData?.rows ?? []}
      columns={columns}
      totals={showTotal ? tableData?.totals : undefined}
      averages={showAverage ? tableData?.averages : undefined}
      hideFooter
    />
  );
};

const getAggregates = (
  rows: ProcessedMetrics[],
  baseFields: Partial<DailyMetricResponse>
): Aggregates => {
  const template = {
    ...baseFields,
    db: undefined,
  } as ProcessedMetrics;
  const totals = { ...template };
  const averages = { ...template };
  const unanimous = { ...template };

  for (const metric of CERTIFIED_METRICS) {
    if (metric.type === "number" && !metric.canTotal && !metric.canAverage) {
      continue;
    }
    const numbers: number[] = [];
    let firstRow = true;
    let unanimousValue: MetricValue | undefined;
    for (const row of rows) {
      const value = row[metric.id as keyof Omit<ProcessedMetrics, "db">];
      if (firstRow) {
        firstRow = false;
        unanimousValue = value;
      }

      if (
        typeof value === "number" &&
        !Number.isNaN(value) &&
        !isUndefined(value)
      ) {
        numbers.push(value);
      }
      if (unanimousValue !== value) {
        unanimousValue = undefined;
        // if it's not a number and we know the values are mixed, we don't
        // have to look at all of them
        if (metric.type !== "number") {
          break;
        }
      }
    }
    if (numbers.length > 0) {
      // not sure how to get these types to work correctly
      (totals as any)[metric.id] = metric.canTotal ? sum(numbers) : undefined;
      (averages as any)[metric.id] = metric.canAverage
        ? mean(numbers)
        : undefined;
    }
    (unanimous as any)[metric.id] = unanimousValue;
  }
  return { totals, averages, unanimous };
};

const CarbonDataCell: FunctionComponent<{
  metric: Metric;
  row: DataRow;
  showTotals: boolean;
  showAverages: boolean;
}> = ({ metric, row, showTotals, showAverages }) => {
  const key = metric.id as keyof Omit<DailyMetricResponse, "db">;

  // Render a measurement component for this metric, after filtering out blank
  // values.
  const measurement = (values: MetricValue[]): ReactNode => {
    const classNames = "flex flex-col items-end h-full";
    values = values.map((v) => {
      return isBlankValue(v) ? undefined : v;
    });
    return (
      <Measurement
        value={values}
        metric={metric}
        valueClassName={classNames}
        unitClassName={classNames}
      />
    );
  };

  const unanimousOrMixed = (unanimous: MetricValue): ReactNode => {
    const isUnanimous = !isBlankValue(unanimous);
    return isUnanimous ? measurement([unanimous]) : <Mixed />;
  };

  switch (row.type) {
    case RowType.SINGLE_JOB:
    case RowType.SINGLE_DAY: {
      const { metrics } = row;
      const value = metrics[key];
      return measurement([value]);
    }

    case RowType.ROBOT_AGGREGATES: {
      const { aggregates } = row;
      const total = aggregates.totals[key];
      const average = aggregates.averages[key];
      if (!metric.canTotal && !metric.canAverage) {
        // Special case: we can use a unanimous value as an average
        // (but not a total) even for categorical metrics.
        return showAverages
          ? unanimousOrMixed(aggregates.unanimous[key])
          : measurement([undefined]);
      }
      const values = [];
      if (showTotals) {
        values.push(total);
      }
      if (showAverages) {
        values.push(average);
      }
      return measurement(values);
    }

    case RowType.BOTTOM_LINE_AGGREGATES: {
      const { aggregationType, aggregates } = row;
      // this is clearer as a switch, even though it's nested
      // eslint-disable-next-line sonarjs/no-nested-switch
      switch (aggregationType) {
        case AggregationType.TOTAL: {
          return measurement([aggregates.totals[key]]);
        }
        case AggregationType.AVERAGE: {
          return metric.canAverage
            ? measurement([aggregates.averages[key]])
            : unanimousOrMixed(aggregates.unanimous[key]);
        }
        default: {
          console.error(
            "Unexpected aggregation type in data cell:",
            checkNever(aggregationType)
          );
          return measurement([undefined]);
        }
      }
    }

    default: {
      console.error("Unexpected row type in data cell:", checkNever(row));
      return measurement([undefined]);
    }
  }
};

const Mixed: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <span className="opacity-50 italic w-full text-end">
      {t("views.reports.scheduled.table.fields.mixed")}
    </span>
  );
};

const CarbonGroupingCell: FunctionComponent<
  GridRenderCellParams<DataRow> & {
    showTotals: boolean;
    showAverages: boolean;
  }
> = ({ id, field, row, rowNode, showTotals, showAverages }) => {
  const { isInternal } = useSelf();
  const { t } = useTranslation();
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );
  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    if (rowNode.type !== "group") {
      return;
    }

    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  let label: string;
  switch (row.type) {
    case RowType.SINGLE_JOB: {
      label = row.jobName;
      break;
    }
    case RowType.SINGLE_DAY: {
      label = row.date;
      break;
    }
    case RowType.ROBOT_AGGREGATES: {
      const { serial } = row;
      label = isInternal ? serial : getCustomerSerial(t, serial);
      break;
    }
    case RowType.BOTTOM_LINE_AGGREGATES: {
      label = t(row.aggregationType);
      break;
    }
    default: {
      console.error("Unexpected row type in grouping cell:", checkNever(row));
      label = "";
    }
  }

  return (
    <div
      style={{ marginLeft: rowNode.depth * 50 }}
      onClick={handleClick}
      tabIndex={-1}
      role="button"
      className="cursor-pointer flex gap-2 items-center w-full h-full"
    >
      {filteredDescendantCount > 0 && (
        <>
          {rowNode.type === "group" && rowNode.childrenExpanded ? (
            <ExpandedIcon />
          ) : (
            <CollapsedIcon />
          )}
        </>
      )}
      <span>{label}</span>
      {filteredDescendantCount > 0 && (
        <div className="flex flex-1 flex-col h-full items-end font-mono text-xs opacity-50 font-normal">
          {showTotals && (
            <span className="flex-1 flex items-center">
              {t("views.reports.scheduled.table.fields.totalShort")}
            </span>
          )}
          {showAverages && (
            <span className="flex-1 flex items-center">
              {t("views.reports.scheduled.table.fields.averageShort")}
            </span>
          )}
        </div>
      )}
    </div>
  );
};
